.mouse-cursor {
    @include position($__fixed, 0, null, null, 0);
	pointer-events: $__none;
	border-radius: 50%;
    @include transform(translateZ(0));
	visibility: $__hidden;
}
.cursor-inner {
	margin-left: -4px;
	margin-top: -4px;
	width: 10px;
	height: 10px;
	z-index: 10000001;
	background: $__blue__14 !important;
	-webkit-transition: width .3s ease-in-out, height .3s ease-in-out, margin .3s ease-in-out, opacity .3s ease-in-out;
	transition: width .3s ease-in-out, height .3s ease-in-out, margin .3s ease-in-out, opacity .3s ease-in-out;
	&.cursor-hover {
		margin-left: -20px;
		margin-top: -20px;
		width: 40px;
		height: 40px;
		background: $__blue__14 !important;
		opacity: .1;
	}
}
.cursor-outer {
	margin-left: -15px;
	margin-top: -15px;
	width: 30px;
	height: 30px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	z-index: 10000000;
	opacity: .5;
	border: 2px solid $__blue__14;
    @include transform(all .08s ease-out);
	&.cursor-hover {
		opacity: 0;
	}
}
.mim_tm_all_wrap[data-magic-cursor="hide"] {
	.mouse-cursor {
		display: $__none;
		opacity: 0;
		visibility: $__hidden;
		position: $__absolute;
		z-index: -1111;
	}
}
.progress-wrap {
	position: fixed;
	right: 25px;
	bottom: 25px;
	height: 35px;
	width: 35px;
	cursor: $__pointer;
	display: $__block;
	border-radius: 50%;
	box-shadow: inset 0 0 0 2px rgb(75 64 237 / 20%);
	z-index: 99;
	opacity: 0;
	visibility: $__hidden;
	-webkit-transition: all 200ms linear;
	transition: all 200ms linear;
	transform: translateY(15px);
	-webkit-transform: translateY(15px);
	-moz-transform: translateY(15px);
	-ms-transform: translateY(15px);
	-o-transform: translateY(15px);
	&.active-progress {
		opacity: 1;
		visibility: $__visible;
		transform: translateY(0);
	}
	&::after {
		position: absolute;
		content: '\f062';
		font-family: 'FontAwesome';
		text-align: $__center;
		line-height: 35px;
		font-size: 17px;
		color: #0E82FD;
		left: 0;
		top: 0;
		height: 35px;
		width: 35px;
		cursor: $__pointer;
		display: $__block;
		z-index: 1;
		-webkit-transition: all 200ms linear;
		transition: all 200ms linear;
	}
	svg {
		path {
			fill: $__none;
		}
		&.progress-circle {
			path {
				stroke: #0E82FD;
				stroke-width: 4;
				box-sizing: border-box;
				-webkit-transition: all 200ms linear;
				transition: all 200ms linear;
			}
		}
	}
}
