$__primarycolor:#1C5B91;
$__primarycolor-hover: #1D7ED8;
$__secondarycolor:#9A9A9A;
$__deep__skyblue: #42c0fb;
$__blue: #15558d;
$__white: #ffffff;
$__white__01: #f5f8f9;
$__white__02: #EAEAEA;
$__white__03: #f6faff;
$__white__04: #f4fcff;
$__white__05: #F3F8FF;
$__white__06: #F7FAFF;
$__white__07: #F2F7FF;
$__white__08: #f5f5f7;
$__white__09: #E6E9F4;
$__dark-blue: #2E3842;
$__slate-blue: #2d3b48;
$__dark-slate-blue: #474648;
$__gray: #333333;
$__gray__10: #737373;
$__dark__gray: #888888;
$__mix__gray: #f7f7f7;
$__dark-gray: #484848;
$__light-gray: #dcdcdc;
$__goose-gray: #cccccc;
$__light__goose: #dddddd;
$__light-goose-gray: #dedfe0;
$__light-silver: #f0f0f0;
$__black: #000000;
$__blac__blue: #515783;
$__mix__ash: #ABB0BE;
$__ash__water: #E6F4FD;
$__smokey-gray: #757575;
$__gray__smoke: #a2a1a1;
$__light__smokey-gray: #666;
$__dark__smokeygray: #727272;
$__gray__smokey: #64627C;
$__gray__09: #948CA0;
$__gray__01: #DCD0FF;
$__gray__02: #F5FAFF;
$__gray__light__03: #EEF2FF;
$__celeste: #0de0fe;
$__dark-celeste: #01cae4;
$__light__celeste: #0CE0FF;
$__sky__light__01: #a9d4ff;
$__sky__light__02: #8ec4ff;
$__white__celeste: #c2d6f3;
$__light-pale-blue: #f8f9fa;
$__aqua__blue: #e9f5f7;
$__gray__08: #808080;
$__light__gray__01: #B4ABCA;
$__red: #fb1612;
$__dis__red: #e30303;
$__ash__red: #DD2D4E;
$__green: #28a745;
$__light__green: #F3F9FF;
$__dull-gold: #f4c150;
$__dark__blue: #272b41;
$__sky__blue: #20c0f3;
$__gray__cloud: #999;
$__gray__dark: #4B4B4B;
$__dark__gray__cloud: #9f9f9f;
$__ash__blue: #00BCD1;
$__steel__blue: #1663a6;
$__info__blue: #4d72d0;
$__info_sky: #1e5c92;
$__info__dark: #2264C5;
$__granite: #858585;
$__ash__granite: #A4A4A4;
$__light__granite: #8a8a8a;
$__granite__gary: #797979;
$__granite__01: #7c7c7c;
$__platinum: #f9f9f9;
$__light__platinum: #acc9f5;
$__jet__gray: #C0C0C0;
$__dark__ink: #1860CA;
$__sky__ink: #2868C8;
$__metallic-silver: #e3e3e3;
$__light__metallic: #dfdfdf;
$__dark-inkblue: #3474ac;
$__ash__white: #F8FBFF;
$__ash__sky: #E0F2FF;
$__ink__blue: #536CF1;
$__info__light: #1db9aa;
$__primary__light: #2196f3;
$__danger__light: #e63c3c;
$__warning__light: #f39c12;
$__star__yellow: #FED35D;
$__yellow: #FFBD07;
$__yellow__01: #ffd152;
$__success__light: #26af48;
$__purple__light: #c580ff;
$__default__light: #283447;
$__light__metalic: #e9e9e9;
$__metalic__blue: #e2e5e8;
$__blue__angel: #cfcfcf;
$__Light__blue__angel: #dcddea;
$__grapefruit: #d9534f;
$__light__grapefruit: #d43f3a;
$__mild__grapefruit: #e48784;
$__violet__pink: #ff4877;
$__dark__pink: #D84153;
$__ash__gray: #a0a0a0;
$__dark__ash__gray: #555;
$__light__white: #fafafa;
$__dark__light: #f5f5f5;
$__cloud__gray: #eeeeee;
$__gray__wolf: #3e3e3e;
$__gray__01: #E8EEFF;
$__gray__02: #9D9D9D;
$__gray__03: #6C6C6C;
$__water: #f5f7fc;
$__lava__red: #dc3545;
$__metalic__gray: #bbb;
$__ash__metalic: #F2F2F2;
$__bright__gold: #f2b600;
$__rose__light: #E74E84;
$__rose: #FFAABD;
$__rose__01: #FFE0E7;
$__water__white: #fcfcfc;
$__light__water__white: #f3f3f3;
$__light__blue__gray: #f5f5f6;
$__baby__blue: #e0e3e4;
$__light__columbia__blue: #d2dde9;
$__light__battle__gary: #aaa;
$__dark__battle__gary: #777;
$__royal__blue: #2c80ff;
$__zombie__green: #00d285;
$__dark__marble__blue: #495463;
$__mix__white: #fbfbfb;
$__night: #3d3d3d;
$__ash__night: #1C1C1C;
$__dark__night: #383838;
$__dark__cloud: #d4d4d4;
$__light__cloud: #e5e5e5;
$__lapis__blue: #3a559f;
$__dark__lapis: #22396B;
$__light__lapis: #0098AE;
$__dark__lava__red: #dd4b39;
$__dark__beige: #b8b8b8;
$__iridium: #676767;
$__light__chilli__pepper: #fc6075;
$__chilli__red: #ffa69e;
$__successcolor: #7bb13c;
$__light__orange: #FFF1C9;
$__light__orange__01: #ffb88e;
$__successcolor-hover: #00cc52;
$__infocolor: #00d0f1;
$__infocolor-hover: #028ee1;
$__warningcolor: #ffbc34;
$__warningcolor-hover: #e9ab2e;
$__dangercolor: #e84646;
$__dangercolor-hover: #ff0100;
$__purplecolor: #9368e9;
$__purpletext: #7460ee;
$__cherry__red: #f06060;
$__lime__green: #55ce63;
$__aquamarine: #40dda4;
$__spring__green: #2ab934;
$__dark__ash: #212529;
$__dark__candy: #ababab;
$__light__candy: #e6e6e6;
$__candycolor: #a6a6a6;
$__ligt__carbon: #979797;
$__cantaloupe: #ff9b44;
$__dark__plum__purple: #4E4852;
$__plum__gray: #757578;
$__turquoise: #0db9f2;
$__beige: #dbdbdb;
$__light__beige: #e4e4e4;
$__light__vampire: #f1f1f1;
$__battleship__gray: #959595;
$__banner-btn: #1C5B90;
$__sky__light: #1C71EF;
$__blur__blue: #20D7F2;
$__light__blue: #7289DE;
$__blue__01: #216EE1;
$__blue__02: #2467CA;
$__blue__03: #186DB5;
$__blue__04: #1B5D92;
$__blue__05: #274782;
$__blue__06: #204892;
$__blue__07: #17418E;
$__blue__08: #1b70ee;
$__blue__09: #2A85D1;
$__blue__10: #2357A6;
$__blue__11: #1376CA;
$__blue__12: #0f4d84;
$__blue__13: #6A5DEE;
$__blue__14: #0de0fe;
$__blue__15: #0C4F8A;
$__sky__02: #13a5e5;
$__sky__01: #10DEFD;
$__sky__03: #28DAF4;
$__sky__white: #E8F6FF;
$__sky__lighter: #E5F6FD;
$__mist_white: #F2F6F6;
$__cyan_blue: #E6F1FF;
$__white_tone: #E5E7EB;
$__midnight_black: #1F2937;
$__bodycolor: #6B7280;
$__rock_blue: #4B5563;
$__gulf_gray: #9CA3AF;
$__nickel_color: #959595;
$__grey_100: #A4A4A4;
$__grey_200: #E6E6E6;
$__grey_300: #F7F7F7;
$__grey_400: #F5F5F5;
$__grey_500: #ECEBFF;
$__blue_shade: #3C4758;
$__grey_whitish: #F4F4F4;
$__argent_color: #888888;
$__charcoal_color: #494949;
$__steel_color: #777777;
$__pearl_white: #AEAEB2;
$__athens_grey: #F3F4F6;
$__yellowish_white: #8C97B0;
$__green_color: #008F64;
$__red_color: #FF3535;
$__white_shade: #E4E4E8;
$__white_silver: #A9A9A9;
$__success_color: #22C550;
$__gray_goose: #EDF1F1;
$__jet_grey: #484848;
$__danger_color: #FF3333;
$__mint_chiffon: #DCFCE5;
$__cement_white: #FFF6D8;
$__dark_yellow: #F3DB00;
$__platinum_color:  #E3E4E8;
$__grey: #8894AE;
$__whisper_color: #E5E5E5;
$__blue_color: #2F353C;
$__grey_600: #8C8C8C;
$__grey_700: #E9EAF6;
$__grey_800: #8D8D8D;
$__grey_900: #ABABAB;
$__orange: #FFBC11;
$__link_water: #D1D5DB;
$__work__blue: #E0E7FF;
$__black_shade: #0A0B0D;
$__flash_color: #EFF0F7;
$__fog_color: #D9DBE9;
$__broken_blue: #3B9CF6;
$__tag__blue: rgba(255, 255, 255, 0.2);
$__light_white: #EDEDED;
$__white_smoke: #F3F3F3;
$__grey_white: #F8F8F8;
$__honeydew_color: #E2E8F0;
$__zinc_color: #27272A;
$__royal_blue: #1E3259;
$__navey__blue: #15558d;
$__caribbean__green: #09dca4;
$__caribbean__dark: #09e5ab;
$__celeste: #0de0fe;
$__dark__celeste: #01cae4;
$__celtic_blue: #0071DC;
$__alto_color: #D9D9D9;
$__basalt_grey: #999999;
$__ebony_clay: #28283C;
$__grey_dark: #4A4A4A;
$__zambesi_grey: #5B5B5B;
$__davy_grey: #595959;
$__babe_grey: #B9B9B9;
$__darkish_grey: #D4D4D4;
$__silver_grey: #AAAAAA;
$__ghost_white: #F8FBFF;
$__cyan_color: #0CE0FF;
$__mine_grey: #3F3F3F;

$__errigal_white: #F1F1F3;
$__quartz_color: #71717A;
$__rich_black: #040404;
$__trolley_grey: #818181;
$__bright_grey: #EFEFEF;
$__text_danger: #FF0000;
$__pastel_orange: #FFB54A;
$__green_shade: #159F46;
$__grayish_blue: #E4E6EF;
$__light_green: #4CAF50;
$__tone_grey: #9E9E9E;
$__dimgrey_color: #696969;
$__black_mana: #838383;
$__emperor_grey: #525252;
$__silver_chalice: #ACACAC;
$__zambesi_grey: #5B5B5B;
$__sonic_silver: #717171;
$__chinse_white: #E0E0E0;
$__arc_light: #c9e3fe;
$__congo_brown: #494545;
$__misty_rose: #ffe6e1;
$__misty__green: #00CC45;
$__dove_grey: #6B6B6B;
$__light_grey: #c2c2c2;
$__fog_silver: #7C7C7C;
$__blue_color1: #1C5B91;
$__dark_cyan: #1B5A90;
$__lightblue_shade: #4183BB;
$__grey_shase: #2B3D51;
$__home__12__section__even : #F9FAFB;
$__cool_blue :#BEC4D9;
$__cherry_grey : #2B3D51;
$__lite_dark_grey : #707070;
$__blue_color1: #1C5B91;
$__greymax: #E7E8E6;
$__lite__gray: #F6F7F5;
$__laysgreen :#2DD4BF;
$__thunderblue: #1E5D8A;
$__blue__20 : #0F78E7;
$__greymax: #E7E8E6;
$__lite_orange : #FFAF14;
$__green__blue: #CCFBF1;
$__blue_color1: #1C5B91;
$__dark_cyan: #1B5A90;
$__pattens_blue: #F0F4F6;
$__color_warning: #FFAF14;
$__blue_shade1: #2369A6;
$__white_shade1: #D6EEFF;
$__white_shade2: #F9FDFF;
$__white_shade3: #E6FCFF;
$__white_shade4: #F0F4F7;
$__black_shade1: #131218;
$__blue_shade2: #3E50AE;
$__gold_color: #DDBC5B; 
$__grey_color: #666666; 
$__sand_color: #FDEEC3; 
$__greenish_color: #13C2C2;
$__greenish_color1: #13A0CA;
$__greenish_color2: #1C5C92;
$__greenish_color3: #97F0FF;
$__greenish_color4: #0CBCDA;
$__greenish_color5: #328CD9;
$__home__12__banner__section : #F9F9F9;
$__home__12__section__even : #F9FAFB;
$__home__12__section__odd : #F3F4F6;
$__laysgreen :#2DD4BF;
$__sky__04 :#F8FAFC;
$__blue__16 :#DBEFFE;
$__puregold :#FFC001;
$__light-white-grey : #F8FAFC;
$__wonder-blue: #BFE0FE;
$__blue__17 :#1D7ED8;
$__grey_white__01:#94A3B8;
$__baby__blue :#EEF6FF;
$__laysgreen :#2DD4BF;
$__sky__04 :#F8FAFC;
$__blue__16 :#DBEFFE;
$__light-white-grey : #F8FAFC;
$__wonder-blue: #BFE0FE;
$__blue__17 :#1D7ED8;
$__grey_white__01:#94A3B8;
$__laysgreen :#2DD4BF;
$__lite_dark_grey : #707070;
$__little_skyblue: #E3FBFF;
$__ass_grey: #EEEEF3;
$__little_grey_blue :#B0D6FF;
$__Dark_purple : #4C58A0;
$__dark_sandstone :#515151;
$__little_assgrey :#A2A2A2;
$__dark_brown :#626262;
$__lite_shadow: #7D7D7D;


$__blue__dark: #002578;
$__white__shade: #F1F5F9;
$__purple: #D1FFB5;
$__violet: #7C3AED;
$__violet__dark: #7E22CE;
$__yellow__light: #FFD66A;
$__blue__light: #20A5FF;
$__black__shade: #0F172A;
$__blue__ash: #E8F1FF;
$__pink__dark: #DB2777;
$__teal-200: #99F6E4;
$__black__ash: #334155;
$__ash__black: #475569;
$__cart__red: #E11D48;
$__sky__blue__2: #E0F2FE;
$__gold__2: #FDCA47;
$__gold__3: #FED78A;
$__gold__4: #FF8A00;
$__blue__18: #004FFF;
$__blue__19: #D6F8FF;
$__ash__white__2: #FAF5FF;
$__light__lime: #F7FEE7;
$__black__01: #374151;
$__black__02: #131313;
$__black__03: #262626;
$__black__04: #1A1A1A;
$__black__05: #1E293B;
$__purple__dark: #4338CA;
$__purple__01: #601CA5;
$__purple__light__01: #F6EEFF;
$__light__red: #FFEDE3;
$__dark__orange: #E5440E;
$__grey__01: #F5F3FF;
$__grey__02: #EDE9FE;
$__box__shadow: 0px 7.5px 17.5px 0px rgba(0, 0, 0, 0.05);
$__green-700: #158033;
$__rose-700: #BE123C;
$__indigo-800: #3730A3;
$__indigo-500: #6366F1;
$__emerald-600: #059669;
$__teal: #14B8A6;
$__yellow: #EAAB08;
$__yellow-50: #FEF8E8;
$__purple-600: #9333EA;
$__slate-300: #CBD5E1;
$__slate-500: #64748B;
$__zinc-900:#18181B;
$__orange-600: #EA580C;
$__orange-50: #FFF7ED;
$__blue-400: #60B9FA;
.primary {
	background-color:$__primarycolor;
}
$__black-50: #000C18;
$__blue-01: #EAE9FF;
$__green-01: #F1FFDB;
$__info-01: #C9F3FF;
$__red-01: #FFE5E4;
$__pink-01: #FFD9F0;
$__success-01: #D8FFDA;
$__danger-01: #FFEADF;
$__red-100: #FEE2E2;
$__red-500: #EF444E;
$__amber-500: #F59E0B;
$__gray-50: #F9FAFB;
$__blue-50: #F9FCFF;
$__sky-200: #BAE6FD;
$__sky-600: #0284C7;
$__sky-700: #0369A1;
$__sky-50: #F0F9FF;
$__cyan-400: #22D6EE;









$zinc-200: #E4E4E7;
$blue-300: #93D0FD;
$__indigo-900: #312E81;
$__indigo-600: #4F46E5;
$__emerals-500: #10B981;
$__fuchsia-200: #F5D0FE;
$__fuchsia-600: #C026D3;
$cyan-200: #A5ECFC;
$cyan-600: #089EB2;