.appoint-patient {
	.appointment-wrap.appointment-grid-wrap {
		& > ul {
			.appointment-info {
				padding-bottom: 10px;
				display: flex;
				display: -webkit-flex;
				align-items:center;
				-webkit-align-items:center;
				justify-content: space-between;
				-webkit-justify-content: space-between;
				flex-wrap: wrap;
				p:first-child {
					margin-right: 10px;
				}
				
			}
			.appointment-grid-head {
				.grid-user-msg {
					.hospital-icon {
						i {
							color: $__violet;
						}
					}
				}
			}

		}
	}
}
.profile-widget {
	&.patient-favour {
		border: 1px solid $__honeydew_color;
		box-shadow: 0px 7.5px 17.5px 0px #0000000D;
		padding: 0;
		@include rounded(10px);
		.fav-head {
			position: relative;
			padding: 24px;
			border-bottom: 1px solid $__honeydew_color;
			.doc-img {
				width: 80px;
				margin: 0 auto 5px;
				@include rounded(10px);
				img {
					width: 80px;
					height: 80px;
					@include rounded(10px);
					object-fit: cover;
				}
			}
			.fav-btn {
				opacity: 1;
				visibility: visible;
				background-color: $__light-white-grey;
				@include transform(unset);
				width: 36px;
				height: 36px;
				@include rounded(50%);
				top: 20px;
				right: 20px;
				.favourite-icon {
					&.favourite {
						color: $__orange-600;
					}
				}
			}
			.pro-content {
				.title {
					text-align: center;
					 font-size: $__font__size__16;
					 font-weight: $__semibold;
					 margin-bottom: 10px;
					 color: $__black__shade;
					 a {
					 	color: $__black__shade;
					 	&:hover {
					 		color: $__blue__14;
					 	}
					 }
				}
				p {
            		&.speciality {
            			text-align: center;
            			font-size: $__font__size__14;
            			color: $__slate-500;
            			-webkit-line-clamp: 2; 
    					-webkit-box-orient: vertical;
					    overflow: hidden;
					    text-overflow: ellipsis;
					    display: -webkit-box;;
            		}
            	}
            	.rating { 
            		text-align: center;
            		color: $__black__ash;
            		font-size: $__font__size__14;
            		font-weight: $__medium;
            		@include margin-padding(0 0 15px, 0);
            	}
            	.available-info {
            		font-size: $__font__size__14;
            		color: $__black__05;
            		li {
            			display: flex;
						display: -webkit-flex;
						color: $__black__ash;
						font-size: $__font__size__14;
		                & + li {
		                    margin-top: 15px;
		                }
		            }
            	}
            	.last-book {
            		background: $__light-white-grey;
            		@include rounded(10px);
            		@include margin-padding(null, 10px);
            		p {
            			text-align: center;
            			font-size: $__font__size__14;
            			margin: 0;
            			color: $__black__shade;
            		}
            	}
			}
		}
		.fav-footer {
			padding: 15px;
			.btn {
			    font-size: $__font__size__15;
			    font-weight: $__semibold;
			    @include margin-padding(null, 6px);
			}
			.view-btn {
			    color: $__slate-500;
			    border: 2px solid $__home__12__section__even;
			    background-color: $__home__12__section__even;
			    text-align: $__center;
			    display: $__block;
			    &:hover, &:focus {
			        background-color: darken($__home__12__section__even, 5%);
			        border-color: darken($__home__12__section__even, 5%);
			        color: $__slate-500;
			    }
			}
			.book-btn {
			    background-color: $__white;
			    border: 2px solid $__blue__14;
			    color: $__blue__14;
			    text-align: $__center;
			    display: $__block;
			    &:hover, &:focus {
			        background-color: darken($__blue__14, 3%);
			        border-color: darken($__blue__14, 3%);
			        color: $__white;
			    }
			}
		}
	}
}