.add-new-btn {
    background-color: $__celeste;
    color: $__white;
    display: $__inline__block;
    font-weight: $__medium;
    @include margin-padding(0 0 20px, 10px 20px);
    @include rounded(30px);
    &:focus, &:hover, &.active {
    	background-color: $__celeste;
    	color: $__white;
    }
}
.patient-info {
	margin-top: 15px;
	ul {
	    list-style: $__none;
		font-size: .875rem;
		@include margin-padding(0, 0);
		li {
		    position: $__relative;
		    font-size: .875rem;
		    font-weight: $__medium;
		    color: $__dark__blue;
		    text-transform: $__capitalize;
		    & + li {
				margin-top: 15px;
			}
			i {
				width: 18px;
			}
			span {
			    color: $__smokey-gray;
			    float: $__right;
			    font-weight: $__regular;
			}
		}
	}
}