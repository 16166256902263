.pharmacy-body {
    font-family: $__home_ten__font__family;
    .header {
        .header-nav {
            justify-content: start;
            -webkit-justify-content: start;
            @include respond-below(custom991) {
                justify-content: space-between;
                -webkit-justify-content: space-between;
            }
            .logo {
                display: $__none;
                @include respond-below(custom991) {
                    display: $__block;
                }
            }
            .main-nav {
                li {
                    a {
                        font-size: $__font__size__16;
                        color: $__black__05;
                        &:hover {
                            color: $__blue__14;
                        }
                        @include respond-below(custom991) {
                            color: $__white;
                        }
                    }
                }
            }
        }
    }
    .pharmacy-header {
        position: $__fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1001;
        background: $__white;
    }
    .sidebar-overlay {
        z-index: 1;
    }
}
.top-header {
    background-color: $__blue__ash;
    padding: 10px 0;
    @include respond-below(custom991) {
        display: $__none;
    }
    .special-offer-content {
        @include respond-below(custom767) {
            padding-bottom: 10px;
        }
        p {
            font-size: $__font__size__14;
            color: $__black__ash;
            margin-bottom: 0;
            span {
                color: $__black__shade;
                font-weight: $__semibold;
            }
        }
    }
}
.top-header-right {
    .nav {
        @extend %flex__end;
        @extend %flex-align-center;
        @include respond-below(custom767) {
            justify-content: start;
            -webkit-justify-content: start;
            margin-left: -10px;
        }
        li {
            display: $__flex;
            padding-right: 24px;
            margin-right: 20px;
            border-right: 1px solid #E5E7EB;
            &:last-child {
                padding-right: 0;
                margin-right: 0;
                border-right: 0;
            }
            @include respond-below(custom767) {
                padding-right: 0;
                margin-right: 15px;
                border-right: 0;
            }
        }
    }
}
.lang-dropdown {
    position: $__relative;
    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }
    .dropdown-toggle {
        font-size: $__font__size__14;
        font-weight: $__medium;
        color: $__black__ash;
        padding: 10px 10px;
        margin-right: 0;
        background: $__transparent;
        border: 0;
        border-radius: 0;
        position: $__relative;
        display: $__flex;
        @extend %flex__center;
        @extend %flex__start;
        &:last-child {
            margin-right: 0;
        }
        &.show {
            &+.dropdown-menu {
                visibility: $__visible;
                opacity: 1;
                margin-top: 8px !important;
                border-radius: 0 0 5px 5px;
                @include transform(translateY(0));
            }
        }

        &:after {
            border-top: 0;
            border-left: 0;
            border-bottom: 2px solid $__black__ash;
            border-right: 2px solid $__black__ash;
            content: '';
            height: 8px;
            display: $__inline__block;
            pointer-events: $__none;
            -webkit-transform-origin: 66% 66%;
            -ms-transform-origin: 66% 66%;
            transform-origin: 66% 66%;
            @include transform(rotate(45deg));
            @include transition(all 0.15s ease-in-out);
            width: 8px;
            vertical-align: 2px;
            margin-left: 0;
            @include position($__absolute, 15px, -5px, null, null);

            &:hover {
                border-bottom: 2px solid $__black;
                border-right: 2px solid $__black;
            }
        }
        .flag-img {
            margin-right: 10px;
            border-radius: 50px;
            width: 20px;
        }
    }

    &.show {
        &+.dropdown-menu {
            visibility: $__visible;
            opacity: 1;
            margin-top: 0;
            border-radius: 0 0 5px 5px;
            @include transform(translateY(0));
        }
    }

    .dropdown-menu {
        border-radius: 5px;
        @include margin-padding(0, 0);
        min-width: 200px;
        visibility: $__hidden;
        opacity: 0;
        top: 100%;
        left: auto !important;
        right: 0 !important;
        @include transition(all .2s ease);
        display: $__block;
        @include transform(translateY(40px));

        .dropdown-item {
            @extend %flex__center;
            font-size: $__font__size__14;
            border-bottom: 1px solid $__metallic-silver;            
            @include margin-padding(0, 10px 15px);
            img {
                margin-right: 10px;
                width: 20px;
            }
            &:hover {
                color: $__blue__14;
            }
        }
    }

    .dropdown-toggle[aria-expanded="true"]:after {
        @include transform(rotate(-135deg));
    }
}
.log-register {
   background: $__puregold;
   border: 1px solid $__puregold; 
   border-radius: 5px;
   padding: 6px 6px;
   color: $__black__shade;
   font-size: $__font__size__14;
   font-weight: $__medium;
   @extend %flex-align-center;
   min-height: 35px;
   box-shadow: inset 0 0 0 0 $__transparent;   
   @include transition(0.5s);
   &:hover {
       border-color: $__puregold; 
       background: $__transparent;
       box-shadow: inset 0 0 0 50px $__transparent;
       @include transition(0.5s);
   }
   a {
    &:hover {
       color: $__black__shade;
    }
   }
   span {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 50px;
    background: $__white;
    @extend %inlineflex-align-center;
   }
}
.cart-section {
    background: $__white;
    border-bottom: 1px solid $__white_tone;
    @include margin-padding(null, 15px 0);
    @include respond-below(custom991) {
        display: $__none;
    }
}
.cart-logo {
    width: 160px;
}
.cart-search {
    form {
        width: 100%;
        float: $__left;
        border-radius: 6px;
        border: 1px solid $__grey_200;
        @include margin-padding(null, 5px);
        @extend %display-flex;
        @extend %align__center;
    }
    .enter-pincode {
        background: $__sky__04;
        @include margin-padding(null, 10px);
        position: $__relative;
        width: 25%;
        float: $__left;
        i {
            @include position($__absolute, 50%, 0, null, 10px);
            @include transform(translateY(-50%));
            content: "";
            color: $__ash__black;
        }
        .form-control {
            font-size: $__font__size__14;
            font-weight: $__medium;
            color: $__ash__black;
            background: $__transparent;
            min-height: $__auto;
            @include margin-padding(null, 0 0 0 25px);
            border: 0;
            position: $__relative;
            &::placeholder {
                font-size: $__font__size__14;
                font-weight: $__medium;
                color: $__ash__black;
            }
        }
    }
    .cart-search-input {
        width: 65%;
        float: $__left;
        .form-control {
            font-size: $__font__size__14;
            color: $__gray__08;
            background: $__transparent;
            min-height: $__auto;
            @include margin-padding(null, 0 15px 0 24px);
            border: 0;
            position: $__relative;
            &::placeholder {
                font-size: $__font__size__14;
                color: $__gray__08;
            }
        }
    }
    .cart-search-btn {
        margin-left: $__auto;
        .btn {
            font-size: $__font__size__16;
            font-weight: $__medium;
            min-width: 45px;
            @include margin-padding(null, 10px 10px);
            border-radius: 4px;
            background-color:$__blue__14;
            border: 1px solid $__blue__14;
            color: $__white;
            box-shadow: inset 0 0 0 0 $__white;
            @include transition(0.5s);
            &:hover {
                color: $__blue__14;
                border-color: $__blue__14;
                background-color: $__white;
                box-shadow: inset 0 0 0 50px $__white;
                @include transition(0.5s);
            }
        }
    }
}
.shopping-cart-list {
    .nav {
        @extend %flex__end;
        @extend %flex-align-center;
        li {
            padding-right: 16px;
            margin-right: 16px;
            border-right: 1px solid $__white_tone;
            &:last-child {
                padding-right: 0;
                margin-right: 0;
                border-right: 0;
            }
        }
    }
}
.shopping-cart-amount {
    @extend %flex__center;
    .shopping-cart-icon {
        position: $__relative;
        margin-right: 12px;
        span {
            background-color: $__cart__red;
            border-radius: 50px;
            color: $__white;
            font-size: $__font__size__10;
            text-align: $__center;
            position: $__absolute;
            top: -2px;
            right: 0;
            padding: 0 5px;
        }
    }
    .shopping-cart-content {
        p {
            font-size: $__font__size__14;
            font-weight: $__medium;
            color: $__black__01;
            @include margin-padding(0, null);
        }
        h6 {
            font-size: $__font__size__16;
            font-weight: $__semibold;
            color: $__black__ash;
            @include margin-padding(0, null);
        }
    }
}
.browse-categorie {
    margin-right: 140px;
    @include respond-below(custom991) {
        margin-right: 0;
    }
    @include respond-below(custom575) {
        display: $__none;
    }
    .categorie-dropdown {
        .dropdown-toggle {
            font-size: $__font__size__14;
            font-weight: $__medium;
            color: $__black__ash;
            min-width: 190px;
            padding: 10px 10px;
            margin-right: 0;
            background: $__sky__blue__2;
            border: 0;
            border-radius: 5px;
            position: $__relative;
            display: $__flex;
            @extend %flex__center;
            @extend %flex__start;
            &:last-child {
                margin-right: 0;
            }
            &.show {
                &+.dropdown-menu {
                    visibility: $__visible;
                    opacity: 1;
                    margin-top: 0;
                    border-radius: 0 0 5px 5px;
                    @include transform(translateY(0));
                }
            }

            &:after {
                border-top: 0;
                border-left: 0;
                border-bottom: 2px solid $__black__ash;
                border-right: 2px solid $__black__ash;
                content: '';
                height: 8px;
                display: $__inline__block;
                pointer-events: $__none;
                -webkit-transform-origin: 66% 66%;
                -ms-transform-origin: 66% 66%;
                transform-origin: 66% 66%;
                @include transform(rotate(45deg));
                @include transition(all 0.15s ease-in-out);
                width: 8px;
                vertical-align: 2px;
                margin-left: 0;
                @include position($__absolute, 15px, 12px, null, null);

                &:hover {
                    border-bottom: 2px solid $__black;
                    border-right: 2px solid $__black;
                }
            }
            img {
                margin-right: 8px;
            }
        }

        &.show {
            &+.dropdown-menu {
                visibility: $__visible;
                opacity: 1;
                margin-top: 0;
                border-radius: 0 0 5px 5px;
                @include transform(translateY(0));
            }
        }

        .dropdown-menu {
            border-radius: 5px;
            @include margin-padding(0, 0);
            min-width: 190px;
            visibility: $__hidden;
            opacity: 0;
            top: 100%;
            left: auto !important;
            right: 0 !important;
            @include transition(all .2s ease);
            display: $__block;
            @include transform(translateY(40px));

            .dropdown-item {
                @extend %flex__center;
                font-size: $__font__size__14;
                font-weight: $__medium;
                border-bottom: 1px solid $__metallic-silver;            
                @include margin-padding(0, 10px 15px);
                img {
                    margin-right: 10px;
                    width: 20px;
                }
                &:hover {
                    color: $__blue__14;
                }
            }
        }

        .dropdown-toggle[aria-expanded="true"]:after {
            @include transform(rotate(-135deg));
        }
    }
}
.pharmacy-banner {
    background: $__blue__18;
    padding: 40px 0 40px;
    position: $__relative;
    overflow: $__hidden;
    z-index: 1;
}
.pharmacy-shapes {
    position: $__relative;
    @include respond-below(custom991) {
        display: $__none;
    }
    .pharmacy-shape-left {
        @include position($__absolute, -50px, null, null, 0);
        .shape-six {
            @include position($__absolute, 175px, null, null, 0);
        }
    }
    .pharmacy-shape-right {
        @include position($__absolute, -40px, -80px, null, null);
        .shape-eight {
            width: 700px;
        }
        .shape-seven {
            @include position($__absolute, -20px, 0, null, null);
        }
    }
}
.pharmacy-content {
    position: $__relative;
    z-index: 1;
    margin-bottom: 40px;
    @include respond-below(custom767) {
        margin-bottom: 20px;
    }
    h1 {
        font-weight: $__bold;
        font-size: $__font__size__60;
        color: $__white;
        max-width: 486px;
        margin-bottom: 10px;
        @include respond-below(custom1199) {
            font-size: $__font__size__46;
        }
        @include respond-below(custom991) {
            font-size: $__font__size__36;
        }
        @include respond-below(custom767) {
            font-size: $__font__size__30;
        }
    }
    h4 {
        font-weight: $__medium;
        font-size: $__font__size__36;
        color: $__gold__2;
        margin-bottom: 20px;
        @include respond-below(custom1399) {
            font-size: $__font__size__34;
        }
        @include respond-below(custom991) {
            font-size: $__font__size__28;
            margin-bottom: 15px;
        }
         @include respond-below(custom767) {
            font-size: $__font__size__24;
        }
    }
    p {
        font-weight: $__medium;
        font-size: $__font__size__20;
        color: $__white;
        margin-bottom: 0;
        @include respond-below(custom991) {
            font-size: $__font__size__18;
        }
        @include respond-below(custom767) {
            font-size: $__font__size__16;
        }
    }
}
.pharmacy-banner-img {
    position: $__relative;
    z-index: 1;
    -webkit-animation: float 2s ease-in-out infinite;
    animation: float 2s ease-in-out infinite;
    @include respond-below(custom991) {
        display: $__none;
    }
    @keyframes float {
        0% {
            @include transform(translateY(0px));
        }
        50% {
            @include transform(translateY(-15px));
        }
        100% {
            @include transform(translateY(0px));
        }
    }
}
.pharmacy-btn {
    .btn {
        @extend %display-inline-flex;
        border-radius: 6px;
        font-weight: $__semibold;
        font-size: $__font__size__15;
        min-width: 107px;
        padding: 11px 15px;
        background: $__ash__white__2;
        border: 1px solid $__white;
        box-shadow: inset 0 0 0 0 $__blue__18;
        color: $__blue__18;
        @include transition(0.5s);
        &:hover {
            color: $__white;
            background: $__blue__18;
            border-color: $__white;
            box-shadow: inset 0 0 0 50px $__blue__18;
            @include transition(0.5s);
        }
        @include respond-below(custom767) {
            font-size: $__font__size__14;
        }
    }
}
.welcome-section {
    background: $__white;
    position: $__relative;
    overflow: $__hidden;
    @include margin-padding(null, 80px 0 56px);
    @include respond-below(custom991) {
        @include margin-padding(null, 40px 0 16px);
    }
    @include respond-below(custom767) {
        @include margin-padding(null, 40px 0 20px);
    }
}
.welcome-grid {
    background: $__light__lime;
    border-radius: 10px;
    @include margin-padding(0 0 80px, 20px);
    position: $__relative;
    overflow: $__hidden;
    @include respond-below(custom991) {
        @include margin-padding(0 0 40px, 20px);
    }
    @include respond-below(custom767) {
        @include margin-padding(0 0 20px, 20px);
    }
    .welcome-info {
        @extend %flex__center;
        @include respond-below(custom767) {
            @include margin-padding(0 0 20px, null);
        }
        .welcome-icon {
            @extend %flex-align-center;
            margin-right: 15px;
            background: $__white;
            min-width: 70px;
            height: 70px;
            border-radius: 50px;
            @include respond-below(custom767) {
                display: $__none;
            }
        }
        .welcome-content {
            h5 {
                font-size: $__font__size__24;
                font-weight: $__semibold;
                color: $__black__shade;
                margin-bottom: 5px;
                @include respond-below(custom767) {
                    font-size: $__font__size__20;
                }
            }
            p {
                font-size: $__font__size__16;
                font-weight: $__medium;
                color: $__black__01;
                margin-bottom: 0;
                @include respond-below(custom767) {
                    font-size: $__font__size__14;
                }
            }
        }
    }
    .welcome-btn {
        float: $__right;
        position: $__relative;
        @include respond-below(custom767) {
            float: $__left;
        }
        .btn {
            @extend %display-inline-flex;
            border-radius: 6px;
            font-weight: $__semibold;
            font-size: $__font__size__15;
            min-width: 140px;
            padding: 11px 15px;
            background: $__purple__dark;
            border: 1px solid $__purple__dark;
            box-shadow: inset 0 0 0 0 $__white;
            color: $__white;
            @include transition(0.5s);
            &:hover {
                color: $__purple__dark;
                background: $__white;
                border-color: $__purple__dark;
                box-shadow: inset 0 0 0 50px $__white;
                @include transition(0.5s);
            }
            @include respond-below(custom767) {
                font-size: $__font__size__14;
                padding: 10px 15px;
            }
        }
    }
    .welcome-shapes {
        position: $__relative;
        @include respond-below(custom991) {
            display: $__none;
        }
        .welcome-shape-top {
            @include position($__absolute, -40px, null, null, -30px);
        }
        .welcome-shape-bottom {
            @include position($__absolute, 42px, null, null, 50px);
        }
    }
}
.shop-card {
    border-radius: 10px;
    width: $__full__width;
    position: $__relative;
    overflow: $__hidden;
    @include margin-padding(0 0 24px, 24px);
    @include respond-below(custom767) {
        @include margin-padding(0 0 20px, 20px);
    }
    .shop-content {
        h5 {
            font-size: $__font__size__20;
            font-weight: $__semibold;
            color: $__black;
            margin-bottom: 15px;
            @include respond-below(custom767) {
                font-size: $__font__size__18;
            }
        }
        p {
            font-size: $__font__size__12;
            font-weight: $__semibold;
            color: $__black__02;
            margin-bottom: 15px; 
        }
        h6 {
            font-size: $__font__size__16;
            font-weight: $__medium;
            color: $__black__03;
            margin-bottom: 15px;
            @include respond-below(custom767) {
                font-size: $__font__size__14;
            }
        }
        .btn {
            @extend %display-inline-flex;
            border-radius: 5px;
            font-weight: $__medium;
            font-size: $__font__size__12;
            min-width: 80px;
            padding: 4px 8px;
            background: $__white;
            border: 1px solid $__white;
            box-shadow: inset 0 0 0 0 $__blue__18;
            color: $__blue__18;
            @include transition(0.5s);
            &:hover {
                color: $__white;
                background: $__blue__18;
                border-color: $__white;
                box-shadow: inset 0 0 0 50px $__blue__18;
                @include transition(0.5s);
            }
        }
    }
    .shop-img {
        position: $__absolute;
        top: 50%;
        transform: translateY(-50%);
        @include respond-below(custom767) {
            display: $__none;
        }
    }
}
.suppliment-card {
    background: $__light__red;
    h5 {
        span {
            color: $__dark__orange;
        }
    }
    .shop-content {
        .btn {
            background: $__dark__orange;
            border: 1px solid $__dark__orange;
            box-shadow: inset 0 0 0 0 $__white;
            color: $__white;
            @include transition(0.5s);
            &:hover {
                color: $__dark__orange;
                background: $__white;
                border-color: $__dark__orange;
                box-shadow: inset 0 0 0 50px $__white;
                @include transition(0.5s);
            }
        }
    }
}
.freshner-card {
    background: $__blue__19;
    h5 {
        span {
            color: $__blue__18;
        }
    }
    .shop-content {
        .btn {
            background: $__blue__18;
            border: 1px solid $__blue__18;
            box-shadow: inset 0 0 0 0 $__white;
            color: $__white;
            @include transition(0.5s);
            &:hover {
                color: $__blue__18;
                background: $__white;
                border-color: $__blue__18;
                box-shadow: inset 0 0 0 50px $__white;
                @include transition(0.5s);
            }
        }
    }
}
.product-worth-card {
    background: $__purple__light__01;
    h5 {
        span {
            color: $__purple__01;
        }
    }
    .shop-content {
        .btn {
            background: $__purple__01;
            border: 1px solid $__purple__01;
            box-shadow: inset 0 0 0 0 $__white;
            color: $__white;
            @include transition(0.5s);
            &:hover {
                color: $__purple__01;
                background: $__white;
                border-color: $__purple__01;
                box-shadow: inset 0 0 0 50px $__white;
                @include transition(0.5s);
            }
        }
    }
}
.deals-section {
    background: $__gray__light__03;
    position: $__relative;
    overflow: $__hidden;
    @include margin-padding(null, 80px 0 60px);
    @include respond-below(custom991) {
        @include margin-padding(null, 40px 0 20px);
    }
}
.pharmacy-section-header {
    @include margin-padding(0 0 40px, null);
    .pharmacy-title {
        @include respond-below(custom767) {
            @include margin-padding(0 0 15px, null);
        }
        h4 {
            font-size: $__font__size__24;
            font-weight: $__semibold;
            color: $__black__shade;
            margin-bottom: 0; 
        }
    }
    .pharmacy-title-link {
        float: $__right;
        @include respond-below(custom767) {
            float: $__left;
        }
        a {
            font-size: $__font__size__16;
            font-weight: $__semibold;
            color: $__blue__18;
            @include respond-below(custom767) {
               font-size: $__font__size__14; 
            }
            &:hover {
                color: $__purple__dark;
            }
            i {
                margin-left: 2px;
            }
        }
    }
}
.deals-list {
    .nav {
        @include respond-below(custom991) {
            justify-content: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
        }
        li {
            margin-right: 10px;
            @include respond-below(custom575) {
                width: 46%;
                margin: 0 10px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.deals-grid {
    @include margin-padding(0 0 20px, null);
    text-align: $__center;
    .deals-box {
        background: $__grey__02;
        border-radius: 24px;
        @include margin-padding(0 0 16px, 24px);
        text-align: $__center;
        width: 150px;
        height: 138px;
        @include respond-below(custom1399) {
            width: 130px;
        }
        @extend %flex-align-center;
        @include respond-below(custom575) {
            width: 100%;
        }
    }
    .deals-content {
        a {
            font-size: $__font__size__16;
            font-weight: $__semibold;
            color: $__black__shade;
            &:hover {
                color: $__blue__18;
            }
        }
    }
}
.categorie-section {
    background: $__white;
    position: $__relative;
    overflow: $__hidden;
    @include margin-padding(null, 80px 0 56px);
    @include respond-below(custom991) {
        @include margin-padding(null, 40px 0 20px);
    }
}
.categorie-info {
   @include margin-padding(0 0 16px, null);
}
.categorie-grid {
    border-radius: 10px;
    border: 1px solid $__honeydew_color;
    background: $__white;
    box-shadow: $__box__shadow;
    @include margin-padding(0 0 24px, 24px);
    text-align: $__center;
    @include respond-below(custom991) {
        @include margin-padding(0 0 20px, 20px);
    }
    .categorie-img {
        @include margin-padding(0 auto 15px, null);
        width: 90px;
    }
    .categorie-content {
        h5 {
            font-size: $__font__size__18;
            font-weight: $__semibold;
            margin-bottom: 5px;
            @include respond-below(custom767) {
                font-size: $__font__size__16;
            }
            a {
                color: $__black__shade;
                &:hover {
                    color: $__blue__18;
                }
            }
        }
        p {
            font-size: $__font__size__14;
            font-weight: $__medium;
            color: $__ash__black;
            margin-bottom: 0;
        }
    }
}
.categorie-card {
    @include margin-padding(0 0 24px, 24px);
    position: $__relative;
    overflow: $__hidden;
    width: $__full__width;
    border-radius: 10px;
    @extend %flex-align-between;
    @include respond-below(custom991) {
        @include margin-padding(0 0 20px, 20px);
    }
    @include respond-below(custom575) {
        display: $__block;
    }
    .categorie-details {
        @include respond-below(custom575) {
            @include margin-padding(0 0 15px, null);
        }
        h4 {
            font-size: $__font__size__24;
            font-weight: $__semibold;
            color: $__white;
            margin-bottom: 8px;
            max-width: 260px;
            @include respond-below(custom767) {
                font-size: $__font__size__22;
            }
        }
        p {
            font-size: $__font__size__14;
            font-weight: $__medium;
            color: $__white;
            margin-bottom: 0;
        }
    }
    .categorie-btn {
        position: $__relative;
        z-index: 1;
        .btn {
            @extend %inlineflex-align-center;
            border-radius: 5px;
            font-weight: $__bold;
            font-size: $__font__size__14;
            min-width: 140px;
            padding: 9px 15px;
            background: $__white;
            border: 1px solid $__white;
            box-shadow: inset 0 0 0 0 $__blue__18;
            color: $__blue__18;
            @include transition(0.5s);
            @include respond-below(custom767) {
                padding: 8px 15px;
            }
            &:hover {
                color: $__white;
                background: $__blue__18;
                border-color: $__blue__18;
                box-shadow: inset 0 0 0 50px $__blue__18;
                @include transition(0.5s);
                img {
                    filter: invert(1) brightness(1000);
                    @include transition(0.5s);
                }
            }
        }
    }
    .categorie-shapes {
        position: $__absolute;
        right: 0;
        @include respond-below(custom991) {
            display: $__none;
        }
        .categorie-shape-top {
            @include position($__relative, -40px, 160px, null, null);
        }
        .categorie-shape-bottom {
            @include position($__relative, 40px, 280px, null, null);
        }
    }
}
.cash-card {
    background: $__laysgreen;
    .categorie-btn {
        .btn {
            min-width: 130px;
            background: $__white;
            border: 1px solid $__white;
            box-shadow: inset 0 0 0 0 $__blue__18;
            color: $__laysgreen;
            @include transition(0.5s);
            &:hover {
                color: $__white;
                background: $__blue__18;
                border-color: $__blue__18;
                box-shadow: inset 0 0 0 50px $__blue__18;
                @include transition(0.5s);
            }
            img {
                margin-right: 5px;
            }
        }
    }
}
.sale-card {
    background: $__violet__dark;
    .categorie-btn {
        .btn {
            min-width: 150px;
            background: $__blue__ash;
            border: 1px solid $__blue__ash;
            box-shadow: inset 0 0 0 0 $__blue__18;
            color: $__violet__dark;
            @include transition(0.5s);
            &:hover {
                color: $__white;
                background: $__blue__18;
                border-color: $__blue__18;
                box-shadow: inset 0 0 0 50px $__blue__18;
                @include transition(0.5s);
            }
            img {
                margin-left: 5px;
                &:hover {
                    filter: invert(1) brightness(1000);
                }
            }
        }
    }
}
.seller-section {
    background: $__white;
    position: $__relative;
    overflow: $__hidden;
    .seller-info {
        border-top: 1px solid $__white_tone;
        @include margin-padding(null, 80px 0 56px);
        @include respond-below(custom991) {
            @include margin-padding(null, 40px 0 20px);
        }
    }
}
.seller-card {
    background: $__violet__dark;
    @include margin-padding(0 0 24px, 20px 0 0);
    position: $__relative;
    overflow: $__hidden;
    border-radius: 10px;
    @include respond-below(custom991) {
        @include margin-padding(0 0 20px, 20px);
    }
    .seller-content {
        text-align: $__center;
        @include margin-padding(0 0 40px, null);
        h6 {
            font-size: $__font__size__14;
            font-weight: $__bold;
            color: $__black__shade;
            background: $__gold__3;
            border-radius: 10px;
            padding: 3px 10px;
            display: $__inline__block;
            margin-bottom: 20px;
        }
        h4 {
            font-size: $__font__size__36;
            font-weight: $__semibold;
            color: $__sky__04;
            max-width: 190px;
            margin: 0 $__auto 15px;
            @include respond-below(custom991) {
                font-size: $__font__size__28;
            }
            @include respond-below(custom767) {
                font-size: $__font__size__20;
            }
        }
        .btn {
            @extend %inlineflex-align-center;
            border-radius: 10px;
            font-weight: $__semibold;
            font-size: $__font__size__14;
            min-width: 145px;
            padding: 10px 15px;
            background: $__white;
            border: 1px solid $__white;
            box-shadow: inset 0 0 0 0 $__blue__18;
            color: $__violet__dark;
            @include transition(0.5s);
            @include respond-below(custom767) {
                padding: 8px 15px;
            }
            &:hover {
                color: $__white;
                background: $__blue__18;
                border-color: $__blue__18;
                box-shadow: inset 0 0 0 50px $__blue__18;
                @include transition(0.5s);
            }
        }
    }
    .seller-time {
        margin-bottom: 30px;
        @include respond-below(custom991) {
            margin-bottom: 0;
        }
        .nav {
            @extend %flex__justifycenter;
            padding: 0 15px;
            @include respond-below(custom1399) {
                padding: 0 10px;
            }
            li {
                background: $__white;
                padding: 10px;
                margin-right: 4px;
                position: $__relative;
                border-radius: 5px;
                text-align: $__center;
                width: 61px;
                margin-bottom: 8px;
                @include respond-below(custom1399) {
                    width: 58px;
                    margin-right: 3px;
                }
                &:last-child {
                  margin-right: 0;  
                }
                &::after {
                    content: '';
                    width: 40px;
                    height: 4px;
                    border-radius: 50px;
                    position: $__absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                    background: $__violet__dark;
                }
                h5 {
                    font-size: $__font__size__20;
                    font-weight: $__medium;
                    color: $__black;
                    margin-bottom: 0;
                    @include respond-below(custom767) {
                        font-size: $__font__size__18;
                    }
                }
                p {
                    font-size: $__font__size__10;
                    font-weight: $__semibold;
                    color: $__black;
                    margin-bottom: 0;
                }
            }
        }
    }
    .seller-img {
        img {
            width: 300px;
            position: $__absolute;
            right: 0;
            bottom: 0;
            @include respond-below(custom767) {
                display: $__none;
            }
        }
    }
}
.seller-title {
    margin-bottom: 24px;
    @include respond-below(custom991) {
        margin-bottom: 20px;
    }
    h4 {
        font-size: $__font__size__20;
        font-weight: $__bold;
        color: $__black__04;
        margin-bottom: 0;
        @include respond-below(custom767) {
            font-size: $__font__size__18;
        }
    }
}
.seller-grid {
    border-radius: 10px;
    border: 1px solid $__white_tone;
    background: $__white;
    @include margin-padding(0 0 24px, 10px);
    @extend %flex__center;
    @include respond-below(custom991) {
        @include margin-padding(0 0 20px, 10px);
    }
    .seller-icon {
        width: 75px;
        height: 75px;
        margin-right: 15px;
        padding: 10px;
        border-radius: 10px;
        background: $__sky__04;
        @extend %flex-align-center;
    }
    .seller-text {
        overflow: hidden;
        h6 {
            font-size: $__font__size__14;
            font-weight: $__medium;
            color: $__blue__14;
            margin-bottom: 4px;
        }
        h5 {
            font-size: $__font__size__16;
            font-weight: $__semibold;
            margin-bottom: 2px;
            text-overflow: ellipsis;
            overflow: hidden;
            a {
                color: $__black__shade;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                &:hover {
                    color: $__blue__18; 
                }
            }
        }
        p {
            font-size: $__font__size__16;
            font-weight: $__semibold;
            color: $__black__shade;
            margin-bottom: 0;
            span {
                font-size: $__font__size__14;
                color: $__link_water;
                font-weight: $__medium;
                text-decoration: line-through;
            }
        }
    }
}
.trusted-section {
    background: $__sky__04;
    position: $__relative;
    overflow: $__hidden;
    @include margin-padding(null, 80px 0 60px);
    @include respond-below(custom991) {
        @include margin-padding(null, 40px 0);
    }
    .partners-slider {
        li {
            a {
                background: $__transparent;
                border: 0;
                padding: 10px;
                text-align: $__center;
                height: $__initial;
                border-radius: 0;
                img {
                    filter: gray;
                    -webkit-filter: grayscale(1);
                    filter: grayscale(1);
                    &:hover {
                        -webkit-filter: grayscale(0);
                        filter: none;
                    }
                }
            }
        }
    }
    .owl-carousel.partners-slider.owl-loaded {
        @extend %align-items-center;
    }
}
.trusted-info {
    @include margin-padding(0 0 60px, null); 
    @include respond-below(custom991) {
        @include margin-padding(null, 0 0 40px);
    } 
    .trusted-img {
        -webkit-animation: float 2s ease-in-out infinite;
        animation: float 2s ease-in-out infinite;
        @keyframes float {
            0% {
                @include transform(translateY(0px));
            }
            50% {
                @include transform(translateY(-15px));
            }
            100% {
                @include transform(translateY(0px));
            }
        }
        @include respond-below(custom991) {
            display: $__none;
        }
    }
    .trusted-content {
        h4 {
            font-size: $__font__size__36;
            font-weight: $__bold;
            color: $__black__04;
            margin-bottom: 24px;
            @include respond-below(custom991) {
                font-size: $__font__size__30;
                margin-bottom: 20px;
            }
            @include respond-below(custom767) {
                font-size: $__font__size__24;
            }
        }
    }
    .trusted-text {
        margin-bottom: 24px;
        @include respond-below(custom991) {
            margin-bottom: 20px;
        }
        h6 {
            font-size: $__font__size__18;
            font-weight: $__medium;
            color: $__black__04;
            margin-bottom: 10px;
            @extend %flex__center;
            @include respond-below(custom767) {
                font-size: $__font__size__16;
            }
            i {
                font-size: $__font__size__20;
                color: $__purple__dark;
                margin-right: 10px;
            }
        }
        p {
            font-size: $__font__size__14;
            color: $__gray__08;
            max-width: 500px;
            margin-bottom: 0;
            @include respond-below(custom991) {
                max-width: $__inherit;
            }
        }
    }
    .trusted-btn {
        .btn {
            @extend %inlineflex-align-center;
            border-radius: 6px;
            font-weight: $__semibold;
            font-size: $__font__size__15;
            min-width: 130px;
            padding: 11px 15px;
            background: $__purple__dark;
            border: 1px solid $__purple__dark;
            box-shadow: inset 0 0 0 0 $__white;
            color: $__white;
            @include transition(0.5s);
            &:hover {
                color: $__purple__dark;
                background: $__white;
                border-color: $__purple__dark;
                box-shadow: inset 0 0 0 50px $__white;
                @include transition(0.5s);
            }
            @include respond-below(custom767) {
                font-size: $__font__size__14;
                padding: 10px 15px;
            }
            i {
                margin-left: 6px;
                font-size: $__font__size__18;
            }
        }
    }
}
.review-section {
    background: $__blue__dark;
    position: $__relative;
    overflow: $__hidden;
    @include margin-padding(null, 80px 0);
    @include respond-below(custom991) {
        @include margin-padding(null, 40px 0);
    }
    .review-big-img {
        -webkit-animation: float 2s ease-in-out infinite;
        animation: float 2s ease-in-out infinite;
        @keyframes float {
            0% {
                @include transform(translateY(0px));
            }
            50% {
                @include transform(translateY(-15px));
            }
            100% {
                @include transform(translateY(0px));
            }
        }
        @include respond-below(custom991) {
            display: $__none;
        }
    }
}
.review-grid {
    @include margin-padding(0 0 40px, null);
    &:last-child {
        @include margin-padding(0, null);
    }
    @extend %display-flex;
    @extend %align-items-start;
    .review-img {
        width: 80px;
        min-width: 80px;
        height: 80px;
        border-radius: 50px;
        margin-right: 15px;
        @include respond-below(custom767) {
            width: 60px;
            min-width: 60px;
            height: 60px;
        }
        img {
            width: 80px;
            min-width: 80px;
            height: 80px;
            border-radius: 50px;
            @include respond-below(custom767) {
                width: 60px;
                min-width: 60px;
                height: 60px;
            }
        }
    }
    .review-content {
        .rating {
            margin-bottom: 5px;
            i {
                color: $__gold__4;
            }
        }
        p {
            font-size: $__font__size__16;
            color: $__white;
            margin-bottom: 15px;
            @include respond-below(custom767) {
                font-size: $__font__size__14;
            }
        }
        h5 {
            font-size: $__font__size__18;
            font-weight: $__semibold;
            color: $__white;
            margin-bottom: 0;
            @include respond-below(custom767) {
                font-size: $__font__size__16;
            }
        }
    }
}
.news-section {
    background: $__white;
    position: $__relative;
    overflow: $__hidden;
    @include margin-padding(null, 80px 0 56px);
    @include respond-below(custom991) {
        @include margin-padding(null, 40px 20px);
    }
}
.news-grid {
    border-radius: 0px 0px 10px 10px;
    background: $__white;
    box-shadow: $__box__shadow;
    @include margin-padding(0 0 24px, null);
    @include respond-below(custom991) {
        @include margin-padding(0 0 20px, null);
    }
    .news-image {
        @include margin-padding(null, null);
        position: $__relative;
        overflow: $__hidden;
        border-radius: 10px 10px 0px 0px;
        img {
            @include transform(translateZ(0));
            @include transition(all 2000ms cubic-bezier(.19,1,.22,1) 0ms);
            border-radius: 10px 10px 0px 0px;
        }
        a {
            &:hover img {
                @include transform(scale(1.15));
            }
        }
        .news-date {
            position: $__absolute;
            bottom: 0;
            border-radius: 4px;
            background: $__white;
            padding: 6px 10px;
            margin: 24px;
            width: 58px;
            height: 58px;
            text-align: $__center;
            @include respond-below(custom767) {
                margin: 20px;
            }
            h5 {
                font-size: $__font__size__24;
                font-weight: $__semibold;
                color: $__black__05;
                margin-bottom: 0;
                @include respond-below(custom767) {
                    font-size: $__font__size__20;
                }
            }
            p {
                font-size: $__font__size__12;
                font-weight: $__medium;
                color: $__black__ash;
                margin-bottom: 0;
            }
        }
    }
    .news-content {
        @include margin-padding(null, 24px);
        @include respond-below(custom991) {
            @include margin-padding(null, 20px);
        }
        .nav {
            margin-bottom: 15px;;
            li {
                margin-right: 15px;
                margin-bottom: 5px;
                &:last-child {
                    margin-right: 0;
                }
                p {
                    font-size: $__font__size__14;
                    font-weight: $__medium;
                    color: $__black__05;
                    margin-bottom: 0;
                    @extend %flex__inlinecenter;
                    img {
                        margin-right: 4px;
                    }
                }
            }
        }
        .news-title {
            font-size: $__font__size__20;
            font-weight: $__semibold;
            color: $__black__shade;
            margin-bottom: 0;
            @include respond-below(custom767) {
                font-size: $__font__size__16;
            }
            a {
                color: $__black__shade;
                &:hover {
                    color: $__blue__18;
                }
            }
        }
    }
}
.products-sec {
    background: $__sky__04;
    @include margin-padding(null, 80px 0 40px);
    @include respond-below(custom991) {
        @include margin-padding(null, 40px 0 20px);
    }
    &.recent-product-sec {
        background: $__white;
    }
}
.products-card {
    border: 1px solid $zinc-200;
    background: $__white;
    @include margin-padding(0 0 40px, 24px);
    box-shadow: 0px 7.5px 17.5px 0px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    position: $__relative;
    @include respond-below(custom991) {
        @include margin-padding(0 0 20px, 20px);
    }
    .product-card-img {
        margin-bottom: 15px;
        border: 1px solid $__white__shade;
        border-radius: 10px;
        text-align: $__center;
        min-height: 250px;
        @extend %flex-align-center;
        overflow: hidden;
        img {
            @include transform(translateZ(0));
            @include transition(all 2000ms cubic-bezier(.19,1,.22,1) 0ms);
        }        
        &:hover img {
            @include transform(scale(1.15));
        }
        @include respond-below(custom1399) {
            min-height: 220px;
        }
    }
    .product-content {
        h6 {
            color: $__blue__14;
            font-size: $__font__size__14;
            margin-bottom: 5px;
        }
        p {
            color: $__black__ash;
            margin-bottom: 0;
        }
        h4 {
            font-size: $__font__size__20;
            font-weight: $__bold;
            margin-bottom: 8px;
            a {
                color: $__black__shade;
                &:hover {
                    color: $__blue__18;
                }
            }
            @include respond-below(custom1399) {
                font-size: $__font__size__18;
            }
        }
        .badge {
            background: #FED78A;
            border-radius: 10px;
            font-size: $__font__size__14;
            color: $__black__shade;
            font-weight: $__bold;
        }
        h5 {
            font-size: $__font__size__14;
            margin-bottom: 0;
            color: $__black__ash;
            span {
                color: #334155;
                font-weight: $__bold;
            }
        }
        .sticker {
            border-radius: 0px 10px 10px 0px;
            background: #B91C25;
            padding: 8px 10px;
            display: $__inline__block;
            line-height: $__normal;
            color: $__white;
            font-size: $__font__size__14;
            font-weight: $__semibold;
            position: $__absolute;
            left: 0;
            top: 24px;
        }
        .product-cart {
            @include margin-padding(15px 0 0, 15px 0 0);
            border-top: 1px solid$__white__shade;
            @extend %flex__center;
            @extend %flex__between;
            .cart-price {
                h5 {
                    font-size: $__font__size__20;
                    font-weight: $__bold;
                    margin-bottom: 0;
                    color: $__black__shade;
                    span {
                        font-size: $__font__size__14;
                        color: $__link_water;
                        font-weight: $__medium;
                        display: $__block;
                        margin-top: 3px;
                        text-decoration: line-through;
                    }
                }
            }
            .cart-icon {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #E4E4E7;
                @extend %flex-align-center;
                color: rgba(15, 23, 42, 1);
                &:hover {
                    background: $__blue__14;
                    color: $__white;
                }
                &.check-icon {
                    background: #10B981;
                    color: $__white;
                }
            }
            .cart-count {
                display: $__flex;
                align-items: $__center;
                .custom-increment.cart {
                    border: 1px solid #E5E7EB;
                    padding: 6px;
                    border-radius: 50px;
                    margin-right: 10px;
                }
                .custom-increment.cart input[type=text] {
                    width: 20px;
                    padding: 0;
                    border: 0;
                    margin: 0 10px;
                }
                .input-group-btn {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        display: $__flex;
                        align-items: $__center;
                        justify-content: $__center;
                    button {
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        border-radius: 50%;
                        display: $__flex;
                        align-items: $__center;
                        justify-content: $__center;
                        background: rgba(212, 212, 216, 1);
                        color: $__white;
                        padding: 0;
                    }
                }
            }
        }
    }
    .product-icon {
        span {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            @extend %flex-align-center;
            background: $__blue__ash;
            margin-bottom: 15px;
        }
    }
}
.best-sell-box {
    background: $__light__lime;
    @include margin-padding(0 0 40px, 24px);
    border-radius: 10px;
    @include respond-below(custom991) {
        @include margin-padding(0 0 20px, 20px);
    }
    h5 {
        color: #0F172A;
        font-weight: $__bold;
        margin-bottom: 10px;
    }
    p {
        color: #334155;
        font-size: $__font__size__14;
        margin-bottom: 0;
    }
    &.box-two {
        background: #F0FDF4;
    }
}
.app-sec-twelve {
    &.pharmacy-app-sec {
        .app-twelve {
            @include margin-padding(null, 0 0 80px);
            @include respond-below(custom991) {
                @include margin-padding(null, 0 0 40px);
            }
            .app-header {
                h2 {
                    font-size: $__font__size__36;
                    @include respond-below(custom991) {
                        font-size: $__font__size__30;
                    }
                }
            }
        }
        .app-scan {
            @include margin-padding(null, 0);
        }
    }
}
.app-section {
    &.app-sec-twelve {
        &.pharmacy-app-sec {
            .app-bg {
                background-image: url(../images/pharmacy-app-bg.png);
                background-color: rgba(14, 130, 253, 1);
                background-size: 70% 100%;
                background-repeat: no-repeat;
                background-position: right;
                border-radius: 25px;
                position: relative;
                .app-bgs {
                    @include position(absolute, 0, 0, null, null);
                }
            }
            
        }
    }
}  
.more-coupon-sec {
    background: $__blue__18;
    @include margin-padding(0,80px 0 56px);
    @include respond-below(custom991) {
        @include margin-padding(null, 40px 0 20px);
    }
    .pharmacy-section-header {
        h4 {
            color: $__white;
        }
        .pharmacy-title-link a {
            color: $__white;
            &:hover {
                color: $__puregold;
            }
        }
    }
}
.coupon-card {
    box-shadow: 0px 7.5px 17.5px 0px rgba(0, 0, 0, 0.05);
    background: $__white;
    @include margin-padding(0 0 24px,24px);
    @extend %flex__center;
    @extend %flex__between;
    border-radius: 10px;
    @include respond-below(custom991) {
        @include margin-padding(0 0 20px, 20px);
    }
    .coupon-info {
        h4 {
            color: $__black;
            font-size: $__font__size__20;
            margin-bottom: 10px;
            font-weight: $__semibold;
            @include respond-below(custom767) {
                font-size: $__font__size__16;
            }
        }
        p {
            font-size: $__font__size__14;
            font-weight: $__medium;
            color: $__ash__black;
            margin-bottom: 0;
        }
    }
    .coupon-code {
        span {
            display: $__inline__block;
            border: 1px dashed $__puregold;
            background: #FEF8E8;
            color: $__puregold;
            font-size: $__font__size__12;
            font-weight: $__semibold;
            border-radius: 30px;
            padding: 5px 10px;
        }
    }
}
.app-sec-twelve {
    &.pharmacy-app-sec {
        .app-twelve {
            .mobile-img {
                @include respond-below(custom1399) {
                    bottom: -20px;
                }
            }
        }
    }
}
.footer {
    &.pharmacy-footer {
        background: linear-gradient(97deg, #E8F1FF 44.44%, rgba(77, 142, 232, 0.00) 92.82%);
        .top-footer {
            @extend %flex__center;
            @include margin-padding(0,40px 0 24px);
            border-bottom: 1px solid $__honeydew_color;
            .footer-logo {
                margin-right: 70px;
                @include respond-below(custom767) {
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }
            .doccure-info {
                p {
                    max-width: 800px;
                    margin-bottom: 0;
                    color: $__midnight_black;
                    font-size: $__font__size__16;
                    @include respond-below(custom991) {
                        font-size: $__font__size__15;
                    }
                }
            }
            @include respond-below(custom767) {
                display: block;
            }
        }
        .mid-footer {
            @include margin-padding(0 0 20px,40px 0 0);
            .footer-links {
                margin-bottom: 20px;
                h4 {
                    font-size: $__font__size__20;
                    color: $__black__shade;
                    font-weight: $__semibold;
                    margin-bottom: 20px;
                    @include respond-below(custom991) {
                        font-size: $__font__size__18;
                    }
                }
                .pages-links {
                    li {
                        margin-bottom: 10px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        a {
                            color: $__black__shade;
                            font-size: $__font__size__14;
                            @include respond-below(custom767) {
                                font-size: $__font__size__14;
                            }
                            &:hover {
                                color: $__blue__18;
                            }
                        }
                    }
                }
                .app-store-links {
                    @extend %flex__center;
                    margin-top: 40px;
                    a {
                        margin-right: 20px;
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
            .input-block {
                position: $__relative;
                input {
                    height: 45px;
                    border-radius: 6px;
                    padding-right: 100px;
                    border: 1px solid rgba(229, 231, 235, 1);
                    font-size: $__font__size__14;
                    font-weight: $__medium;
                    &:placeholder {
                        color: $__bodycolor;
                    }
                }
                button {
                    background: $__blue__14;
                    height: 46px;
                    border-color: $__blue__14;
                    position: $__absolute;
                    right: 0; 
                    top: 0;
                    border-radius: 0 6px 6px 0;  
                    color: $__white;
                    border: 0;    
                    padding: 5px 17px;  
                    font-weight: $__semibold;
                    &:hover {
                        background: $__blue__dark;
                        border-color: $__blue__dark;
                    }             
                }
            }
        }
        .mid-foot-two {
            @include margin-padding(0,30px 0 20px);
            border-top: 1px solid $__honeydew_color;
            .payment-methods {
                margin-bottom: 10px;
                li {
                    @include margin-padding(0 20px 0 0,0 20px 0 0);
                    border-right: 1px solid $__honeydew_color;
                    @include respond-below(custom767) {
                        @include margin-padding(0 10px 0 0,0 10px 0 0);
                    }
                    &:last-child {
                        border: 0;
                        @include margin-padding(0,0);
                    }
                }
            }
            .social-icons {
                justify-content: $__end;
                margin-bottom: 10px;
                @include respond-below(custom767) {
                    justify-content: $__start;
                }
                li {
                    margin-right: 5px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        background: $__black__shade;
                        color: $__white;
                        @extend %flex-align-center;
                        &:hover {
                            background: $__blue__18;
                            color: $__white;
                        }
                    }
                }
            }
        }
        .footer-bottom {
            padding: 20px;
            background: linear-gradient(97deg, #E8F1FF 44.44%, rgba(232, 241, 255, 0.00) 92.82%);
            p {
                font-size: $__font__size__14;
                font-weight: $__medium;
                margin-bottom: 0;
                color: $__black__05;
            }
        }
    }
}
.doc-info-left {
    .doctor-img1 {
        margin-right: 20px;
        @include respond-below(custom767) {
            margin-bottom: 10px;
            margin-right: 0;
        }
    }
}
.product-description {
    .doctor-img1 {
        width: 40%;
    }
    .doc-info-cont {
        width: 60%;
    }
} 
ul {
    &.benifits-col {
        li {
            i {
                font-size: 20px;
                margin-right: 20px;
                color: #20c0f3;
            }
        } 
    } 
}