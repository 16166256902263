.footer {
    background-color: $__blue;
	&.footer-one {
		.footer-bottom {
			.copyright {
				.copyright-text {
					p {
						color: $__midnight_black;
						@include respond-below(custom767) {
							text-align: $__center;
						}
					}
				}
			}
		}
	}
    .footer-top {
		@include margin-padding(null, 40px 0);
		@include respond-below(custom991) {
			padding-bottom: 10px;
		}
		.footer-title {
		    color: $__white;
		    font-size: $__font__size__20;
		    font-weight: $__bold;
		    @include margin-padding(0 0 30px, null);
		    text-transform: $__capitalize;
		    @include respond-below(custom767) {
		    	font-size: $__font__size__18;
		    }
		    @include respond-below(custom575) {
		    	font-size: $__font__size__18;
		    }
		}
		.footer-widget {
			@include respond-below(custom991) {
				margin-bottom: 30px;
			}
			&.footer-menu {
				ul {
					list-style: $__none;
				    outline: $__none;
				    @include margin-padding(0,0);
				    li {
				    	margin-bottom: 10px;
					    position: $__relative;
					    &:last-child {
							margin-bottom: 0;
						}
						a {
							color: $__white;
							font-size: $__font__size__15;
							padding-left: 20px;
							position: $__relative;
							@include transition(all 0.4s ease 0s);
							&:hover {
								color: $__white;
								letter-spacing: 0.5px;
								padding-left: 30px;
							}
							&::before {
								content: "\f101";
								font-family: "Font Awesome 5 Free";
								font-weight: 900;
								margin-right: 5px;
								@include position($__absolute, 0, null, null, 0);
							}
						}
				    }
				}
			}
			.footer-logo {
				@include margin-padding(0 50px 30px 0, null);
				max-width: 300px;
			}
			.footer-about-content {
				 p {
					color: $__white;
					&:last-child {
						margin-bottom: 0;
					}
				}
				.social-icon ul {
					list-style: $__none;
					@extend %inline-flex;
					@include margin-padding(0,0);
					li {
						margin-right: 15px;
						&:last-child {
							margin-right: 0;
						}
						a {	
							color: $__white;
							@extend %inline-flex;
							font-size: $__font__size__20;
							@include transition(all 0.4s ease 0s);
							&:hover {
								color: $__primarycolor;
							}
						}
					}
				}
			}
			.footer-contact-info {
				color: $__white;
				font-size: $__font__size__15;
				.footer-address {
					@extend %display-flex;
					span {
						margin-right: 20px;
						i.fa-map-marker-alt {
							font-size: $__font__size__20;
						}
					}
					p {
						@include margin-padding(0 0 1rem, null);
					}
				}
				p {
					i {
						margin-right: 15px;
					}
				}
			}
		}
	}
	.footer-bottom {
		.copyright {
			border-top: 1px solid $__steel__blue;
			@include margin-padding(null, 30px 0);
			.copyright-text {
				p {
					font-size: $__font__size__15;
					color: $__white;
					a {
						color: $__midnight_black;
						font-weight: 500;
						@include transition(all 0.4s ease 0s);
						&:hover {
							color: $__blue__14;
						}
					}
					&.title {
						font-weight: $__regular;
						@include margin-padding(10px 0 0, null);
					}
				}	
			}
			.policy-menu {
			    font-size: $__font__size__14;
			    text-align: $__right;
			    @include margin-padding(0,0);
			    @include respond-below(custom767) {
			    	margin-top: 10px;
	    			text-align: $__left;
			    }
			    li {
				    display: $__inline__block;
				    margin-right: 15px;
				    &:last-child {
						margin-right: 0;
					}
					a {
					    color: $__white;
					    &:hover, &:focus {
					    	color: $__blue__14;
					    }
					}
					&::after {
						color: $__white;
					    content: "|";
					    font-weight: $__light;
					    @include position($__relative, null, null, null, 10px);
					}
					&:last-child::after {
					    content: "";
					}
				}
			}
		}		
	} 
}


