.doctor-content {
	background-color: $__white;
    @include margin-padding(0, 140px 0 60px);
    @include respond-below(custom991) {
        @include margin-padding(0, 100px 0 40px);
    }
    @include respond-below(custom767) {
        @include margin-padding(0, 100px 0 20px);
    }
	&.content {
		@include margin-padding(0, 60px 0);
		@include respond-below(custom991) {
			@include margin-padding(0, 40px 0);
		}
		@include respond-below(custom767) {
			@include margin-padding(0, 20px 0);
		}
	}
}
.back-link {
	padding-bottom: 30px;
	a {
		font-size: $__font__size__15;
		color: $__nickel_color;
		@extend %flex__inlinecenter;
		&:hover {
			color: $__primarycolor;
		}
		i {
			margin-right: 10px;
		}
	}
    @include respond-below(custom767) {
        padding-bottom: 25px;
    }
}
.paitent-header {
	padding-bottom: 40px;
    .paitent-title {
        font-size: $__font__size__22;
        margin-bottom: 0;
        @include respond-below(custom991) {
            font-size: $__font__size__20;
        }
        @include respond-below(custom767) {
            font-size: $__font__size__18;
        }
    }
    @include respond-below(custom991) {
        padding-bottom: 30px;
    }
    @include respond-below(custom767) {
        padding-bottom: 20px;
    }
}
.booking-header {
	padding-bottom: 25px;
    .booking-title {
        font-weight: $__medium;
        font-size: $__font__size__18;
        margin-bottom: 0;
    }
    @include respond-below(custom767) {
        padding-bottom: 20px;
    }       
}
.payment-form-group {
	margin-bottom: 10px;
}
.payments-form {
    .custom_radio {
        font-weight: $__medium;
        color: $__black;
        padding-left: 26px;
        margin-bottom: 0;
        input {
            &:checked {
                ~ .checkmark {
                    border: 5px solid $__blue__14;
                }
            }
        }
        .checkmark {
            top: $__inherit;
            border: 2px solid $__secondarycolor;
            &:after {
                top: -1px;
                left: -1px;
                background: $__white;
				width: 0;
				height: 0;
            }
        }
    }
}
.card-label {
	& > label {
		background-color: $__white;
		color: $__grey_900;
		display: $__inline__block;
		font-size: $__font__size__13;
		font-weight: $__medium;
		@include margin-padding(6px $__auto $__auto 8px, 0 7px);
	}
	& > .form-control {
		@include margin-padding(-13px 0 0, 6px 15px 0);
		min-height: 48px;
		&::placeholder {
			font-weight: $__medium;
			color: $__blue_shade;
		}
	}
}
.booking-btn {
	.btn {
		min-width: 123px;
		font-weight: $__medium;
		@extend %display-inline-flex;
		@include margin-padding(0, 8px 10px);
		border-radius: 5px;
		i {
			font-size: $__font__size__18;
			color: $__white;
			position: $__relative;
			left: 8px;
			@include transition(0.7s);
		}
		&:hover {
			i {
				color: $__blue__14;
				left: 12px;
				@include transition(0.7s);
			}
		}
        @include respond-below(custom767) {
            min-width: 100px;
			@include margin-padding(0, 7px 10px);
        }  
	}
    @include respond-below(custom991) {
        padding-bottom: 25px;
    }
}
.booking-card {
	border: 2px solid $__grey_whitish;
	border-radius: 8px;
	margin-bottom: 25px;
    .booking-card-body {
        padding: 15px;
    }
    @include respond-below(custom991) {
        margin-bottom: 20px;
    }
}
.booking-doctor-details {
	@extend %flex-align-between;
}
.booking-doctor-left {
	@extend %flex__center;
}
.booking-doctor-img {
	width: 45px;
	height: 45px;
	margin-right: 15px;
	border-radius: 50%;
	img {
		width: 45px;
		height: 45px;
		border-radius: 50%;
	}
}
.booking-doctor-info {
	h4 {
		font-weight: $__medium;
		font-size: $__font__size__16;
		margin-bottom: 5px;
		a {
			&:hover {
				color: $__blue__14;
			}
		}
	}
	p {
		color: $__argent_color;
		margin-bottom: 0;
	}
}
.booking-doctor-right {
	p {
		@extend %flex__center;
		margin-bottom: 0;
	}
	i {
		margin-right: 18px;
		font-size: $__font__size__20;
		color: $__green;
	}
	a {
		font-weight: $__medium;
		text-decoration: $__underline;
		color: $__blue__14;
		&:hover {
			color: $__black;
		}
	}
}
.booking-list-body {
	padding-bottom: 5px !important;
	.booking-doctor-right {
		padding-bottom: 10px;
	}
}
.booking-list {
	@extend %flex-align-between;
	@extend %flex__wrap;
}
.booking-date-list {
	width: 300px;
	ul {
		@include margin-padding(0, 0);
		list-style: $__none;
		li {
			font-weight: $__medium;
			color: $__charcoal_color;
			padding-bottom: 10px;
			display: $__flex;
    		justify-content: space-between;
			span {
				@extend %flex__inlinecenter;
				font-weight: $__normal;
				color: $__steel_color;
				float: $__right;
				text-align: $__right;
				i {
					font-size: $__font__size__16;
					margin-right: 6px;
				}
			}
		}
	}
    @include respond-below(custom1399) {
        width: 100%;
    }
    &.consultation-date-list {
        width: $__full__width;
    }
}
.proceed-btn {
	text-align: $__right;
	.btn {
        @include margin-padding(0, 8px 15px);
		min-width: 209px;
        @include respond-below(custom767) {
            min-width: 200px;
        }
	}
    @include respond-below(custom991) {
        padding-bottom: 0;
    }
}
.custom_radio {
	color: $__grey_800;
	@extend %flex__inlinecenter;
	position: $__relative;
	font-size: $__font__size__14;
	padding-left: 30px;
	margin-bottom: 10px;
	cursor: $__pointer;
	user-select: $__none;
	input {
		position: $__absolute;
		opacity: 0;
		&:checked {
			& ~ .checkmark {
				border: 1px solid $__blue__14;
				&:after {
					@include transform(scale(1));
					opacity: 1;
					visibility: $__visible;
				}
			}
		}
	}
	.checkmark {
        @include position($__absolute, 0, null, null, 0);
		height: 18px;
		width: 18px;
		background-color: $__white;
		border: 1px solid $__pearl_white;
		border-radius: 50%;
		@include transition(all 0.3s ease-in-out);
		&:after {
			display: $__block;
			content: "";
			@include position($__absolute, 4px, null, null, 3px);
			opacity: 0;
			width: 9px;
			height: 9px;
			border-radius: 50%;
			background: $__blue__14;
			visibility: $__hidden;
			@include transform(scale(0.1));
			@include transition(all 0.3s ease-in-out);
		}
	}
}
.radio_input {
	.custom_radio {
		& + .custom_radio {
			margin-left: 15px;
		}
	}
}
