
.request-content-info {
  @include margin-padding(0px, 10px);
  .request-content {
    position: $__relative;
    .request-info {
      position: $__relative;
      z-index: 1;
      .btn {
        width: $__full__width;
        color: $__white;
        background: $__blue__14;
        border: 1px solid $__blue__14;
        box-shadow: inset 0 0 0 0 $__white;
        @include transition(0.7s);
        font-weight: $__semibold;
        padding: 10px 15px;
        @include respond-below(custom767) {
          padding: 8px 15px;
        }
        &:hover {
          color: $__blue__14;
          background: $__white;
          border: 1px solid $__blue__14;
          box-shadow: inset 0 0 0 50px $__white;
          @include transition(0.7s);
        }
      }
      .form-control {
        font-size: $__font__size__14;
      }
      .back-btn-light {
        .btn {
          color: $__black;
          background-color: $__platinum;
          border: 1px solid $__platinum;
          box-shadow: inset 0 0 0 0 $__white;
          &:hover {
            color: #000;
            background-color: $__white;
            border: 1px solid $__light-gray;
            box-shadow: inset 0 0 0 50px $__white;
          }
          i {
            margin-right: 10px;
          }
        }
      }
    }
    .medication-details {
      @extend %display-flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $__light-gray;
      border-radius: 5px;
      padding: 20px 50px 20px 20px;
      margin-bottom: 50px;

      .medication-details-item {
        flex-grow: 1;
      }
      .back-to-search {
        margin-right: 30px;
        a {
          font-size: $__font__size__15;
          color: $__nickel_color;
          @extend %flex__inlinecenter;
          &:hover {
            color: $__blue__14;
          }
          i {
            margin-right: 10px;
          }
        }
      }
      @include respond-below(custom991) {
        margin-bottom: 20px;
      }
      @include respond-below(custom767) {
        margin-bottom: 10px;
      }
      @include respond-below(custom575) {
        flex-direction: column;
      }
    }
    .rx-request-summary {
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      border: 1px solid $__light-gray;
    }
  }
  .request-details-title {
    margin-bottom: 15px;
    h3 {
      font-size: 25px;
      font-weight: bold;
    }
    p {
      font-size: 14px;
    }
    .request-form-header {
      @extend %flex-justify-between;
      @extend %flex-align-center;
      img {
        height: 70px;
      }
      h3 {
        align-self: center;
        padding-right: 30px;
      }
    }
  }
  //@include respond-below(custom991) {
  //  @include margin-padding(0, 90px 0 40px);
  //}
}