
.banner-wrapper {
    max-width: 800px;
    width: $__full__width;
    @include margin-padding(0 $__auto, null);
    @include respond-below(custom991) {
        max-width: 720px;
    }
    .search-box-3 {
        background: $__info_sky;
        border-radius: 10px;
        @include position($__absolute, null, null, null, null);
        @include margin-padding(-50px 0 0, 40px 20px);
        @include respond-below(custom991) {
            display: $__none;
        }
        form {
            @extend %display-flex;
        }
        .search-location {
            -ms-flex: 0 0 240px;
            flex: 0 0 240px;
            margin-right: 12px;
            position: $__relative;
            width: 240px;
            .form-control {
                background: $__white url(../images/location.png) no-repeat 10px $__center;
            }
        }
        .form-group {
            margin-bottom: 0px;
        }
        .form-control {
            border: 1px solid $__goose-gray;
            box-shadow: inset 0 0px 0px rgb(0 0 0 / 8%);
            border-radius: 5px;
            padding-left: 35px;
        }
        .search-info {
            -ms-flex: 0 0 490px;
            flex: 0 0 490px;
            margin-right: 12px;
            position: $__relative;
            width: 490px;
            .form-control {
                background: $__white url(../images/search.png) no-repeat 10px $__center;
            }
        }
        .form-control {
            background: $__white url(../images/search.png) no-repeat 10px $__center;
        }
        .search-btn {
            width: 46px;
            -ms-flex: 0 0 46px;
            flex: 0 0 46px;
            height: 46px;
            span {
                display: $__none;
                font-weight: $__medium;
            }
        }
    }
}
.rx-request-steps {
    .work-step-box {
        background: $__white;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 15px;
        padding: 24px;
        box-shadow: 0px 7.5px 17.5px 0px #0000000D;
        display: flex;
        align-items: center;
        //justify-content: center;
        .step-img {
            margin-right: 20px;
            flex-shrink: 0;
            img {
                width: 50px;
            }
        }
        .step-desc {
            flex-grow: 1;
            min-height: 95px;
            h6 {
                font-weight: 600;
                font-size: 18px;
                margin-bottom: 10px;
                color: $__dark__blue;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            p {
                font-size: $__font__size__14;
                color: $__black__01;
                margin-bottom: 0;
            }
        }
    }
}
.steps-to-follow {
    max-width: 900px;
    width: $__full__width;
    @include margin-padding(0 $__auto, null);
    padding: 0 0 56px;
    @include respond-below(custom991) {
        max-width: 820px;
        padding: 0 0 36px;
    }
    .bg-shapes {
        img {
            position: absolute;
            bottom: 59px;
            right: 0;
            z-index: -1;
        }
    }
    .box-detail {
        background: $__white;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 24px;
        padding: 24px;
        box-shadow: 0px 7.5px 17.5px 0px #0000000D;
        display: flex;
        align-items: center;
        justify-content: center;
        .steps-list-box {
            position: relative;
            .steps-list-img {
                width: 140px;
                height: 60px;
                margin: 0 auto 10px;
            }
            h6 {
                font-weight: 600;
                font-size: 18px;
                margin-bottom: 10px;
                color: $__dark__blue;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: center;
            }
            p {
                font-size: $__font__size__14;
                color: $__black__01;
                text-align: center;
                margin-bottom: 0;
            }
            span {
                color: $__primarycolor;
                font-weight: 700;
                font-size: 30px;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 12%);
            }
        }
    }
    .gallery-box-left {
        padding-top: 30px;
        .box-detail {
            margin-left: 24px;
            margin-right: 0;
            @include respond-below(custom1199) {
                margin-left: 0;
            }
        }
    }
    .section-header-one {
        h2 {
            font-size: $__font__size__26;
            color: $__dark__blue;
        }
        .need-to-know-content {
            margin-top: 50px;
            .need-to-know-content-btn {
                border-radius: 35px;
                padding: 10px 30px;
                border: 3px solid $__blue__14;
                font-size: 18px;
                font-weight: 500;
                color: $__blue__14;
                box-shadow: inset 0 0 0 0 $__blue__14;
                transition: all 0.7s;
                @include respond-below(custom991 ) {
                    font-size: 16px;
                }
                &:hover {
                    box-shadow: inset 0 0 0 50px $__blue__14;
                    color: $__white;
                }
                &.book-btn {
                    background-color: $__blue__14;
                    color: $__white;
                    box-shadow: inset 0 0 0 0 $__white;
                    &:hover {
                        box-shadow: inset 0 0 0 50px $__white;
                        color: $__blue__14;
                    }
                }
            }
        }
    }
}
.btn-search {
    background: $__white;
    border: 1px solid $__white;
    color: $__info_sky;
}

.form-custom {
    i {
        color: $__ash__red;
        @include position($__absolute, 35%, 15px, null, null);
    }
}
.doctor-search-form select {
    border: 1px solid rgba(16, 222, 253, 0.1);
    box-shadow: 0px 4px 4px rgb(237 237 237 / 25%);
    border-radius: 5px;
}
.doctor-search-form input {
    border: 1px solid rgba(16, 222, 253, 0.1);
    box-shadow: 0px 4px 4px rgb(237 237 237 / 25%);
    border-radius: 5px;
}
.doctor-search-form {
    input, select {
        background: $__white;
        border-radius: 130px;
        min-height: 60px;
        border: 0;
    }
}
