.appointment-wrap {
	.appointment-detail-btn {
		a {
			display: flex;
			text-align: center;
			align-items: center;
			&:hover {
				background: $__blue__14;
				color: $__white;
			}
		}
	}
	&.appointment-grid-wrap {
		flex-direction: column;
		align-items: start;
		padding: 24px;
		width: 100%;
		margin-bottom: 24px;
		> ul {
			> li {
				width: 100%;
				margin-bottom: 24px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			.appointment-grid-head {
				display: flex;
				align-items: center;
				justify-content: space-between;
				.grid-user-msg {
					width: 36px;
					height: 36px;
					border-radius: 50%;
					border: 1px solid $__honeydew_color;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					flex-shrink: 0;
					.video-icon {
						i {
							color: $__indigo-500;
						}
					}
					.hospital-icon {
						i {
							color: $__emerald-600;
						}
					}
					.telephone-icon {
						i {
							color: $__indigo-800;
						}
					}
				}
			}
			.appointment-info {
				background: $__white__shade;
				padding: 15px;
				border-radius: 10px;
			}
			.appointment-action {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			.appointment-start {
				a {
					font-size: $__font__size__14;
				}
			}
		}
	}
}

.dashboard-header {
	.header-back {
		display: flex;
		align-items: center;
		h3 {
			margin-bottom: 0;
		}
		a {
			display: inline-block;
			margin-right: 8px;
		}
	}
}
.appointment-detail-card {
	border: 1px solid $__honeydew_color;
	margin-bottom: 24px;
	.patinet-information {
		a {
			flex-shrink: 0;
			img {
				width: 120px;
				height: 120px;
				@include respond-below(custom575) {
					width: 90px;
					height: 90px;
				}
			}
		}
		.mail-info-patient {
			ul {
				margin-top: 10px;
				li {
					& +li {
						margin-top: 5px;
					}
				}
			}
		}
	}
	.appointment-info {
		.person-info {
			p {
				font-size: $__font__size__14;
				color: $__black__ash;
				font-weight: $__medium;
			}
			& +.person-info {
				margin-top: 10px;
			}
		}
		.apponitment-types {
			li {
				i {
					margin-right: 5px;
					&.text-green {
						color: $__emerald-600;
					}
				}
			}
		}
	}
	.appointment-action {
		text-align: $__right;
		@include respond-below(custom575) {
			text-align: left;
		}
		.consult-fees {
			h6 {
				font-size: $__font__size__14;
				margin-bottom: 10px;
			}
		}
		ul {
			justify-content: end;
			@include respond-below(custom575) {
				justify-content: start;
			}
		}
		.reject-popup {
			font-weight: $__semibold;
			font-size: $__font__size__14;
			text-decoration: underline;
		}
	}
	.detail-badge-info {
		margin-bottom: 10px;
		display: inline-flex;
		align-items: center;
		span {
			padding: 10px;
			font-size: $__font__size__12;
			color: $__white;
			font-weight: $__medium;
			border-radius: 30px;
			&.bg-grey {
				background: $__bodycolor;
			}
			&.bg-yellow {
				background: #EAAB08;
			}
			&.bg-green {
				background: $__teal;
			}
			&.bg-red {
				background: #EF444E;
			}
			&.bg-soft-red {
				background: #FEF2F2;
				color: #DE3F44;
			}
			&.bg-soft-green {
				background: #F0FDF4;
				color: #1CA345;
			}
		}
	}
}
.detail-card-bottom-info {
	padding-top: 24px;
	margin-top: 9px;
	border-top: 1px solid $__honeydew_color;
	li {
		h6 {
			color: $__black__ash;
			margin-bottom: 5px;
			font-size: $__font__size__14;
		}
		span {
			color: $__black__shade;
			font-weight: $__normal;
			font-size: $__font__size__15;
		}
		.start-btn {
			a {
				background: $__blue__14;
				border-color: $__blue__14;
				font-size: $__font__size__14;
				font-weight: $__medium;
				&:hover {
					border-color: $__blue__14;
					background: $__white;
					color: $__blue__14;
				}
			}
		}
	}
}
.recent-appointments {
	.head-text {
		margin-bottom: 24px;
		font-size: $__font__size__20;
		font-weight: $__semibold;
	}
}
.btn-primary-border {
	border: 1px solid $__blue__14;
	color: $__blue__14;
	font-weight: $__semibold;
	padding: 5px 10px;
	border-radius: 6px;
	font-size: $__font__size__15;
	&:hover {
		background: $__blue__14;
		color: $__white;
	}
}
.modal {
	.modal-header {
		justify-content: space-between;
	}
}
.custom-modal-two {
	.modal-content {
		padding: 24px;
		@include respond-below(custom991) {
			padding: 15px;
		}
		.modal-header {
			padding: 0 0 15px 0;
			margin-bottom: 15px;
			h5 {
				font-weight: $__semibold;
				@include respond-below(custom575) {
					font-size: 18px;
				}
			}
			button {
				border: 0;
				background: $__honeydew_color;
				border-radius: 50%;
				color: $__white;
				font-size: $__font__size__10;
				width: 20px;
				height: 20px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				transition: 0.5s all;
				&:hover {
					background: $__cart__red;
					transition: 0.5s all;
				}
			}
		}
		.modal-body {
			padding: 0;
		}
	}
	
}
.create-appointment-details {
	.session-end-head {
		background: $__sky__04;
		padding: 15px;
		border-radius: 10px;
		margin-bottom: 24px;
		h6 {
			margin-bottom: 0;
			color: $__black__shade;
			font-weight: $__semibold;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			span {
				color: $__black__05;
				font-weight: $__medium;
				margin-right: 5px;
			}
		}
	}
	.head-text {
		font-size: $__font__size__20;
		margin-bottom: 24px;
		color: $__black__shade;
		font-weight: $__semibold;
	}
}
.create-details-card {
	border: 1px solid $__honeydew_color;
	padding: 24px;
	border-radius: 10px;
	box-shadow: 0px 7.5px 17.5px 0px #0000000D;
	margin-bottom: 24px;
	.create-details-card-head {
		border-bottom: 1px solid #E2E8F0;
		margin-bottom: 24px;
	}
	.card-title-text {
		h5 {
			font-size: $__font__size__18;
			margin-bottom: 15px;
			color: $__black__shade;
			font-weight: $__semibold;
		}
	
	}
	.patient-info-box {
		background: $__sky__04;
		padding: 15px 15px 0;
		border-radius: 10px;
		margin-bottom: 24px;
		.info-list {
			margin-bottom: 15px;
			li {
				font-weight: $__medium;
				font-size: $__font__size__14;
				color: $__black__ash;
				h6 {
					margin-bottom: 0;
					font-size: $__font__size__14;
				}
				& +li {
					margin-top: 5px;
				}
			}
		}
	}
}
.create-details-card-body {
	.form-bg-title {
		background: $__sky__04;
		padding: 10px;
		margin-bottom: 15px;
		h5 {
			font-size: $__font__size__18;
			margin-bottom: 0;
			font-weight: $__semibold;
		}
	}
	.input-block-new {
		margin-bottom: 24px;
	}
}
.input-block-new {
	position: relative;
	label {
		font-size: $__font__size__14;
	}
	.input-text-field {
		position: relative;
		.input-group-text {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			background: transparent;
			border: 0;
			border-left: 1px solid #E2E8F0;
			padding: 0 0 0 10px;
			line-height: 1;
			border-radius: 0;
			color: #131022;
			font-weight: $__medium;
			font-size: $__font__size__14;
		}
		input {
			padding-right: 45px;
			height: 40px;
			min-height: 40px;
			border: 1px solid #E2E8F0;
			border-radius: 10px;
		}
	}
	input {
		height: 40px;
		min-height: 40px;
		border-radius: 6px;
		&::placeholder {
			color: $__slate-500;
		}
	}
	textarea {
		border: 1px solid #E2E8F0;
		border-radius: 6px;
		resize: none;
	}
	.bootstrap-tagsinput {
		min-height: 40px;
		border: 1px solid #E2E8F0;
		border-radius: 6px;
		height: auto;
		.badge-info {
			background: $__white__shade !important;
			border: 0;
			border-radius: 10px;
			font-size: $__font__size__14;
			padding: 5px;
			color: $__bodycolor;
		}
		.tag [data-role=remove] {
			left: 0;
			margin-left: 5px;
		}
		input {
			line-height: 30px;
			&::placeholder {
				font-size: $__font__size__14;
				color: $__bodycolor;
			}
		}
	}
	.input-field-set {
		display: flex;
		align-items: center;
		label {
			width: 50%;
			margin-bottom: 0;
			@include respond-below(custom575) {
				width: 100%;
			}
		}
	}
	.input-text {
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		color: $__blue__14;
		font-weight: $__medium;
	}
}
.medication-wrap {
	.input-block-new {
		flex: 1;
		label {
			display: block;
		}
		margin-right: 15px;
		.select2-container .select2-selection--single {
			height: 40px;
			min-height: 40px;
			border: 1px solid #E2E8F0;
			border-radius: 6px;
		}
		.select2-container--default .select2-selection--single .select2-selection__rendered {
			line-height: 40px;
			color: $__bodycolor;
		}
		.select2-container--default .select2-selection--single .select2-selection__arrow {
			height: 40px;
		}
		.select2-container--default .select2-selection--single .select2-selection__arrow b {
			border-width: 0 2px 2px 0 !important;
			transform: rotate(45deg) translatey(-50%);
			border-color: #6B7280 !important;
			padding: 3px;
		}
	}
}
.form-set-button {
	display: flex;
	align-items: center;
	justify-content: end;
	.btn-light {
		background: $__athens_grey;
		color: $__midnight_black;
		border-color: $__athens_grey;
		padding: 8px 20px;
		margin-right: 15px;
		font-weight: $__semibold;
	}
	.btn-primary {
		background: $__blue__14;
		border-color: $__blue__14;
		padding: 8px 20px;
		font-weight: $__semibold;
		@include respond-below(custom575) {
			padding: 10px;
		}
		&:active, &:active:not(:disabled):not(.disabled) {
			background: $__blue__14;
			border-color: $__blue__14;
		}
	}
}
.daterange-wraper {
	margin-bottom: 20px;
	width: 230px;
	input.form-control {
		border: 1px solid #E2E8F0;
		min-height: 36px;
		height: 36px;
		border-radius: 10px;
		padding-left: 35px;
		color: #6B7280;
		&::placeholder {
			color: #6B7280;
		}
	}
	i {
		position: absolute;
		left: 15px;
		top: 50%;
		transform: translateY(-50%);
		color: #6B7280;
	}
}
.social-media-links {
	.select-social-link {
		flex-basis: 27%;
		margin-right: 15px;
	}
	.select2-container .select2-selection--single {
		border: 1px solid #E2E8F0;
		box-shadow: 0px 1px 2px 0px #0000000D;
		background: transparent;
		height: 40px;
    	min-height: 40px;
		border-radius: 6px;
	}
	.select2-container {
		width: 100% !important;
	}
	.select2-container--default .select2-selection--single .select2-selection__rendered {
		line-height: 40px;
		span {
			img {
				width: 16px;
				margin-right: 10px;
			}
		}
	}
	.select2-container--default .select2-selection--single .select2-selection__arrow {
		height: 40px;
	}
}
.select2-results__option .social-img {
	margin-right: 10px;
}
.daterangepicker .ranges li.active {
	background: $__blue__14;
}