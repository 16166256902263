.terms-text {
    margin-bottom: 20px;
    h4 {
	    font-size: $__font__size__24;
	    font-weight: $__medium;
	    margin-bottom: 20px;
	}
	p {
	    color: $__light__smokey-gray;
	    display: $__inline__block;
	    font-size: $__font__size__16;
	}
}