.content {
    min-height: 600px;
    @include margin-padding(null, 90px 0 36px);
	@include respond-below(custom991) {
    	@include margin-padding(null, 60px 0 40px);
    }
    @include respond-below(custom767) {
    	@include margin-padding(null, 50px 0 30px);
    }
}
.top-space {
	padding-top: 140px;
}
.account-page .content {
	&.top-space {
		padding-top: 140px;
	}
}
.card {
    border: 1px solid $__light-silver;
    margin-bottom: 1.875rem;
    @include respond-below(custom767) {
    	margin-bottom: 20px;
    }
    @include respond-below(custom575) {
    	margin-bottom: 0.9375rem;
    }
    .card-header {
    	background-color: $__white;    
		border-bottom: 1px solid $__light-silver;
		@include respond-below(custom575) {
			@include margin-padding(null, .75rem 1.25rem);
		}
		.card-title {
		    margin-bottom: 0;
		}
	}
}
.card-body {
	@include margin-padding(null, 1.5rem);
	@include respond-below(custom575) {
		@include margin-padding(null, 1.25rem);
	}
}
.card-title {
    margin-bottom: 15px;
}
.card-header {
	border-bottom: 1px solid $__light-silver;
	@include margin-padding(null, 1rem 1.5rem);
	@include respond-below(custom575) {
		@include margin-padding(null, .75rem 1.25rem);
	}
}
.card-footer {
	background-color: $__white;
	border-top: 1px solid $__light-silver;
	@include margin-padding(null, 1rem 1.5rem);
	@include respond-below(custom575) {
		@include margin-padding(null, .75rem 1.25rem);
	}
}
.custom-modal {
	.modal-content {
    	border: 0;
    	@include rounded(10px);
    	.modal-header {
		    @include margin-padding(null, 1.25rem);
		    .modal-title {
			    font-size: $__font__size__20;
				@include respond-below(custom575) {
					font-size: $__font__size__18;
				}
			}
		}
    }
    .close {
	    background-color: $__ash__gray;
	    color: $__white;
	    font-size:  $__font__size__17;
	    height: 20px;
	    line-height: 20px;
	    opacity: 1;
	    width: 20px;
	    z-index: 99;
	    @include rounded(50%);
	    @include margin-padding(0, 0);
	    @include position($__absolute, 26px, 20px, null, null);
	}
	.modal-body {
	    @include margin-padding(null, 1.25rem);
	}
	.modal-footer {
		padding: 1.25rem;
		.btn-outline {
			margin: .25rem $__auto;
		}
	}
}
.custom-medicalrecord-modal {
	.modal-content {
		.modal-header {
			border-color: $__light__cloud;
			@include margin-padding(1.5rem 1.5rem 0 1.5rem, 0 0 1rem);
		}
		.form-control, .bootstrap-tagsinput {
			background-color: $__white__08;
			border-color: $__white__08;
		}
		.select2-container {
			.select2-selection--single {
				background-color: $__white__08;
				border-color: $__white__08;
			}
		}
		.upload-medical-records {
			position: $__relative;
			input[type="file"] {
				width: $__full__width;
				height: $__full__height;
				opacity: 0;
				cursor: $__pointer;
				@include position($__absolute, 0, 0, 0, 0);
			}
			.dropzone {
				min-height: 180px;
				@extend %flex-align-center;
				@extend %flex-wrap;
				h5 {
					color: $__granite__01;
					margin-bottom: 3px;
				}
				h6 {
					color: $__granite__01;
					margin-bottom: 3px;
					span {
						text-decoration: $__underline;
					}
				}
			}
		}
	}
}