.section-search {
    background: $__platinum url(../images/search-bg.png) no-repeat bottom center;
    min-height: 730px;
    background-size: $__full__width auto;
    position: $__relative;
    background-blend-mode: Darken;
    @include margin-padding(null, 150px 0 80px 0);
    @include respond-below(custom991) {
        min-height: 330px;
    }
    @include respond-below(custom767) {
        background: $__platinum;
        @include margin-padding(null, 100px 0 50px 0);
    }
    @include respond-below(custom479) {
        min-height: 410px;
        @include margin-padding(null, 100px 15px 30px 15px);
    }
    .banner-wrapper {
        max-width: 870px;
        width: $__full__width;
        @include margin-padding(0 auto, null);
        @include respond-below(custom991) {
            max-width: 720px;
        }
        .banner-header {
            @include margin-padding(0 0 30px, null);
            h1 {
                font-size: $__font__size__36;
                font-weight: $__semibold;
                @include margin-padding(0 0 10px, null);
                @include respond-below(custom991) {
                    font-size: $__font__size__34;
                }
                @include respond-below(custom767) {
                    font-size: $__font__size__32;
                }
                @include respond-below(custom575) {
                    font-size: $__font__size__28;
                }
            }
            p {
                color: $__smokey-gray;
                font-size: $__font__size__18;
                @include margin-padding(null null 0, null);
                @include respond-below(custom767) {
                    font-size: $__font__size__16;
                }
            }
        }
        .search-box {
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 30px;
            @include respond-below(custom767) {
                max-width: 535px;
                @include margin-padding(0 auto 20px, null);
            }
            form {
                @extend %display-flex;
                @include respond-below(custom767) {
                    -ms-flex-direction: column;
                    flex-direction: column;
                    word-wrap: break-word;
                    background-clip: border-box;
                }
                @include respond-below(custom575) {
                    display: $__block;
                }
            }
            .form-control {
                border: 1px solid $__goose-gray;
                @include rounded(5px);
                padding-left: 35px;
                @include box-shadow(inset, 0, 0px, 0px, null, rgba(0,0,0,.075));
                &::placeholder {
                    font-size: $__font__size__14;
                }
            }
            .search-location {
                -ms-flex: 0 0 200px;
                flex: 0 0 200px;
                margin-right: 12px;
                position: $__relative;
                width: 200px;
                @include respond-below(custom767) {
                    -ms-flex: 0 0 $__full__width;
                    flex: 0 0 $__full__width;
                    width: $__full__width;
                }
                @include respond-below(custom575) {
                    width: $__full__width !important;
                    -ms-flex: $__none;
                    flex: $__none;
                }
                .form-control {
                    background: $__white url(../images/location.png) no-repeat 10px $__center;
                }
            }
            .search-info {
                -ms-flex: 0 0 550px;
                flex: 0 0 550px;
                margin-right: 12px;
                position: $__relative;
                width: 550px;
                @include respond-below(custom991) {
                    -ms-flex: 0 0 250px;
                    flex: 0 0 250px;
                    width: 250px;
                }
                @include respond-below(custom767) {
                    -ms-flex: 0 0 $__full__width;
                    flex: 0 0 $__full__width;
                    width: $__full__width;
                }
                @include respond-below(custom575) {
                    width: $__full__width !important;
                    -ms-flex: $__none;
                    flex: $__none;
                }
                .form-control {
                    background: $__white url(../images/search.png) no-repeat 10px $__center;
                }
            }
            .search-btn {
                width: 46px;
                -ms-flex: 0 0 46px;
                flex: 0 0 46px;
                height: 46px;
                color: $__white;
                margin-top: 1px;
                @include respond-below(custom767) {
                    -ms-flex: 0 0 $__full__width;
                    flex: 0 0 $__full__width;
                    min-height: 46px;
                    width: $__full__width;
                }
                span {
                    display: $__none;
                    font-weight: $__medium;
                    @include respond-below(custom767) {
                        display: $__inline__block;
                        margin-left: 5px;
                        text-transform: $__uppercase;
                    }
                }
            }
            .form-text {
                color: $__smokey-gray;
                font-size: $__font__size__13;
                display: inline-block;
            }
            @include respond-below(custom767) {
                max-width: 535px;
                @include margin-padding(0 auto 20px, null);
            }
        }
    }
    .easy-steps-wrapper {
        @extend %flex-align-center;
        @extend %flex-column;

        h3 {
        }

        .easy-steps {
            font-size: $__font__size__18;
            color: $__smokey-gray;;
            max-width: 700px;
            text-align: left;
            @include respond-below(custom767) {
                font-size: $__font__size__16;
            }
        }
    }
}
.section-header {
    margin-bottom: 60px;
    h5 {
        font-weight: $__semibold;
        font-size: $__font__size__18;
        color: $__blue__14;
        margin-bottom: 10px;
    }
    h2 {
        font-size: $__font__size__36;
        font-weight: $__medium;
        @include margin-padding(null null 0, null);
        @include respond-below(custom767) {
            font-size: $__font__size__30;
        }
        @include respond-below(custom575) {
            font-size: $__font__size__24;
        }
    }
    .sub-title {
        color: $__smokey-gray;
        font-size: $__font__size__16;
        max-width: 600px;
        @include margin-padding(15px auto 0, null);
        @include respond-below(custom767) {
            font-size: $__font__size__14;
        }
        @include respond-below(custom575) {
            font-size: $__font__size__14;
        }
    }
    p {
        color: $__smokey-gray;
        font-size: $__font__size__16;
        @include margin-padding(15px 0 0 0, null);
        @include respond-below(custom767) {
            font-size: $__font__size__15;
        }
        @include respond-below(custom575) {
            font-size: $__font__size__15;
        }
    }
}
.section-specialities {
    background-color: $__white;
    @include margin-padding(null, 80px 0);
    @include respond-below(custom991) {
        @include margin-padding(null, 50px 0);
    }
    @include respond-below(custom479) {
        background-color: $__white;
        @include margin-padding(null, 30px 0);
    }
    .specialities-slider {
        .slick-slide {
            display: $__block;
            @include margin-padding(10px 20px 0 0, 0 12px);
            @include respond-below(custom767) {
                margin-right: 15px;
            }
            @include respond-below(custom479) {
                margin-right: 10px;
            }
        }
        // .slick-dots {
        //     margin-top: 44px;
        //     position: $__unset;
        // }
        .speicality-item {
            p {
                font-size: $__font__size__16;
                font-weight: $__medium;
                @include margin-padding(30px 0 0, null);
                @include respond-below(custom767) {
                    font-size: $__font__size__14;
                }
                @include respond-below(custom575) {
                    font-size: $__font__size__14;
                }
            }
            .speicality-img {
                position: $__relative;
                height: 150px;
                @include rounded(100%);
                width: 150px;
                background: $__white;
                margin: 0 $__auto;
                @include box-shadow(null, 2px, 2px, 13px, null, rgba(0, 0, 0, 0.1));
                @include respond-below(custom479) {
                    width: 120px;
                    height: 120px;
                }
                img {
                    @include transform(translateY(-50%));
                    @include position($__absolute, 50%, 0, null, 0);
                    @include margin-padding(0 auto, null);
                    @include respond-below(custom479){
                        width: 50px;
                    }
                }
                span {       
                    @extend %flex-align-center;
                    @include rounded(50%);
                    @include margin-padding(null, 5px);
                    background-color: $__white;
                    @include position($__absolute, null, 10px, 10px, null);
                    @extend %inline-flex;
                    @extend %justify-content-center;
                    @include box-shadow(null, 1px, 6px, 14px, null, rgba(0, 0, 0, 0.2));
                    i {
                        font-size: $__font__size__15;
                        color: $__celeste;
                    }
                }
            }
        }
    }
}
.slick-dots {
    display: $__block;
    width: $__full__width;
    list-style: $__none;
    text-align: $__center;
    @include position($__absolute, null, null, -25px, null);
    @include margin-padding(0,0);
    li {
        position: $__relative;
        display: $__inline__block;
        width: 20px;
        height: 5px;      
        cursor: $__pointer;
        @include margin-padding(0 5px,0);
        button {
            font-size: 0;
            line-height: 0;
            display: $__block;
            width: 20px;
            height: 5px;
            cursor: $__pointer;
            color: $__transparent;
            border: 0;
            outline: $__none;
            background: $__jet__gray;
            @include margin-padding(null, 0);
            &:hover, &:focus {
                outline: $__none;
            }
            &:hover:before, &:focus:before {
                opacity: 1;
            }
            &:before {
                display: $__none;
                font-family: 'slick';
                font-size: $__font__size__76;
                line-height: 20px;
                width: 20px;
                height: 20px;
                content: '-';
                text-align: $__center;
                opacity: .25;
                color: $__black;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                @include position($__absolute, 0, null, null, 0);
            }
        }
        &.slick-active button {
            background-color: $__celeste;
            &:before {
                opacity: 1;
                color: $__celeste;
            }
            .slick-slide {
                outline: $__none !important;
            }
        }
    }
}
.section-doctor {
    background-color: $__light-pale-blue;
     @include margin-padding(null, 80px 0);
     @include respond-below(custom767) {
        @include margin-padding(null, 50px 0);
    }
    .profile-widget {
        @include box-shadow(null, 2px, 2px, 13px, null, rgba(0, 0, 0, 0.1));
        margin-bottom: 0;
    }
    .section-header {
         @include margin-padding(0 0 30px, null);
        p {
            margin-top: 10px;
        }
    }
    .about-content {
        @include respond-below(custom991) {
            margin-bottom: 30px;
        }
         p {
            font-size: $__font__size__14;
            font-weight: $__regular;
            line-height: 26px;
            @include margin-padding(0, null);
            & + p {
                margin-top: 20px;
            }
        }
        a {
            background-color: $__celeste;
            color: $__white;
            display: $__inline__block;
            font-size: $__font__size__16;
            font-weight: $__medium;
            min-width: 150px;
            @include margin-padding(30px 0 0, 15px 20px);
            text-align: $__center;
            @include rounded(4px);
            @include respond-below(custom767) {
                @include margin-padding(null, 12px 20px);
            }
            &:hover, &:focus {
                background-color: $__dark-celeste;
                border-color: $__dark-celeste;
                color: $__white;
            }
        }
    }
    .doctor-slider {
        @include respond-below(custom767) {
            margin-top: 25px;
        }
        .slick-slide {
            display: $__block;
            width: 280px;
            margin-bottom:0;
            @include margin-padding(null, 10px);
        }
    }
}
.profile-widget {
    background-color: $__white;
    border: 1px solid $__light-silver;
    position: $__relative;
    margin-bottom: 30px;
    @include margin-padding(null, 15px);
    @include rounded(4px);
    @include transition(all .3s ease 0s);
    .fav-btn {        
        background-color: $__white;
        width: 30px;
        height: 30px;
        color: $__dark-blue;
        opacity: 0;
        visibility: $__hidden;
        z-index: 99;
        @include position($__absolute, 5px, 5px, null, null);
        @extend %flex-align-center;
        @extend %inline-flex;
        @extend %justify-content-center;
        @include transform(translate3d(100%, 0, 0));
        @include rounded(3px);
    }
    &:hover .fav-btn {
        opacity: 1;
        visibility: $__visible;
        @include transform(translate3d(0, 0, 0));
        &:hover {
            background-color: $__red;
            color: $__white;
            @include transform(translate3d(0, 0, 0));
        }
    }
    .verified {
        color: $__green;
        margin-left: 3px;
    }
    .rating {
        color: $__smokey-gray;
        font-size: $__font__size__14;
        margin-bottom: 15px !important;
        i {
            font-size: $__font__size__14;
        }
    }
    .doc-img {
        position: $__relative;
        overflow: $__hidden;
        z-index: 1;
        @include rounded(4px);
        img {
            @include rounded(4px);
            @include transform(translateZ(0));
            @include transition(all 2000ms cubic-bezier(.19,1,.22,1) 0ms);
            width: $__full__width;
        }
        &:hover img {
            @include transform(scale(1.15));
        }
    }
    .pro-content {
        @include margin-padding(null, 15px 0 0);
        .title {
            font-size: $__font__size__17;
            font-weight: $__medium;
            margin-bottom: 5px;
            a {
                display: $__inline__block;
            }
        }
        p {
            &.speciality {
                font-size: $__font__size__13;
                color: $__smokey-gray;
                margin-bottom: 5px;
                min-height: 40px;
            }
        }
        .rating {
            list-style: $__none;
            width: $__full__width;
            @include margin-padding(0 0 7px, 0);
            i {
                color: $__light-goose-gray;
                &.filled {
                    color: $__dull-gold;
                }
            }
        }
        .available-info {
            font-size: $__font__size__13;
            color: $__smokey-gray;
            font-weight: $__regular;
            list-style: $__none;
            @include margin-padding(auto auto 15px, 0);
            li {
                i {
                    width: 22px;
                }
                & + li {
                    margin-top: 5px;
                }
            }
        }
        .row {
            &.row-sm {
                margin-left: -3px;
                margin-right: -3px;
                & > div {
                    padding-left: 3px;
                    padding-right: 3px;
                }
            }
        }
    }
}
.section-features {
    background-color: $__white;
    @include margin-padding(null, 80px 0);
    @include respond-below(custom767) {
        @include margin-padding(null, 50px 0);
    }
    @include respond-below(custom479) {
        background-color: $__white;
        @include margin-padding(null, 30px 0);
    }
    .features-img {
        @include respond-below(custom767) {
            text-align: $__center;
            @include margin-padding(0 auto, null);
        }
    }
    .features-slider {
        .slick-slide {
            margin-right: 62px;
        }
        .slick-center {
            opacity: 1;
            @include transform(scale(1.20));
        }
        .slick-list {
            @include margin-padding(null, 16px 50px !important);
        }
        .slick-dots {
            margin-top: 44px;
            position: $__unset;
            @include respond-below(custom767) {
                text-align: $__center;
                @include margin-padding(0 auto, null);
            }
        }
        .feature-item {
            img {
                box-shadow: 1px 6px 14px rgba(0,0,0,0.2);
                height: 115px;
                object-fit: $__cover;
                width: 115px;
                @include rounded(100%);
            }
            p {
                font-weight: $__medium;
                @include margin-padding(20px 0 0, null);
            }
        }
    }
}
.section-blogs {
    background-color: $__light-pale-blue;
    @include margin-padding(null, 80px 0);
    @include respond-below(custom767) {
        @include margin-padding(null, 50px 0);
    }
    @include respond-below(custom479) {
        @include margin-padding(null, 30px 0);
    }
    .view-all {
        .btn {
            margin-top: 20px;
            font-size: $__font__size__16;
            font-weight: $__bold;
            @include margin-padding(null, 12px 30px);
        }
    }
}
.rating {
    list-style: $__none;
    width: $__full__width;
    @include margin-padding(0 0 7px, 0);
    i {
        color: $__light-goose-gray;
        &.filled {
            color: $__dull-gold;
        }
    }
}
.home-tile-section {
	background-color: $__light-pale-blue;
    @include margin-padding(null, 80px 0);
    @include respond-below(custom767) {
        @include margin-padding(null, 50px 0);
    }
    @include respond-below(custom479) {
        @include margin-padding(null, 30px 0);
    }
    .doctor-book-card {
        position: $__relative;
        max-width: $__full__width;
        box-shadow: 0px 3px 20px #0000000b;
        overflow: $__hidden;
        @include margin-padding(0 auto, null);
        @include respond-below(custom575) {
            margin-bottom: 0.9375rem;
        }
        img {
            vertical-align: $__middle;
            border-radius: .25rem;
            @include transition(all 2000ms cubic-bezier(.19,1,.22,1) 0ms);
        }
        .doctor-book-card-content {
            color: $__light__vampire;
            width: $__full__width;
            height: $__full__height;
            border-radius: .25rem;
            @include position($__absolute, 50%, null, null, 50%);
            @include transform(translate(-50%, -50%));
            @include margin-padding(null, 20px);
            & > div {
                width: $__full__width;
                @include transform(translate(-50%, -50%));
                @include position($__absolute, 50%, null, null, 50%);
            }
            .book-btn1 {
                font-size: $__font__size__14;
            }
        }
        .tile-card-content-1 {
            background: rgba(13, 13, 13, 0.33);
        }
        h3 {
            text-shadow: 2px 4px 3px rgb(0 0 0 / 30%);
            color: $__white;
        }
        &:hover {
            img {
                @include transform(scale(1.15));
            }
        }
    }
}

.banner-section {
    background: $__blue-50;
    padding: 100px 0 30px;
    position: $__relative;
    overflow: $__hidden;
    z-index: 1;
    &::after {
        content: '';
        @include background-properties($__cover, $__no_repeat, $__center);
        @include position($__absolute, 0, null, null, 0);
        width: $__full__width;
        height: $__full__height;
        z-index: -1;
    }
}
.banner-shapes {
    position: $__relative;
    .banner-shape-left {
        @include position($__absolute, 600px, null, null, 0);
    }
    .banner-shape-right {
        @include position($__absolute, 30px, null, null, 0);
        @include transform(rotate(180deg));
    }
}
.banner-content {
    padding-bottom: 80px;
    position: $__relative;
    h1 {
        font-weight: $__semibold;
        font-size: $__font__size__48;
        margin-bottom: 15px;
        color: $__midnight_black;
        span {
            color: $__blue__14;
        }
        @include respond-below(custom991) {
            font-size: $__font__size__36;
        }
        @include respond-below(custom767) {
            font-size: $__font__size__28;
            margin-bottom: 20px;
        }
    }
    .header-icon {
        position: $__absolute;
        right: -30px;
        top: -15px;
        @include respond-below(custom1199) {
            display: $__none;
        }
    }
    p {
        font-weight: $__medium;
        font-size: $__font__size__20;
        margin-bottom: 40px;
        color: $__black__01;
        @include respond-below(custom767) {
            font-size: $__font__size__16;
            margin-bottom: 20px;
        }
    }
    .btn {
        font-weight: $__medium;
        border-radius: 8px;
        @include margin-padding(0, 12px 31px);
        min-width: 214px;
        display: $__inline__block;
        background-color:$__blue__14;
        border: 1px solid $__blue__14;
        color: $__white;
        font-size: $__font__size__20;
        box-shadow: inset 0 0 0 0 $__white;
        @include transition(0.5s);
        &:hover {
            color: $__blue__14;
            border-color: $__blue__14;
            background-color: $__white;
            box-shadow: inset 0 0 0 50px $__white;
            @include transition(0.5s);
        }
        @include respond-below(custom991) {
            font-size: $__font__size__16;
            @include margin-padding(0, 10px 15px);
            min-width: 190px;
        }
        @include respond-below(custom767) {
            font-size: $__font__size__15;
            @include margin-padding(0, 9px 15px);
            min-width: 170px;
        }
    }
    @include respond-below(custom991) {
        text-align: $__center;
        padding-bottom: 40px;
    }
}
.banner-arrow-img {
    position: $__absolute;
    left: 215px;
    top: 195px;
    @include respond-below(custom1199) {
        display: $__none;
    }
}
.search-box-one {
    min-width: 800px;
    background: $__white;
    border: 1px solid #F0F0F0;
    box-shadow: 0px 3px 53px rgb(197 197 197 / 20%);
    border-radius: 7px;
    @include margin-padding(0, 24px);
    display: $__table;
    position: $__relative;
    z-index: 1;
    form {
        float: $__left;
        width: $__full__width;
    }
    input {
        &:hover, &:focus {
            background-color: $__white !important;
        }

    }
    @include respond-below(custom1399) {
        @include margin-padding(0, 20px);
    }
    @include respond-below(custom991) {
        min-width: $__full__width;
        width: $__full__width;
        @include margin-padding(0, 13px 15px);
    }
    @include respond-below(custom767) {
        @include margin-padding(0, 0px 15px 15px);
    }
}
.search-box-one .form-control:hover, .search-box-one .form-control:focus {
    background-color: $__white !important;
}
.search-input {
    width: 25%;
    float: $__left;
    display: $__table__cell;
    vertical-align: $__middle;
    position: $__relative;
    &.search-line {
        width: 35%;
        &::before {
            @include position($__absolute, 50%, 0, null, null);
            @include transform(translateY(-50%));
            width: 1px;
            height: 44px;
            background: $__platinum_color;
            content: "";
            z-index: 1;
            @include respond-below(custom767) {
                display: $__none;
            }
        }
        @include respond-below(custom767) {
            width: $__full__width;
        }
    }
    &.search-map-line {
        width: 26%;
        &::before {
            @include position($__absolute, 50%, 0, null, null);
            @include transform(translateY(-50%));
            width: 1px;
            height: 44px;
            background: $__platinum_color;
            content: "";
            z-index: 1;
            @include respond-below(custom767) {
                display: $__none;
            }
        }
        input {
            padding: 0px 36px 0px 36px;
            @include respond-below(custom767) {
                @include margin-padding(null, 0px 25px 0px 25px);
            }
        }
        & > i {
            left: 10px;
            @include respond-below(custom767) {
                left: 0;
            }
        }
        @include respond-below(custom767) {
            width: $__full__width;
        }
    }
    input {
        height: 40px;
        @include margin-padding(null, 0 10px 0 25px);
        border: 0;
        &::placeholder {
            color: $__yellowish_white;
        }
    }
    &.search-calendar-line {
        input {
            @include margin-padding(null, 0 36px 0 36px);
            @include respond-below(custom767) {
                @include margin-padding(null, 0px 25px 0px 25px);
            }
        }
        & > i {
            left: 10px;
            @include respond-below(custom767) {
                left: 0;
            }
        }
    }
    i {
        @include position($__absolute, 50%, $__inherit, null, null);
        @include transform(translateY(-50%));
        color: $__grey;
        font-size: $__font__size__18;
    }
    .current-loc-icon {
        @include position($__absolute, 50%, 12px, null, null);
        @include transform(translateY(-50%));
        margin-top: 0px;
        color: $__grey;
        font-size: $__font__size__18;
        line-height: 0;
        i {
            position: $__unset;
            transform: $__unset;
        }
    }
    & > i {
        &.bficon {
            right: $__inherit;
            left: 0;
            z-index: 1;
        }
    }
    @include respond-below(custom767) {
        width: $__full__width;
    }
}
.form-search-btn {
    float: $__right;
    padding-top: 4px;
    .btn {
        font-size: $__font__size__15;
        font-weight: $__medium;
        min-width: 97px;
        @include margin-padding(null, 7px 15px);
        border-radius: 10px;
        background-color:$__blue__14;
        border: 1px solid $__blue__14;
        color: $__white;
        box-shadow: inset 0 0 0 0 $__white;
        @include transition(0.5s);
        &:hover {
            color: $__blue__14;
            border-color: $__blue__14;
            background-color: $__white;
            box-shadow: inset 0 0 0 50px $__white;
            @include transition(0.5s);
        }
        @include respond-below(custom767) {
            width: 100%;
        }
    }
    @include respond-below(custom767) {
        width: 100%;
        float: $__left;
    }
}

.banner-img {
    position: $__relative;
    text-align: right;
    .banner-img1 {
        @include position($__absolute, 260px, null, null, -20px);
        -webkit-animation: float 2s ease-in-out infinite;
        animation: float 2s ease-in-out infinite;
    }
    .banner-img2 {
        @include position($__absolute, 200px, -40px, null, null);
        -webkit-animation: mover 3s infinite alternate;
        animation: mover 3s infinite alternate;
    }
    .banner-img3 {
        @include position($__absolute, 500px, -80px, null, null);
        -webkit-animation: mover 1s infinite alternate;
        animation: mover 1s infinite alternate;
    }
    @keyframes float {
        0% {
            @include transform(translateY(0px));
        }
        50% {
            @include transform(translateY(-15px));
        }
        100% {
            @include transform(translateY(0px));
        }
    }
    @-webkit-keyframes mover {
        0% { 
            @include transform(translateY(0px));
        }
        100% { 
            @include transform(translateY(-15px));
        }
    }
    @keyframes mover {
        0% { 
            @include transform(translateY(0px));
        }
        100% {
            @include transform(translateY(-15px));
        }
    }
    @include respond-below(custom991) {
        display: $__none;
    }
}

.specialities-section-one {
    background-color: $__white;
    @include margin-padding(0, 80px 0);
    @include respond-below(custom991) {
        @include margin-padding(0, 40px 0);
    }
    .specialities-slider-one {
        .item {
            margin-bottom: 50px;
            @include respond-below(custom767) {
                margin-bottom: 40px;
            }
        }
        .owl-stage-outer {
            margin-left: -10px;
        }
        .owl-item {
            img {
                width: $__auto;
            }
        }
    }
    .specialities-item {
        background: $__white;
        border: 1px solid $__white_tone;
        border-radius: 8px;
        height: 196px;
        @include margin-padding(0 0 0 10px, 30px);
        cursor: $__pointer;
        @include transition(0.7s);
        @extend %flex-align-center;
        @extend %flex-column;
        &:hover {
            background: $__white;
            box-shadow: 0px 10px 40px 10px #00000014;
            @include transition(0.7s);
            .specialities-img {
                span {
                    background: $__blue__14;
                    border: $__blue__14;
                    @include transition(0.7s);
                    img {
                        filter: invert(1) brightness(1000);
                        @include transform(rotateY(360deg));
                        @include transition(0.4s);
                    }
                }
            }
        }
        p {
            font-weight: $__semibold;
            font-size: $__font__size__18;
            color: $__black;
            margin-bottom: 0;
            @include respond-below(custom991) {
                font-size: $__font__size__16;
            }

        }
    }
    .specialities-img {
        padding-bottom: 18px;
        span {
            background: $__gray-50;
            border: 1px solid $__white_tone;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            @extend %flex-align-center;
            @include transition(0.7s);
            img {
                width: $__auto;
                @include transition(0.4s);
            }
        }
    }
    .specialities-btn {
        text-align: $__center;
        .btn {
            @extend %display-inline-flex;
            justify-content: center;
            -webkit-justify-content: center;
            border-radius: 6px;
            font-weight: $__semibold;
            font-size: $__font__size__15;
            min-width: 170px;
            padding: 10px 13px;
            background: $__blue__14;
            border: 1px solid $__blue__14;
            box-shadow: inset 0 0 0 0 $__white;
            color: $__white;
            @include transition(0.5s);
            &:hover {
                color: $__blue__14;
                background: $__white;
                border-color: $__blue__14;
                box-shadow: inset 0 0 0 50px $__white;
                @include transition(0.5s);
            }
            @include respond-below(custom767) {
                font-size: $__font__size__14;
                min-width: 190px;
            }
        }
    }
}

.section-header-one {
    margin-bottom: 40px;
    h2 {
        font-size: $__font__size__40;
        font-weight: $__semibold;
        color: $__midnight_black;
        margin-bottom: 0;
        @include respond-below(custom991) {
            font-size: $__font__size__32;
        }
        @include respond-below(custom767) {
            font-size: $__font__size__26;
        }
    }
    .section-title {
        display: $__inline__block;
        position: $__relative;
        &::before {
            content: '';
            background: url(../images/icons/header-icon.svg);
            @include background-properties($__cover, $__no_repeat, $__right);
            @include position($__absolute, -5px, -30px, null, null);
            width: 35px;
            height: 20px;
            @include respond-below(custom767) {
                display: $__none;
            }
        }
    }
    h5 {
        font-weight: $__semibold;
        font-size: $__font__size__16;
        color: $__blue__14;
        margin-bottom: 12px;
    }
    @include respond-below(custom991) {
        margin-bottom: 40px;
    }
}
.slide-nav-1 button.owl-next,
.slide-nav-1 button.owl-prev,
.slide-nav-2 button.owl-next,
.slide-nav-2 button.owl-prev {
    width: 44px;
    height: 44px;
    font-size: $__font__size__18;
    color: $__royal_blue;
    background: $__white;
    box-shadow: 0px 4px 14px rgba(141, 141, 141, 0.25);
    text-shadow: $__none;
    top: 0;
    cursor: $__pointer;
    border: 0;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 15px;
    border-radius: 50px;
    @extend %inlineflex-align-center;
    @include respond-below(custom767) {
         width: 36px;
        height: 36px;
    }
}
.slide-nav-1 button.owl-next:hover,
.slide-nav-1 button.owl-prev:hover,
.slide-nav-2 button.owl-next:hover,
.slide-nav-2 button.owl-prev:hover {
    color: $__white;
    background: $__blue__14;
}

.doctors-section {
    background: $__gray-50;
    @include margin-padding(0, 80px 0);
    position: $__relative;
    overflow: $__hidden;
    z-index: 1;
    &::after {
        content: '';
        background: url(../images/bg/doctor-shape-img1.png);
        @include background-properties($__cover, $__no_repeat, $__left);
        @include position($__absolute, 0, null, null, 0);
        width: 213px;
        height: 335px;
        opacity: 0.5;
        z-index: -1;
        @include respond-below(custom991) {
            display: $__none;
        }
    }
    &::before {
        content: '';
        background: url(../images/bg/doctor-shape-img2.png);
        @include background-properties($__cover, $__no_repeat, $__right);
        @include position($__absolute, null, 0, 0, null);
        opacity: 0.5;
        width: 213px;
        height: 335px;
        z-index: -1;
        @include respond-below(custom991) {
            display: $__none;
        }
    }
    @include respond-below(custom991) {
        @include margin-padding(0, 40px 0);
    }
}

.doctor-shape-img {
    position: $__relative;
    .doctor-shape-left {
        @include position($__absolute, 0, null, null, -35px);
    }
    .doctor-shape-right {
        @include position($__absolute, 230px, 0, null, null);
    }
    @include respond-below(custom991) {
        display: $__none;
    }
}
@-webkit-keyframes blinker {
    from {opacity: 1.0;}
    to {opacity: 0.0;}
}
.doctor-profile-widget {
    background: $__white;
    border: 1px solid $__gray-50;
    border-radius: 8px;
    position: $__relative;
    overflow: $__hidden;
}
.doc-pro-img {
    position: $__relative;
}
.doctor-profile-img {
    position: $__relative;
    overflow: $__hidden;
    border-radius: 8px 8px 0px 0px;
    img {
        position: $__relative;
        border-radius: 8px 8px 0px 0px;
        @include transform(translateZ(0));
        @include transition(all 2000ms cubic-bezier(.19,1,.22,1) 0ms);
        width: $__full__width;
    }
    &:hover {
        img {
            @include transition(scale(1.15));
        }
        &::before {
            -webkit-animation: circle 1s;
            animation: circle 1s;
        }
    }
    &::before {
        @include position($__absolute, 50%, null, null, 50%);
        z-index: 2;
        display: $__block;
        content: '';
        width: 0;
        height: 0;
        background: rgba(255,255,255,.2);
        border-radius: 100%;
        @include transform(translate(-50%, -50%));
        opacity: 0;
    }
}
@-webkit-keyframes circle {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        width: 200%;
        height: 200%;
        opacity: 0;
    }
}
@keyframes circle {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    100% {
        width: 200%;
        height: 200%;
        opacity: 0;
    }
}
.doctor-amount {
    @include position($__absolute, 20px, 20px, null, null);
    span {
        font-weight: $__semibold;
        font-size: $__font__size__14;
        color: $__midnight_black;
        padding: 6px 12px;
        background: $__white;
        border-radius: 8px;
    }
}
.doc-content {
    @include margin-padding(null, 20px);
    @include respond-below(custom767) {
        @include margin-padding(null, 15px);
    }
}
.doc-pro-info {
    @extend %flex-align-between;
    padding-bottom: 15px;
    .doc-pro-name {
        a {
            font-weight: $__semibold;
            font-size: $__font__size__20;
            color: $__midnight_black;
            &:hover {
                color: $__blue__14;
            }
            @include respond-below(custom1399) {
                font-size: $__font__size__18;
            }
        }
        p {
            color: $__black__01;
            font-size: $__font__size__14;
            margin-bottom: 0;
        }
    }
}

.reviews-ratings {
    p {
        @extend %flex__inlinecenter;
        color: $__black__01;
        font-size: $__font__size__10;
        font-weight: $__medium;
        margin-bottom: 18px;
    }
    span {
        @extend %inlineflex-align-center;
        @include margin-padding(0 4px 0 0, 2px 3px); 
        min-width: 46px;
        color: $__white;
        font-size: $__font__size__12;
        background: $__amber-500;
        border-radius: 6px;
        i {
            margin-right: 5px;
        }
    }
}
.doc-pro-location {
    p {
        font-size: $__font__size__14;
        color: $__black__01;
        @extend %flex__center;
        margin-bottom: 0;
        i {
            margin-right: 5px;
            color: $__midnight_black;
            font-size: $__font__size__16;
        }
    }
    .doc-badge {
        font-size: $__font__size__10;
        font-weight: $__medium;
        @extend %inlineflex-align-center;
        @include margin-padding(0, 4px 5px);
        &.badge-success {
            background: $__green__blue !important;
            color: $__teal;            
        }
        &.badge-danger {
            background: $__red-100 !important;
            color: $__red-500;            
        }
        i {
            font-size: 6px;
            margin-right: 5px;
        }
    }
}
.doctor-profile-widget {
    &.doc-item {
        .doc-pro-location {
            display: flex;
            display: -webkit-flex;
            align-items:center;
            -webkit-align-items:center;
            justify-content: space-between;
            -webkit-justify-content: space-between;
        }
        .doc-pro-info { 
            padding-bottom: 16px;
            border-bottom: 1px solid $__white_tone;
            margin-bottom: 16px;
        }
    }
}
.work-section {
    background: $__mist_white;
    @include margin-padding(0, 80px 0 0);
    position: $__relative;
    overflow: $__hidden;
    z-index: 1;
    &::after {
        content: '';
        background: url(../images/bg/doctor-shape-img1.png);
        @include background-properties($__cover, $__no_repeat, $__left);
        @include position($__absolute, 0, null, null, 0);
        width: 213px;
        height: 335px;
        opacity: 0.5;
        z-index: -1;
        @include respond-below(custom991) {
            display: $__none;
        }
    }
    &::before {
        content: '';
        background: url(../images/bg/doctor-shape-img2.png);
        @include background-properties($__cover, $__no_repeat, $__right);
        @include position($__absolute, null, 0, 0, null);
        opacity: 0.5;
        width: 213px;
        height: 335px;
        z-index: -1;
        @include respond-below(custom991) {
            display: $__none;
        }
    }
    @include respond-below(custom991) {
        @include margin-padding(0, 40px 0 20px);
    }
}
.work-img-info {
    position: $__relative;
    @include respond-below(custom991) {
        display: $__none;
    }
    .work-img {
        position: $__absolute;
        bottom: 0;
    }
}
.work-details {
    padding-left: 60px;
    @include respond-below(custom1199) {
        padding-left: 20px;
    }
}
.work-info {
    @extend %display-flex;
    -webkit-box-align: $__start;
    -ms-flex-align: $__start;
    align-items: $__start;
    margin-bottom: 35px;
    @include respond-below(custom991) {
        margin-bottom: 20px;
    }
    .work-icon {
        margin-right: 20px;
        span {
            min-width: 70px;
            height: 70px;
            background: $__work__blue;
            border-radius: 10px;
            @extend %flex-align-center;
            @include transition(0.7s);
            @include transform(rotate(0));
            img {
                @include transform(rotate(0));
                @include transition(0.7s);
            }
        }
    }
    &:hover {
        .work-icon {
            span {
                background: $__white;
                @include transition(0.7s);
                @include transform(rotate(90deg));
                img {
                    @include transform(rotate(-90deg));
                    @include transition(0.7s);
                }
            }
        }
    }
    .work-content {
        h5 {
            font-weight: $__semibold;
            font-size: $__font__size__18;
            color: $__midnight_black;
            margin-bottom: 14px;
            @include respond-below(custom767) {
                margin-bottom: 10px;
            }
        }
        p {
            font-size: $__font__size__16;
            margin-bottom: 0;
            @include respond-below(custom767) {
                font-size: $__font__size__14;
            }
        }
    }
}

.work-section {
    background: $__blue-50;
    @include margin-padding(0, 80px 0 0);
    position: $__relative;
    overflow: $__hidden;
    z-index: 1;
    &::after {
        content: '';
        background: url(../images/bg/doctor-shape-img1.png);
        @include background-properties($__cover, $__no_repeat, $__left);
        @include position($__absolute, 0, null, null, 0);
        width: 213px;
        height: 335px;
        opacity: 0.5;
        z-index: -1;
        @include respond-below(custom991) {
            display: $__none;
        }
    }
    &::before {
        content: '';
        background: url(../images/bg/doctor-shape-img2.png);
        @include background-properties($__cover, $__no_repeat, $__right);
        @include position($__absolute, null, 0, 0, null);
        opacity: 0.5;
        width: 213px;
        height: 335px;
        z-index: -1;
        @include respond-below(custom991) {
            display: $__none;
        }
    }
    @include respond-below(custom991) {
        @include margin-padding(0, 40px 0 20px);
    }
}
.work-img-info {
    position: $__relative;
    @include respond-below(custom991) {
        display: $__none;
    }
    .work-img {
        position: $__absolute;
        bottom: 0;
    }
}
.work-details {
    padding-left: 60px;
    padding-bottom: 45px;
    @include respond-below(custom1199) {
        padding-left: 20px;
    }
    @include respond-below(custom991) {
        padding-bottom: 0;
    }
}
.work-info {
    @extend %display-flex;
    -webkit-box-align: $__start;
    -ms-flex-align: $__start;
    align-items: $__start;
    margin-bottom: 35px;
    @include respond-below(custom991) {
        margin-bottom: 20px;
    }
    .work-icon {
        margin-right: 20px;
        span {
            min-width: 70px;
            height: 70px;
            background: $__blue__16;
            border-radius: 10px;
            @extend %flex-align-center;
            @include transition(0.7s);
            @include transform(rotate(0));
            img {
                @include transform(rotate(0));
                @include transition(0.7s);
            }
        }
    }
    &:hover {
        .work-icon {
            span {
                background: $__white;
                @include transition(0.7s);
                @include transform(rotate(90deg));
                img {
                    @include transform(rotate(-90deg));
                    @include transition(0.7s);
                }
            }
        }
    }
    .work-content {
        h5 {
            font-weight: $__semibold;
            font-size: $__font__size__18;
            color: $__midnight_black;
            margin-bottom: 14px;
            @include respond-below(custom767) {
                margin-bottom: 10px;
            }
        }
        p {
            font-size: $__font__size__16;
            margin-bottom: 0;
            color: $__black__01;
            @include respond-below(custom767) {
                font-size: $__font__size__14;
            }
        }
    }
}
.testimonial-details p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.articles-section {
    background-color: $__white;
    @include margin-padding(0, 80px 0 56px);
    @include respond-below(custom991) {
        @include margin-padding(0, 40px 0 16px);
    }
}
.articles-grid {
    border: 2px solid $__light_white;
    border-radius: 6px;
    padding: 24px;
    margin-bottom: 24px;
}
.articles-info {
    @extend %flex__center;
    .articles-left {
        margin-right: 24px;
        .articles-img {
            position: $__relative;
            overflow: $__hidden;
            border-radius: 8px;
            width: 205px;
            height: 180px;
            img {
                position: $__relative;
                border-radius: 8px;
                @include transition(all 2000ms cubic-bezier(.19,1,.22,1) 0ms);
                @include transform(translateZ(0));
                width: $__full__width;
            }
            &:hover {
                img {
                    @include transform(scale(1.15));
                }
                &::before {
                    -webkit-animation: circle 1s;
                    animation: circle 1s;
                }
            }
            &::before {
                @include position($__absolute, 50%, null, null, 50%);
                z-index: 2;
                display: $__block;
                content: '';
                width: 0;
                height: 0;
                background: rgba(255,255,255,.2);
                border-radius: 100%;
                @include transform(translate(-50%, -50%));
                opacity: 0;
            }
            @include respond-below(custom1199) {
                width: $__full__width;
                height: $__auto;
            }
        }
        @include respond-below(custom1199) {
            @include margin-padding(0 0 20px 0, 0);
        }
    }
    @include respond-below(custom1199) {
        display: $__block;
        text-align: $__center;
    }
}
.articles-content {
    .articles-list {
        margin-bottom: 10px;
        li {
            @extend %flex__inlinecenter;
            margin-right: 15px;
            color: $__black__01;
            font-size: $__font__size__12;
            &:last-child {
                margin-right: 0;
            }
            i {
                font-size: $__font__size__14;
                margin-right: 8px;
            }
        }
        @include respond-below(custom1199) {
            justify-content: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
        }
    }
    h4 {
        font-weight: $__semibold;
        font-size: $__font__size__16;
        margin-bottom: 5px;
        max-width: 320px;
        @include respond-below(custom1199) {
            margin: auto;
            margin-bottom: 10px;
        }
        @include respond-below(custom991) {
            max-width: $__inherit;
        }
        a {
            color: $__midnight_black;
            &:hover {
                color: $__blue__14;
            }
        }
    }
    p {
        color: $__black__01;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 16px;
    }
    .btn {
        font-weight: $__semibold;
        font-size: $__font__size__15;
        color: $__blue__14;
        background: $__white;
        border: 2px solid $__blue__14;
        box-shadow: inset 0 0 0 0 $__blue__14;
        border-radius: 6px;
        min-width: 103px;
        padding: 7px 10px;
        @include transition(0.5s);
        &:hover {
            color: $__white;
            border-color: $__blue__14;
            background: $__blue__14;
            box-shadow: inset 0 0 0 50px $__blue__14;
            @include transition(0.5s);
        }
        @include respond-below(custom767) {
            font-size: $__font__size__14;
        }
    }
}
.app-section {
    background-color: $__white;
    @include margin-padding(0, 20px 0 80px);
    @include respond-below(custom1199) {
        @include margin-padding(0, 0 0 80px);
    }
    @include respond-below(custom991) {
        @include margin-padding(0, 20px 0 40px);
    }
    @include respond-below(custom767) {
        @include margin-padding(0, 0px 0 40px);
    }
}
.app-bg {
    background: $__blue__14;
    background-image: url(../images/app-bg.png);
    background-position: right top;
    background-repeat: no-repeat;
    border-radius: 6px;
    padding: 40px 0 0 50px;
    @include respond-below(custom1199) {
        @include margin-padding(0, 30px);
    }
    @include respond-below(custom991) {
        @include margin-padding(0, 40px 0);
        background-image: $__inherit;
    }
    @include respond-below(custom767) {
        @include margin-padding(0, 20px);
    }
    .app-content {
        @include margin-padding(0, 40px 0 80px);
        @include respond-below(custom1199) {
            @include margin-padding(0, 0);
        }
    }
}
.app-content {  
    @include respond-below(custom991) {
        text-align: $__center;
    }
}
.app-header {
    padding-bottom: 40px;
    h5 {
        font-size: $__font__size__24;
        font-weight: $__semibold;
        color: $__white;
        margin-bottom: 10px;
        @include respond-below(custom991) {
            font-size: $__font__size__22;
        }
        @include respond-below(custom767) {
            font-size: $__font__size__18;
        }
    }
    h2 {
        font-size: $__font__size__40;
        font-weight: $__semibold;
        color: $__white;
        margin-bottom: 0;
        max-width: 565px;
        @include respond-below(custom991) {
            font-size: $__font__size__34;
            max-width: $__full__width;
        }
        @include respond-below(custom767) {
            font-size: $__font__size__24;
        }
    }
    @include respond-below(custom991) {
        padding-bottom: 30px;
    }
    @include respond-below(custom767) {
        padding-bottom: 20px;
    }
}
.app-scan {
    padding-bottom: 40px;
    p {
        font-size: $__font__size__14;
        font-weight: $__medium;
        color: $__white;
        margin-bottom: 10px;
    }
    @include respond-below(custom991) {
        padding-bottom: 30px;
    }
    @include respond-below(custom767) {
        padding-bottom: 20px;
    }
}
.google-imgs {
    a {
        margin-right: 24px;
        &:last-child {
            margin-right: 0;
        }
        img {
            @include respond-below(custom767) {
                width: 120px;
            }
        }
        @include respond-below(custom767) {
            margin-right: 10px;
        }
    }
}
.mobile-img {
    @include position($__relative, null, null, 0, null);
    @include respond-below(custom991) {
        display: $__none;
    }
    img {
        @include respond-below(custom1440) {
            max-width: 450px;
        }
        @include respond-below(custom1199) {
            max-width: 370px;
        }
    }
}
.faq-section {
    background-color: $__white;
    position: $__relative;
    overflow: $__hidden;
    @include margin-padding(0, 0 0 120px);
    @include respond-below(custom1199) {
        @include margin-padding(0, 0 0 80px);
    }
    @include respond-below(custom991) {
        @include margin-padding(0, 0 0 40px);
    }
}
.faq-img {
    position: $__relative;
    @include respond-below(custom991) {
        text-align: $__center;
        margin-bottom: 80px;
    }
    @include respond-below(custom767) {
        margin-bottom: 70px;
    }
}
.faq-patients-count {
    background: $__white;
    box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.03), 0px 64.81px 46.85px rgba(0, 0, 0, 0.0228), 0px 38.52px 25.48px rgba(0, 0, 0, 0.0182), 0px 20px 13px rgba(0, 0, 0, 0.015), 0px 8.15px 6.52px rgba(0, 0, 0, 0.0118), 0px 1.85px 3.15px rgba(0, 0, 0, 0.0072);
    border-radius: 10px;
    padding: 15px;
    min-width: 222px;
    @include position($__absolute, null, null, -40px, 50%);
    transform: translateX(-50%);
    @extend %flex-align-between;
    @include respond-below(custom991) {
        justify-content: start;
        -webkit-justify-content: start;
        -ms-flex-pack: start;
        box-shadow: $__none;
        border: 1px solid $__white_smoke;
    }
}
.faq-smile-img {
    margin-right: 15px;
}
.faq-patients-content {
    h4 {
        font-weight: $__bold;
        font-size: $__font__size__24;
        color: $__midnight_black;
        margin-bottom: 3px;
        @include respond-below(custom767) {
            font-size: $__font__size__20;
        }
    }
    p {
        font-weight: $__semibold;
        font-size: $__font__size__18;
        color: $__black__01;
        margin-bottom: 0;
        @include respond-below(custom767) {
            font-size: $__font__size__16;
        }
    }
    @include respond-below(custom991) {
        text-align: $__left;
    }
}
.faq-info {
    .accordion-item {
        background: $__athens_grey;
        border: 0;
        border-radius: 6px;
        @include margin-padding(0 0 24px, 15px);
        &:last-child {
            margin-bottom: 0;
        }
        .accordion-body {
            margin-top: 15px;
            padding: 15px 0 0;
            border-top: 1px solid $__honeydew_color;
        }
        @include respond-below(custom767) {
            margin-bottom: 20px;
        }
    }
    .accordion-header {
        position: $__relative;
        @include margin-padding(0, 0);
        border: 0;
    }
    .accordion-button {
        font-size: $__font__size__16;
        font-weight: $__semibold;
        color: $__midnight_black;
        background: $__transparent !important;
        @include margin-padding(0, 0);
        border: 0;
        border-radius: 0;
        box-shadow: $__none;
        position: $__relative;
        cursor: $__pointer;
        &:hover {
            border: 0;
        }
        &:after {
            content: "\e9b1";
            font-family: $__feather__font__family;
            font-size: $__font__size__20;
            background: $__none;
            color: $__blue__14;
            background: $__white;
            width: 32px;
            height: 32px;
            border-radius: 5px;
            @extend %inlineflex-align-center;
        }
        &:not(.collapsed)::after {
            content: "\e996";
            color: $__white;
            background: $__blue__14;
        }
        @include respond-below(custom767) {
            font-size: $__font__size__14;
            padding-right: 30px;
            line-height: 24px;
        }
    }
    .accordion-content {
        p {
            color: $__black__01;
            margin-bottom: 0;
        }
    }
}
.partners-section {
    background-color: $__white;
    @include margin-padding(null, 80px 0);
    @include respond-below(custom991) {
        @include margin-padding(null, 40px 0);
    }
    .owl-nav {
        display: $__none;
    }
}
.partners-slider {
    list-style: $__none;
    li {
        a {
            @extend %flex-align-center;
            background: $__white;
            border: 2px solid $__honeydew_color;
            @include margin-padding(null, 14px 20px);
            text-align: $__center;
            height: 79px;
            border-radius: 10px;
        }
    }
}
.partners-slider.owl-carousel {
    .owl-item {
        img {
            @include respond-below(custom767) {
                width: $__auto;
                margin: 0 $__auto;
                height: $__auto;
            }
        }
    }
}
.footer-1.footer {
    background-color: #F2F6F6;
    .footer-top{
        .footer-title {
            color: #1F2937;
        }
        .footer-widget.footer-menu {
            ul{
                li{
                    a{
                        color: #6B7280;
                        position: relative;
                        overflow: hidden;
                        background: url(../images/footer-arrow.png) no-repeat -20px 20px;
                        -webkit-transition: all 0.5s ease-in-out;
                        -ms-transition: all 0.5s ease-in-out;
                        transition: all 0.5s ease-in-out;
                    }
                }
            }
        }
        .footer-widget{
            .footer-about-content{
                p{
                    color: #6B7280;
                }
            } 
            .footer-contact-info {
                color: #6B7280;
            }
        }
    } 
    .subscribe-form {
        position: relative;
        .form-control {
            font-weight: 500;
            font-size: 14px;
            min-height: 46px;
            color: #6B7280;
            background: #fff;
            border: 1px solid #E5E7EB;
            border-radius: 6px 0px 0px 6px;
            margin: 0;
            padding: 6px 112px 6px 15px;
        }
        .btn {
            font-weight: normal;
            margin: 0;
            padding: 11px 16px;
            font-size: 15px;
            font-weight: 600;
            border-radius: 0 6px 6px 0;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }
}
.owl-carousel {
	&.partners-slider {
		&.owl-loaded {
			padding-left: 0;
		}
	}
}
.doc-plan {
    .pricing-header {
        padding-bottom: 18px;
        .pricing-header-info {
            padding-bottom: 0;
        }
    }
    .pricing-amount {
        h2 {
            margin-bottom: 24px;
        }        
    }
    .pricing-list {
        padding-bottom: 30px;
        ul li {
            margin-bottom: 14px;
        }
    }
}
.service-sec-one {
    padding-bottom: 56px;
    @include respond-below(custom991) {
        padding-bottom: 36px;
    }
}
.serv-wrap {
    border: 1px solid $__white_tone;
    box-shadow: 0px 7.5px 17.5px 0px #0000000D;
    @include margin-padding(0 0 24px, 24px);
    @include rounded(6px);
    display: block;
    text-align: center;
    &:hover {
        span {
            img {
                @include transform(rotateY(360deg));
                @include transition(0.6s);
            }
        }
    }
    &.blue-bg {
        span {
            background: $__blue-01;            
        }
        &:hover {
            background: $__blue-01;            
        }
    }
    &.green-bg {
        span {
            background: $__green-01;            
        }
        &:hover {
            background: $__green-01;            
        }
    }
    &.info-bg {
        span {
            background: $__info-01;            
        }
        &:hover {
            background: $__info-01;            
        }
    }
    &.red-bg {
        span {
            background: $__red-01;            
        }
        &:hover {
            background: $__red-01;            
        }
    }
    &.success-bg {
        span {
            background: $__success-01;            
        }
        &:hover {
            background: $__success-01;            
        }
    }
    &.pink-bg {
        span {
            background: $__pink-01;            
        }
        &:hover {
            background: $__pink-01;            
        }
    }
    &.danger-bg {
        span {
            background: $__danger-01;            
        }
        &:hover {
            background: $__danger-01;            
        }
    }
    span {
        width: 70px;
        height: 70px;
        @include rounded(50%);
        @extend %flex-align-center;
        margin: 0 auto 15px;
    }
    h4 {
        font-weight: $__medium;
        margin-bottom: 0;
        @include respond-below(custom1399) {
            font-size: $__font__size__16;
        }
    }
}
.home-one {
    .faq-info {
        .accordion-item {
            box-shadow: 0px 7.5px 17.5px 0px #0000000D;
            @include margin-padding(0 0 24px, 0);
            .accordion-body {
                margin-top: 0;
                padding: 0 15px 15px;
                border-top: 0;
            }
        }
        .accordion-button {
            @include margin-padding(0, 15px);
            &:not(.collapsed) {
                background: $__white;
            }
        }
        .accordion-collapse {
            &.show {
                background: $__white;
            }
        }
    }
}