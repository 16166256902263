.doc-review {
	&.review-listing {
		@include margin-padding(0, null);
	}
}
.doc-review {
	& > ul {
		& > li {
			background-color: $__white;
			border: 1px solid $__honeydew_color;
			border-radius: 10px;
			box-shadow: 0px 7.5px 17.5px 0px #0000000D;
			padding: 0;
			margin-bottom: 24px;
			.review-content {
				padding: 24px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.daterange-wraper {
					margin-bottom: 0;
				}
			}
			.star-rated {
				display: flex;
				align-items: center;
				span {
					color: $__black__shade;
					font-size: $__font__size__30;
					margin-right: 10px;
					font-weight: $__semibold;
				}
				i {
					color: $__white__shade;
					margin: 0 2px;
					&.filled {
						color: $__puregold;
					}
				}
			}
			ul {
				li {
					padding: 0 24px 24px;
					.replied-comment {
						padding: 15px;
						background: $__sky__04;
						border-radius: 10px;
						.patient-info {
							span {
								display: block;
								color: $__black__ash;
								font-size: $__font__size__14;
							}
						}
						.review-info {
							margin-top: 10px;
						}
					}
					.comment-reply {
						display: flex;
						align-items: center;
						.reply-text {
							color: $__blue__14;
							font-size: $__font__size__14;
							margin-right: 15px;
							font-weight: $__medium;
							&.replied-text {
								color: #1E293B;
							}
						}
						span {
							font-size: $__font__size__14;
							font-weight: $__medium;
							&.no-views {
								color: $__grey_white__01;
							}
							&.replied-views {
								color: $__blue__14;
							}
						}
					}
				}
			}
		}
		
	}
}
.comments {
	width: 100%;
	.comment-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px;
		border-bottom: 1px solid #E2E8F0;
		.patinet-information {
			.patient-info {
				span {
					display: block;
					color: $__black__ash;
				}
			}
		}
	}
	.review-info {
		padding: 24px;
		p {
			color: $__black__shade;
			margin-bottom: 15px;
		}
	}
	.comment-reply {
		display: flex;
		align-items: center;
		.reply-text {
			color: $__blue__14;
			font-size: $__font__size__14;
			margin-right: 15px;
			font-weight: $__medium;
			&:hover {
				color: #0E82FD;
			}
			&.replied-text {
				color: #1E293B;
			}
			i {
				line-height: inherit;
			}
		}
		span {
			font-size: $__font__size__14;
			font-weight: $__medium;
			&.no-views {
				color: $__grey_white__01;
			}
			&.replied-views {
				color: $__blue__14;
			}
		}
	}
}
.durations {
	margin-bottom: 5px;
}