.section-search-medication {
    background: $__platinum url(../images/search-bg.png) no-repeat bottom center;
    min-height: 730px;
    background-size: $__full__width auto;
    position: $__relative;
    background-blend-mode: Darken;
    @include margin-padding(null, 140px 0 120px 0);
    @include respond-below(custom991) {
        min-height: 330px;
    }
    @include respond-below(custom767) {
        background: $__platinum;
        @include margin-padding(null, 100px 0 50px 0);
    }
    @include respond-below(custom479) {
        min-height: 410px;
        @include margin-padding(null, 100px 15px 30px 15px);
    }
    .banner-wrapper {
        max-width: 800px;
        width: $__full__width;
        @include margin-padding(0 auto, null);
        @include respond-below(custom991) {
            max-width: 720px;
        }
        .banner-header {
            @include margin-padding(0 0 30px, null);
            text-align: center;
            h1 {
                font-size: $__font__size__40;
                font-weight: $__semibold;
                @include margin-padding(0 0 10px, null);
                @include respond-below(custom991) {
                    font-size: $__font__size__34;
                }
                @include respond-below(custom767) {
                    font-size: $__font__size__32;
                }
                @include respond-below(custom575) {
                    font-size: $__font__size__28;
                }
            }
            p {
                color: $__smokey-gray;
                font-size: $__font__size__20;
                @include margin-padding(null null 0, null);
                @include respond-below(custom767) {
                    font-size: $__font__size__16;
                }
            }
        }
        .search-box {
            @include respond-below(custom767) {
                max-width: 535px;
                @include margin-padding(0 auto, null);
            }
            form {
                @extend %display-flex;
                flex-direction: column;
                @include respond-below(custom767) {
                    -ms-flex-direction: column;
                    flex-direction: column;
                    word-wrap: break-word;
                    background-clip: border-box;
                }
                @include respond-below(custom575) {
                    display: $__block;
                }
            }
            .form-group {
                @extend %display-flex;
                justify-content: space-between;
                margin-bottom: 0;
                @include respond-below(custom767) {
                    flex-direction: column;
                }
            }
            .form-control {
                border: 1px solid $__goose-gray;
                @include rounded(5px);
                @include box-shadow(inset, 0, 0px, 0px, null, rgba(0,0,0,.075));
                &::placeholder {
                    font-size: $__font__size__14;
                }
            }
            .search-medication-name {
                -ms-flex: 0 0 390px;
                flex: 0 0 390px;
                position: $__relative;
                width: 390px;
                @include respond-below(custom991) {
                    -ms-flex: 0 0 350px;
                    flex: 0 0 350px;
                    width: 350px;
                }
                @include respond-below(custom767) {
                    -ms-flex: 0 0 $__full__width;
                    flex: 0 0 $__full__width;
                    width: $__full__width;
                }
                @include respond-below(custom575) {
                    width: $__full__width !important;
                    -ms-flex: $__none;
                    flex: $__none;
                }
                .form-control {
                    padding-left: 10px;
                    //background: $__white url(../images/search.png) no-repeat 10px $__center;
                }
            }
            .search-medication-form {
                -ms-flex: 0 0 390px;
                flex: 0 0 390px;
                position: $__relative;
                width: 390px;
                @include respond-below(custom991) {
                    -ms-flex: 0 0 350px;
                    flex: 0 0 350px;
                    width: 350px;
                }
                @include respond-below(custom767) {
                    -ms-flex: 0 0 $__full__width;
                    flex: 0 0 $__full__width;
                    width: $__full__width;
                }
                @include respond-below(custom575) {
                    width: $__full__width !important;
                    -ms-flex: $__none;
                    flex: $__none;
                }
                .form-control {
                    padding-left: 10px;
                    //background: $__white url(../images/search.png) no-repeat 10px $__center;
                }
            }
            .search-medication-strength {
                -ms-flex: 0 0 390px;
                flex: 0 0 390px;
                position: $__relative;
                width: 390px;
                @include respond-below(custom991) {
                    -ms-flex: 0 0 350px;
                    flex: 0 0 350px;
                    width: 350px;
                }
                @include respond-below(custom767) {
                    -ms-flex: 0 0 $__full__width;
                    flex: 0 0 $__full__width;
                    width: $__full__width;
                }
                @include respond-below(custom575) {
                    width: $__full__width !important;
                    -ms-flex: $__none;
                    flex: $__none;
                }
                .form-control {
                    padding-left: 10px;
                    //background: $__white url(../images/search.png) no-repeat 10px $__center;
                }
            }
            .search-btn {
                font-size: 15px;
                width: 150px;
                //-ms-flex: 0 0 150px;
                //flex: 0 0 150px;
                height: 46px;
                align-self: flex-end;
                @include respond-below(custom767) {
                    -ms-flex: 0 0 $__full__width;
                    flex: 0 0 $__full__width;
                    min-height: 46px;
                    width: $__full__width;
                }
                span {
                    display: $__inline__block;
                    font-weight: $__medium;
                    @include respond-below(custom767) {
                        margin-left: 5px;
                        text-transform: $__uppercase;
                    }
                }
            }
            .form-text {
                color: $__smokey-gray;
                font-size: $__font__size__13;
                display: inline-block;
            }
            @include respond-below(custom767) {
                max-width: 535px;
                @include margin-padding(0 auto, null);
            }
            .search-result {
                margin-top: 30px;
                h3 {
                    color: $__smokey-gray;
                }
                .pharmacies-count {
                    font-size: $__font__size__22;
                    color: $__successcolor;
                }
                .search-result-box {
                    flex-direction: column;
                    @extend %display-flex;
                    padding: 10px;
                    margin-top: 10px;
                    align-items: center;
                    border: solid 1px $__light-gray;
                    border-radius: 5px;
                    background-color: rgba(16, 222, 253, 0.1);
                    gap: 10px;
                    justify-content: space-between;
                    h3 {
                        font-size: $__font__size__22;
                    }
                }
                .search-success-icon {
                    i {
                        font-size: $__font__size__40;
                        color: $__misty__green;
                    }
                }
                .search-failure-icon {
                    padding-bottom: 18px;
                    margin-right: 18px;
                    i {
                        font-size: $__font__size__40;
                        color: $__dove_grey;
                    }
                }
                .transfer-btn {
                    font-size: 15px;
                    width: 250px;
                    height: 46px;
                    @include respond-below(custom767) {
                        -ms-flex: 0 0 $__full__width;
                        flex: 0 0 $__full__width;
                        min-height: 46px;
                        width: $__full__width;
                    }
                    span {
                        color: white;
                        display: $__inline__block;
                        font-weight: $__medium;
                        @include respond-below(custom767) {
                            margin-left: 5px;
                            text-transform: $__uppercase;
                        }
                    }
                }
            }
        }
    }
}
