.account-card {
	border-radius: 10px;
    font-size: $__font__size__16;
    text-align: $__center;
    @include margin-padding(0 0 30px, 10px);
    span {
        font-size: $__font__size__24;
        display: $__block;
        margin-bottom: 5px;
        font-weight: $__medium;
    }
}
.info-list {
	margin-bottom: 10px;
    .title {
        color: $__dark__blue;
        font-weight: $__medium;
    }
    .text {
        color: $__smokey-gray;
        display: $__block;
        overflow: $__hidden;
    }
}
.account-details-box {
    background: $__black__shade;
    padding: 24px;
    border-radius: 10px;
    margin-bottom: 24px;
    .account-payment-info {
        h4 {
            color: $__white;
            font-size: $__font__size__20;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #334155;
        }
        .payment-amount {
            margin-bottom: 24px;
            h6 {
                color: $__white; 
                display: inline-flex;
                align-items: center;
                i {
                    margin-right: 8px;
                    &.text-orange {
                        color: #F97316;
                    }
                    &.text-pink {
                        color: #DB2777;
                    }
                }
            }
            span {
                color: $__white;
                font-size: $__font__size__24;
                font-weight: $__medium;
                display: block;
            }
        }
        .payment-request {
            span {
                display: block;
                font-size: $__font__size__14;
                color: #64748B;
                margin-bottom: 24px;
            }
        }
    }
}
.bank-details-info {
    @include respond-below(custom991) {
        margin-top: 24px;
    }
    h3 {
        font-size: $__font__size__20;
        margin-bottom: 30px;
        color: $__white;
    }
    ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li {
            margin-right: 15px;
            margin-bottom: 20px;
            &:last-child {
                margin-right: 0;
            }
            h5 {
                color: $__white;
                font-size: $__font__size__16;
                font-weight: $__normal;
                margin-bottom: 0;
            }
            h6 {
                color: $__white;
                font-size: $__font__size__14;
                margin-bottom: 5px;
            }
        }
    }
    .edit-detail-link {
        display: flex;
        align-items: center;
        margin-top: 20px;
        a {
            color: $__blue__14;
            font-size: $__font__size__14;
            margin-right: 8px;
            display: inline-block;
        }
    }
}
.accounts-tab {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #E2E8F0;
    .nav-tabs-bottom {
        .nav-link {
            background: $__white__shade;
            border-radius: 10px;
            padding: 5px 20px;
            color: $__black__ash;
            margin-right: 15px;
            &.active {
                background: $__blue__14;
                color: $__white;
            }
            &:hover {
                background: $__blue__14;
                color: $__white;
            }
        }
    }
}
.custom-new-table {
    border: 1px solid $__honeydew_color;
    border-radius: 10px;
    .table-responsive {
        border-radius: 10px;
        .table {
            thead {
                tr {
                    th {
                        border: 0;
                        background: $__honeydew_color;
                        padding: 12px;
                        font-weight: $__semibold;
                        white-space: nowrap;
                    }
                }
            }
            tbody {
                tr {
                    border: 0;
                    td {
                        border-bottom: 1px solid $__honeydew_color;
                        padding: 12px;
                        font-size: $__font__size__14;
                        white-space: nowrap;
                        span {
                            &.text-blue {
                                color: $__blue__14;
                                &:hover {
                                    color: $__black__05;
                                }
                            }
                            &.badge {
                                padding: 5px 10px;
                                border-radius: 50px;
                                font-weight: $__medium;
                                display: inline-block;
                                &.badge-warning-bg {
                                    background: $__yellow;
                                }
                                &.badge-success-bg {
                                    background: $__teal;
                                }
                                &.badge-danger-bg {
                                    background: #EF444E;
                                }
                            }
                           
                        }
                        .table-avtatar {
                            a {
                                display: inline-flex;
                                align-items: center;
                                img {
                                    width: 24px;
                                    height: 24px;
                                    border-radius: 5px;
                                    margin-right: 12px;

                                }
                            }
                        }
                        .account-action {
                            border: 1px solid #E2E8F0;
                            border-radius: 50%;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            width: 30px;
                            height: 30px;
                            background: $__white;
                            &:hover {
                                background: $__blue__14;
                                color: $__white;
                            }
                        }
                    }
                    &:last-child {
                        td {
                            border: 0;
                        }
                    }
                }
            }
        }
    }
}
.input-block-new {
    margin-bottom: 24px;
}
.modal-header {
    h5 {
        display: inline-flex;
        align-items: center;
        .request-id {
            font-size: $__font__size__16;
            font-weight: $__medium;
            margin-left: 10px;
        }
        .badge {
            padding: 5px 10px;
            border-radius: 50px;
            font-weight: $__medium;
            display: inline-block;
            margin-left: 10px;
            font-size: $__font__size__12;
            &.badge-success-bg {
                background: $__teal;
            }
            &.badge-danger-bg {
                background: #EF444E;
            }
        }
    }
}
.other-accounts-info {
    >ul {
        >li {
            border: 1px solid $__honeydew_color;
            padding: 15px 15px 0;
            border-radius: 10px;
            margin-bottom: 15px;
            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                @include respond-below(custom991) {
                    flex-direction: column;
                    align-items: start;
                }
                li {
                    margin-bottom: 15px;
                    &:first-child  {
                        flex-basis: 16%;
                    }
                    &:nth-child(2) {
                        flex-basis: 27%;
                    }
                    &:nth-child(3) {
                        flex-basis: 12%;
                    }
                    &:nth-child(4) {
                        flex-basis: 25%;
                    }
                    &:last-child {
                        flex-basis: 20%;
                        text-align: right;
                    }
                    @include respond-below(custom991) {
                        flex-basis: auto !important;
                    }
                    h6 {
                        margin-bottom: 5px;
                    }
                    span {
                        color: $__black__shade;
                    }
                    a {
                        color: $__blue__14;
                        font-weight: $__medium;
                    }
                }
            }
        }
    }
}
.completed-request {
    ul {
        border: 1px solid $__honeydew_color;
        padding: 15px 15px 0;
        border-radius: 10px;
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        li {
            margin-bottom: 15px;
            h6 {
                margin-bottom: 5px;
            }
            span {
                color: $__black__shade;
                &.text-blue {
                    color: $__blue__14;
                }
            }
        }
    }
    .bank-detail {
        padding: 24px 24px 9px;
        background: $__sky__04;
        border-radius: 10px;
        margin-bottom: 24px;
        h4 {
            margin-bottom: 15px;
        }
        .accont-information {
            margin-bottom: 15px;
            h6 {
                margin-bottom: 5px;
            }
            span {
                color: $__black__shade;
            }
        }
    }
    .request-des {
        margin-bottom: 24px;
        h4 {
            margin-bottom: 15px; 
        }
        p {
            color: $__black__shade;
            margin-bottom: 0;
        }
    }
}
.canceled-user-info {
    justify-content: space-between;
    margin-bottom: 24px;
    @include respond-below(custom575) {
        display: block !important;
    }
    .email-info {
        ul {
            @include respond-below(custom575) {
                margin-top: 15px;
            }
            li {
                color: $__black__05;
                display: flex;
                align-items: center;
                i {
                    margin-right: 5px;
                }
                & +li {
                    margin-top: 5px;
                }
            }
        }
    }
}
.cancellation-fees {
    padding: 24px 24px 9px;
    background: $__sky__04;
    border-radius: 10px;
    margin-bottom: 24px;
    .cancellation-info {
        display: flex;
        align-items: center;
        @include respond-below(custom575) {
            display: block;
        }
        .appointment-type {
            margin-right: 24px;
            margin-bottom: 15px;
        }
    }
}
.cancel-reason {
    border: 1px solid $__honeydew_color;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 24px;
    h5 {
        font-size: $__font__size__15;
        margin-bottom: 10px;
    }
    p {
        color: $__black__05;
        margin-bottom: 10px;
    }
}
span {
    &.refund {
        color: $__blue__14;
        font-weight: $__medium;
    }
}
.card {
    &.pass-card {
        box-shadow: 0px 7.5px 17.5px 0px #0000000D;
        border: 1px solid $__white;
    }
}
.refund-status {

    span {
        display: block;
        & +span {
            margin-top: 10px;
        }
    }
}