.custom-card {	
	border: 1px solid $__honeydew_color;
	box-shadow: 0px 7.5px 17.5px 0px #0000000D;
	@include rounded(10px);
	.card-header {
		border-color: $__honeydew_color;
		@include margin-padding(0 0 24px, 0);		
		h3 {
			font-weight: $__semibold;
			margin-bottom: 24px;
		}
	}
	.card-body {
		padding: 24px;
		.modal-btn {
			margin-top: 40px;
		}
	}
}
.timing-content {
	.col-form-label {
		color: $__black__shade;
	}
	.form-control {
		min-height: 40px;
		box-shadow: 0px 1px 2px 0px #0000000D;
		padding: 7px 15px;
		border-color: $__honeydew_color;
	}
}
.slot-box {
	border: 2px solid $__honeydew_color;
	@include margin-padding(0 0 30px, 15px 15px 0);
	border-radius: 8px;
	.slot-header {
		display: flex;
		display: -webkit-flex;
		align-items:center;
		-webkit-align-items:center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		border-bottom: 1px solid $__honeydew_color;
		margin-bottom: 15px;
		h5 {
			font-size: $__font__size__18;
			font-weight: $__semibold;
			color: $__black__shade;
			margin-bottom: 15px;
			@include respond-below(custom991) {
				font-size: $__font__size__16;
			}
		}
		ul {
			li {
				font-size: $__font__size__14;
				font-weight: $__medium;
				margin: 0 15px 15px 0;
				display: inline-block;
				&:last-child {
					margin-right: 0;
				}
				a {
					font-size: $__font__size__14;
					font-weight: $__medium;
				}
				.add-slot {
					color: $__blue__14;
					&:hover {
						color: darken($__blue__14, 5%);
					}
				}
				.del-slot {
					color: $__cart__red;
					&:hover {
						color: darken($__cart__red, 5%);
					}
				}				
				@include respond-below(custom767) {
					margin-right: 8px;
				}
			}
		}
	}
	.slot-body {
		p {
			font-size: $__font__size__16;
			color: $__black__ash;
			margin-bottom: 15px;
			@include respond-below(custom767) {
				font-size: $__font__size__15;
			}
		}
		.time-slots {
			li {
				background: $__white__shade;
				@include margin-padding(0 15px 15px 0, 12px 13px);
				border-radius: 6px;
				display: inline-block;
				font-size: $__font__size__15;
				font-weight: $__semibold;
				color: $__black__ash;
				min-width: 120px;
    			text-align: center;
    			position: relative;
				transition: 0.5s;
    			&:hover {
    				&::before {
    					content: "Space : 2";
    					background: $__black__shade;
						color: $__white;
						width: 100%;
						height: 100%;
						position: absolute;
    					left: 0;
    					top: 0;
    					border-radius: 6px;
    					@include margin-padding(0 15px 15px 0, 12px 13px);
    					transition: 0.5s;
    				}
    			}
				i {
					margin-right: 10px;
				}
				&.slot-space {
					background: $__black__shade;
					color: $__white;
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}
.available-tab {
	margin-bottom: 15px;
	.form-label {
		color: $__black__shade;
		font-size: $__font__size__14;
		font-weight: $__medium;
		margin-bottom: 10px;
	}
	ul {
		li {
			@include margin-padding(0 15px 15px 0, null);
			a {
				border: 1px solid $__honeydew_color;
				background: $__light-white-grey;
				color: $__black__ash;
				font-size: $__font__size__14;
				font-weight: $__medium;
				display: inline-block;
				text-align: center;
				@include margin-padding(null, 10px 22px);
				border-radius: 10px;
				@include respond-below(custom767) {
					@include margin-padding(null, 9px 17px);
				}
				@include respond-below(custom575) {
					display: block;
				}
				&.active, &:hover {
					background: $__blue__14;
					border-color: $__blue__14;
					color: $__white;
				}
			}
			@include respond-below(custom575) {
				margin-right: 0;
			}
		}
		@include respond-below(custom575) {
			display: block;
		}
	}
}
.modal-btn {
	.btn {
		font-size: $__font__size__15;
		font-weight: $__semibold;
		@include margin-padding(null, 7px 10px);
		& + .btn {
			margin-left: 15px;
		}
	}
}
.timing-modal {
	.form-wrap {
		margin-bottom: 15px;
		.custom-control-inline {
			font-weight: $__medium;
			margin: 0 10px 5px 0;
			color: $__black__ash;
			font-size: $__font__size__16;
			display: inline-block;
			@include respond-below(custom991) {
				font-size: $__font__size__15;
			}
		}
	}
}
.clinic-wrap {
	border: 1px solid $__honeydew_color;
	border-radius: 10px;
	@include margin-padding(0 0 24px, 24px);
	h5 {
		font-size: $__font__size__18;
		color: $__black__shade;
		font-weight: $__semibold;
		margin-bottom: 15px;
		@include respond-below(custom767) {
			font-size: $__font__size__16;
		}
	}
	.select2-container {
		width: 100% !important;
		.select2-selection--single {
			border-color: $__athens_grey;
			border-radius: 10px;
			box-shadow: 0px 1px 2px 0px #0000000D;
			height: 40px;
			.select2-selection__rendered {
				line-height: 36px;
				padding-left: 5px;
				font-size: $__font__size__14;
				color: $__black__shade;
				.clinic-img {
					border-radius: 8px;
				}
			}
			.select2-selection__arrow {
				height: 40px;
			}
		}
	}
}
.select2-results__option {
    padding: 6px 10px;
}
.select2-results__option .clinic-img {
	border-radius: 8px;
}
.form-wrap {
	margin-bottom: 15px;
}
.custom-modals {
	.modal-content {
		@include margin-padding(null, 24px);
		.modal-header {
			@include margin-padding(null, 0 0 15px);
			.modal-title {
				font-size: $__font__size__24;
				font-weight: $__semibold;
				color: $__black__shade;
				@include respond-below(custom1399) {
					font-size: $__font__size__22;
				}
				@include respond-below(custom991) {
					font-size: $__font__size__20;
				}
				@include respond-below(custom767) {
					font-size: $__font__size__18;
				}
			}
			.btn-close {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				background: $__honeydew_color;
				color: $__white;
				box-shadow: none;
				font-size: $__font__size__14;
				display: flex;
				display: -webkit-flex;
				align-items:center;
				-webkit-align-items:center;
				justify-content: center;
				-webkit-justify-content: center;
				padding: 0;
				&:focus {
					box-shadow: none;
				}
				&:hover {
					background: $__cart__red;
					color: $__white;
				}
			}
		}
		.col-form-label {
			color: $__black__shade;
		}
		.modal-body {
			@include margin-padding(null, 15px 0 15px);
		}
		.modal-footer {
			@include margin-padding(null, 15px 0 0);
		}
	}
}
.custom-table {
	border: 1px solid $__honeydew_color;
	border-radius: 10px;
	.table {
	    	& > thead {
	    		& > tr {
	    			& > th {
	    				border-top: 0;
	    				background: $__honeydew_color;
	    				color: $__black__shade;
	    				font-size: $__font__size__14;
	    				font-weight: $__semibold;
	    				&:first-child {
		    				border-top-left-radius: 10px;
		    			}
		    			&:last-child {
		    				border-top-right-radius: 10px;
		    			}
	    			}
	    		}
	    	}
	    	tr {
	    		th, td {
	    			border-top: 1px solid $__honeydew_color;
				    white-space: $__nowrap;
				    @include margin-padding(null, 1rem 0.75rem);
				    color: $__black__shade;
				    font-size: $__font__size__14;
				    h2 {
				    	a {
				    		color: $__black__ash;
				    		&:hover {
				    			color: $__blue__14;
				    		}
				    	}
				    }
	    			&:first-child {
	    				padding-left: 1.5rem;
	    			}
	    			&:last-child {
	    				padding-right: 1.5rem;
	    			}
	    		}
	    	}
	    	tbody {
	    		tr {
	    			&:last-child {
	    				td {
	    					&:first-child {
		    					border-bottom-left-radius: 10px;
		    				}
	    					&:last-child {
		    					border-bottom-right-radius: 10px;
		    				}
	    				}
		    		}
	    		}
	    	}
	    }
}
.action-item {
	display: flex;
	display: -webkit-flex;
	align-items:center;
	-webkit-align-items:center;
	a {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		border: 1px solid $__honeydew_color;
		color: $__black__ash;
		display: flex;
		display: -webkit-flex;
		align-items:center;
		-webkit-align-items:center;
		justify-content: center;
		-webkit-justify-content: center;
		margin-right: 10px;
		font-size: $__font__size__14;
		&:last-child {
			margin-right: 0;
		}
		&:hover {
			background:$__blue__14;
			color: $__white;
		}
	}
}
.badge-yellow {
	background: $__yellow !important;
}
.badge-green {
	background: $__teal !important;
}
.status-badge {
	font-size: $__font__size__12;
	font-weight: $__medium;
	@include margin-padding(null, 5px 10px);
	border-radius: 60px;
}
.search-field {
	position: relative;
	.form-control {
		height: 36px;
		min-height: 36px;
		border: 1px solid #E2E8F0;
		border-radius: 10px;
	}
	.search-icon {
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		color: $__slate-500;
	}
}
.search-header {
	display: flex;
	display: -webkit-flex;
	align-items:center;
	-webkit-align-items:center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 9px;
	.search-field {
		margin-bottom: 15px;
		.form-control { 
			min-width: 240px;
		}
	}
	.btn {
		font-size: $__font__size__15;
		font-weight: $__semibold;
		margin-bottom: 15px;
		padding: 7px 10px;
	}
}
.patient-wrap {
	box-shadow: 0px 7.5px 17.5px 0px #0000000D;
	@include margin-padding(0 0 24px, 24px 24px 9px);
	display: flex;
	display: -webkit-flex;
	align-items:center;
	-webkit-align-items:center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	flex-wrap: wrap;
	.patient-info {
		display: flex;
		display: -webkit-flex;
		align-items:center;
		-webkit-align-items:center;
		margin-bottom: 10px;
		img {
			width: 64px;
			height: 64px;
			border-radius: 10px;
			margin-right: 10px;
		}
		.user-patient {
			h6 {
				font-size: $__font__size__12;
				font-weight: $__medium;
				color: $__blue__17;
				margin-bottom: 5px;
			}
			h5 {
				font-weight: $__semibold;
				margin-bottom: 5px;
				color: $__black__shade;
			}
			ul {
				li {
					color: $__black__ash;
					font-size: $__font__size__12;
					@include margin-padding(0 10px 0 0, 0 10px 0 0);
					position: relative;
					display: inline-block;
					&::before {
						content: "";
						background: $__honeydew_color;
						width: 1px;
						height: 16px;
						@include position(absolute, 50%, 0, null, null);
						@include transform(translateY(-50%));
					}
					&:last-child {
						@include margin-padding(0, 0);
						&::before {
							content: none;
						}
					}
				}
			}
		}
	}
	.patient-book {
		margin-bottom: 10px;
		p {
			font-size: $__font__size__12;
			color: $__black__ash;
			margin-bottom: 5px;
			i {
				margin-right: 5px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
.lab-icon {
	display: flex;
	display: -webkit-flex;
	align-items:center;
	-webkit-align-items:center;
	span {
		width: 24px;
		height: 24px;
		border-radius: 5px;
		background: $__grey__01;
		color: $__purple-600;
		display: flex;
		display: -webkit-flex;
		align-items:center;
		-webkit-align-items:center;
		justify-content: center;
		-webkit-justify-content: center;
		margin-right: 5px;
	}
	&.prescription {
		span {
			background: $__orange-50;
			color: $__orange-600;
		}
	}
}
.upload-file {
	border: 1px dashed $__honeydew_color;
	background: $__light-white-grey;
	border-radius: 10px;
	@include margin-padding(null, 30px);
	position: relative;
	text-align: center;
	cursor: pointer;
	input {
		@include position(absolute, 0, null, null, 0);
		opacity: 0;
		width: 100%;
		height: 100%;
	}
	p {
		font-size: $__font__size__14;
		font-weight: $__medium;
		color: $__grey_white__01;
		margin-bottom: 0;
	}
}
.modal-content {
	.bootstrap-tagsinput {
		border-radius: 6px;
	}
	.form-control {
		border-radius: 6px;
	}
}
.prescribe-download {
	background: $__light-white-grey;
	@include margin-padding(0 0 24px, 15px);	
	display: flex;
	display: -webkit-flex;
	align-items:center;
	-webkit-align-items:center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	h5 {
		font-size: $__font__size__20;
		font-weight: $__bold;
		margin-bottom: 0;
		@include respond-below(custom991) {
			font-size: $__font__size__18;
		}
		@include respond-below(custom575) {
			margin-bottom: 15px;
		}
	}
	ul {
		li {
			display: inline-block;
			margin-right: 15px;
			&:last-child {
				margin-right: 0;
			}
			.btn {
				font-size: $__font__size__14;
				font-weight: $__medium;
				@include margin-padding(null, 9px 13px);				
			}
		}
	}
	@include respond-below(custom575) {
		display: block;
	}
}
.view-prescribe {
	&.invoice-content {
		border: 1px solid $__honeydew_color;
		@include margin-padding(0, 20px);
		.invoice-item {
			.invoice-details {
				font-size: $__font__size__14;
				color: $__black__ash;
				font-weight: $__normal;
				strong {
					color: $__black__shade;
					font-weight: $__semibold;
				}
			}
			.customer-text {
				font-weight: $__semibold;
				color: $__black__shade;
				margin-bottom: 10px;
			}
			.invoice-info {
				margin-bottom: 20px;
			}
		}
		.invoice-table-wrap {
			h6 {
				font-size: $__font__size__14;
				font-weight: $__semibold;
				margin-bottom: 10px;
				color: $__black__shade;
			}
			.table-responsive {
				margin-bottom: 20px;
			}
			.invoice-table {
				border: 1px solid $__honeydew_color;
				tr {
					th {
						font-size: $__font__size__14;
						font-weight: $__medium;
						background: $__light-white-grey;
						white-space: nowrap;
						color: $__black__shade;
					}
				}
				tbody {
					tr {
						border-color: $__honeydew_color;
						td {
							font-size: $__font__size__14;
							color: $__black__shade;
							font-weight: $__normal;
							white-space: nowrap;
							span {
								font-size: $__font__size__12;
								display: block;
							}
						}
					}
				}
			}
		}
		.other-info {
			background: $__light-white-grey;
			border-radius: 10px;
			@include margin-padding(0 0 20px, 24px);
			h4 {
				margin-bottom: 15px;
				font-weight: $__semibold;
				color: $__black__shade;
			}
			p {
				&.text-muted {
					color: $__black__ash !important; 
					font-size: $__font__size__14;
				}
			}
		}
		.prescriber-info {
			text-align: right;
			h6 {
				font-weight: $__semibold;
				margin-bottom: 5px;
			}
			p {
				font-size: $__font__size__12;
				color: $__black__ash;
				margin-bottom: 0;
			}
		}
		.invoice-table-two {
			tr {
				th {
					font-size: $__font__size__14;
					font-weight: $__medium;
					color: $__black__shade;
				}
				td {
					color: $__black__ash;
					font-weight: $__normal;
				}
			}
		}
	}
}
.appointment-tabs {
	&.user-tab {
		border-bottom: 1px solid $__honeydew_color;
		margin-bottom: 24px;
		ul {
			margin-bottom: 4px;
		}
	}
}
.doctor-content {
	.profile-sidebar {
		margin-bottom: 0;		
		@include respond-below(custom991) {
			margin-bottom: 24px;	
		}
	}
}
.grid-patient {
	.appointment-wrap {
		&.appointment-grid-wrap {
			.patinet-information {	
				img {
					width: 64px;
					height: 64px;
				}
				.patient-info {
					ul {
						li {
							color: $__black__ash;
							font-size: $__font__size__12;
							@include margin-padding(0 10px 0 0, 0 10px 0 0);
							position: relative;
							display: inline-block;
							&::before {
								content: "";
								background: $__honeydew_color;
								width: 1px;
								height: 16px;
								@include position(absolute, 50%, 0, null, null);
								@include transform(translateY(-50%));
							}
							&:last-child {
								@include margin-padding(0, 0);
								&::before {
									content: none;
								}
							}
						}
					}
				}
			}
			.appointment-action {
				.patient-book {
					p {
						font-size: $__font__size__14;
						color: $__black__ash;
						margin-bottom: 5px;
						position: relative;
						display: flex;
						align-items: center;
						i {
							margin-right: 5px;
						}
						&:last-child {
							margin-bottom: 0;
						}
						span {
							color: $__black__ash;	
							display: inline-block;
							margin-left: 5px;
						}
					}
				}
			}
		}
	}
}
.view-prescribe-details {
	border: 1px solid $__honeydew_color;
	@include rounded(6px);
	@include margin-padding(null, 20px);
	.hospital-addr {
		border-bottom: 1px solid $__honeydew_color;
		margin-bottom: 20px;
		text-align: center;
		.invoice-logo {
			margin-bottom: 20px;
		}
		h5 {
			font-weight: $__semibold;
			margin-bottom: 10px;
		}
		p {	
			font-size: $__font__size__14;
		}
	}
	.invoice-info {
		margin-bottom: 20px;
		h6 {
			color: $__black__shade;
			font-weight: $__semibold;
			font-size: $__font__size__20;
			margin-bottom: 2px;
			@include respond-below(custom991) {
				font-size: $__font__size__18;
			}
			@include respond-below(custom767) {
				font-size: $__font__size__16;
			}
		}
		p {
			font-size: $__font__size__14;
			margin-bottom: 0;
		}
	}
	.invoice-info2 {
		text-align: right;
		margin-bottom: 20px;
		p {
			font-size: $__font__size__14;
			margin-bottom: 3px;
			span {
				font-weight: $__semibold;
				color: $__black__shade;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}		
		@include respond-below(custom767) {
			text-align: left;
		}
	}
	.patient-id {
		h6 {
			font-weight: $__semibold;
			margin-bottom: 10px;
		}
	}
	.patient-det {
		background: $__white__shade;
		@include margin-padding(0 0 20px, 5px 10px 0);
		display: flex;
		display: -webkit-flex;
		align-items:center;
		-webkit-align-items:center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		flex-wrap: wrap;
		h6 {
			font-weight: $__semibold;
			color: $__black__shade;
			margin-bottom: 5px;
		}
		ul {
			li {
				display: inline-block;
				margin: 0 18px 5px 0;
				color: $__black__shade;
				font-size: $__font__size__12;
				font-weight: $__semibold;
				&:last-child {
					margin-right: 0;
				}
				@include respond-below(custom1399) {
					margin-right: 12px;
				}
			}
		}
	}
	.appointment-notes {
		h3 {
			font-size: $__font__size__20;
			font-weight: $__bold;
			text-align: center;
			margin-bottom: 20px;
			@include respond-below(custom991) {
				font-size: $__font__size__18;
			}
			@include respond-below(custom767) {
				font-size: $__font__size__16;
			}
		}
	}
	.appoint-wrap {
		margin-bottom: 20px;
		h5 {
			margin-bottom: 15px;
		}
		p {
			font-size: $__font__size__14;
		}
		ul {
			li {
				font-size: $__font__size__14;
				display: inline-block;
				margin: 0 24px 5px 0;
				span {
					color: $__black__05;
					font-weight: $__semibold;
				}
			}
		}
	}
	.inv-table {
		border: 1px solid $__honeydew_color;
		border-radius: 6px;
		margin-bottom: 20px;
		.invoice-table {
				tr {
					th {
						font-size: $__font__size__14;
						font-weight: $__medium;
						background: $__light-white-grey;
						white-space: nowrap;
					}
				}
				tbody {
					tr {
						border-color: $__honeydew_color;
						td {
							font-size: $__font__size__14;
							color: $__black__shade;
							white-space: nowrap;
							vertical-align: middle;
							span {
								font-size: $__font__size__12;
								display: block;
							}
						}
					}
				}
			}
	}
	.scan-wrap {
		margin-bottom: 15px;
		h6 {
			font-weight: $__semibold;
			margin-bottom: 5px;
		}
	}
	.prescriber-info {
		text-align: right;
		margin-bottom: 15px;
		h6 {
			font-weight: $__semibold;
			margin-bottom: 5px;
		}
		p {
			font-size: $__font__size__12;
			color: $__black__ash;
			margin-bottom: 0;
		}	
		@include respond-below(custom767) {
			text-align: left;
		}
	}
	.inv-paginate {
		margin-top: 9px;
	}
}
.add-billing-info,
.add-prescripe-info, .add-info {
	border-bottom: 1px solid $__honeydew_color;
	margin-bottom: 24px;
}
.bill-cont, .prescripe-cont {
	.trash {
		font-size: $__font__size__14;
		font-weight: $__medium;
		color: $__cart__red;
	}
}
.trash-icon {
	font-size: $__font__size__14;
	font-weight: $__medium;
	color: $__cart__red;
}
.add-bill,
.add-prescribe, .more-item {
	font-size: $__font__size__14;
	font-weight: $__medium;
	margin-bottom: 20px;
	color: $__blue__14;
	text-align: right;
	display: inline-block;
	&:hover {
		color: $__blue__15;
	}
}
.sign-wrapper {
	width: 165px;
	text-align: center;
	float: right;
}
.wrap-sign {
	background: $__light-white-grey;
	border: 1px dashed $__honeydew_color;
	@include rounded(10px);
	@include margin-padding(null, 24px);
}
.upload-sign {
	border: 1px dashed $__honeydew_color;
	@include rounded(10px);
	margin-bottom: 10px;
	min-width: 165px;
	@include margin-padding(0 0 10px, 20px);
	font-size: $__font__size__14;
	font-weight: $__medium;
	p {		
		font-size: $__font__size__14;
		font-weight: $__medium;
		color: $__black__shade;
		margin-bottom: 0;
	}
}
.info-name {
	h6 {
		font-size: $__font__size__14;
		font-weight: $__medium;
		color: $__black__shade;
		margin-bottom: 5px;
	}
	p {
		font-size: $__font__size__14;
		color: $__black__ash;
		margin-bottom: 0;
	}
}
.patient-book {
	&.patien-inv {
		h6 {
			font-weight: $__semibold;
			margin-bottom: 5px;
			color: $__blue__14;
			text-align: right;			
			@include respond-below(custom575) {
				text-align: left;
			}
		}
		p {
			font-size: $__font__size__12;
			margin-bottom: 0;
		}
	}
}
.user-accordion-item {  
    background: $__white;
    border: 1px solid $__honeydew_color;
    border-radius: 10px;
    box-shadow: 0px 7.5px 17.5px 0px #0000000D;
    margin-bottom: 24px;
    .accordion-wrap {
        @include margin-padding(null, 24px 38px 24px 24px);
        font-weight: $__semibold;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
        position: relative;
        color: $__black__shade;
        font-size: $__font__size__16;
        span {
           color: $__cart__red;
            margin-right: 8px;  
            font-size: $__font__size__14;
            font-weight: $__medium;          
			&.edit {
				color: $__zinc-900;
			}
        }
        &::before {
            content: "\f078";
            font-family: 'Font Awesome 5 Free';
            @include position(absolute, 50%, 24px, null, null);
            @include transform(translateY(-50%));
        } 
        &:not(.collapsed) {                 
            &::before {
                content: "\f077";
            }           
        }
        &:hover {
        	color: $__dark-blue;
        	&::before {
        		color: $__dark-blue;
        	}
        }
    }
    .content-collapse {
        border-top: 1px solid $__honeydew_color;
        @include margin-padding(null, 24px);         
    }
    .accordion-collapse {
        .form-wrap {
            margin-bottom: 24px;
            &.mb-wrap {
                margin-bottom: 0;
                @include respond-below(custom767) {
                    margin-bottom: 24px;
                }
            }
        }       
    }
}
.modal {
    z-index: 1050;
}
.modal-backdrop {
    z-index: 1040;
}
.stripe-wrapper {
	display: flex;
	display: -webkit-flex;
	align-items:center;
	-webkit-align-items:center;
}
.payout-wrap {
	background: $__black__shade;
	@include margin-padding(0 0 24px, 24px 24px 14px);
	@include rounded(10px);
	.payout-title {
		border-bottom: 1px solid $__black__ash;
		margin-bottom: 24px;
	}
	h4 {
		font-size: $__font__size__20;
		font-weight: $__semibold;
		color: $__white;
		margin-bottom: 10px;
	}
	p {
		font-size: $__font__size__14;
		font-weight: $__medium;
		color: $__white;
		margin-bottom: 10px;
	}
	.stripe-box {
		@include margin-padding(0 24px 10px 0, 20px);
		background: $__white;
		@include rounded(10px);
		position: relative;
		.stripe-img {
			@include margin-padding(0 0 10px, 0 0 10px);
			border-bottom: 1px solid $__honeydew_color;
		}
		a {
			@include rounded(40px);
			@include margin-padding(null, 4px 9px);
			background: $__honeydew_color;
			color: $__black__ash;
			font-size: $__font__size__14;
			font-weight: $__medium;
			i {
				margin-right: 5px;
			}
				&:hover {
					background: $__blue__16;
					color: $__blue__14;
				}
		}
		&:last-child {
			margin-right: 0;
		}
		&.active {
			&::before {
				content: "";
				background-color: $__white;
				width: 20px;
				height: 20px;
				@include rounded(50%);
				@include position(absolute, -5px, -5px, null, null);
				background-image: url('../images/icons/badge.svg');
				background-repeat: no-repeat;
				background-position: center;
			}
		}
	}
}