.appointment-wrap {
	box-shadow: 0px 7.5px 17.5px 0px #0000000D;
	@include margin-padding(0 0 15px, 24px 24px 10px 24px);
	@include rounded(10px);
	& > ul {		
		display: flex;
		display: -webkit-flex;
		align-items:center;
		-webkit-align-items:center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		flex-wrap: wrap;
		@include respond-below(custom575) {
			display: block;
		}
		& > li {
			margin-bottom: 14px;
			&:first-child {
				min-width: 150px;
			}
			&.appointment-info {
				min-width: 190px;
			}
			&.mail-info-patient {
				min-width: 190px;
			}
		}
	}
}
.appointment-info {
	p {
		color: $__black__ash;
		margin-bottom: 5px;
		i {
			margin-right: 5px;
			font-size: $__font__size__13;
			min-width: 13px;
		}
		&.md-text {
			color: $__black__ash;
			margin-bottom: 0;
		}
	}
}
.patinet-information {		
	display: flex;
	display: -webkit-flex;
	align-items:center;
	-webkit-align-items:center;
	min-width: 175px;
	& > a {
		flex-shrink: 0;
	}
	img {
		width: 48px;
		height: 48px;
		border-radius: 10px;
		margin-right: 10px;
	}
	.patient-info {
		margin: 0;
		p {
			color: $__blue__17;
			font-size: $__font__size__12;
			font-weight: $__medium;
			margin-bottom: 5px;
			&.visit {
				font-weight: $__normal;
				color: $__black;
			}
		}
		h6 {
			font-size: $__font__size__16;
			font-weight: $__semibold;
			color: $__black__shade;
			margin-bottom: 0;
			a {
				color: $__black__shade;
				&:hover {
					color: $__blue__14;
				}
			}
		}
	}
}
.appointment-type {
	p {
		font-size: $__font__size__16;
		color: $__black__shade;
		i {
			margin-right: 5px;
		}
		margin-bottom: 0;
		&.md-text {
			color: $__black__ash;
			margin-bottom: 5px;
		}
	}
}
.request-action {
	li {
		margin-right: 15px;
		position: relative;
		padding-right: 15px;
		display: inline-block;
		&:last-child {
			margin-right: 0;
			padding-right: 0;
			&::before {
				content: none;
			}
		}
		&::before {
			@include position(absolute, 50%, 0, null, null);
			@include transform(translateY(-50%));
			content: "";	
			background: $__honeydew_color;
			width: 1px;
			height: 19px;
		}
		a {
			font-size: $__font__size__15;
			font-weight: $__medium;
			i {
				margin-right: 6px;
			}
			&.accept-link {
				color: $__green-700;
			}
			&.reject-link {
				color: $__rose-700;
			}
		}
	}
}
.dashboard-header {
	display: flex;
	display: -webkit-flex;
	align-items:center;
	-webkit-align-items:center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	border-bottom: 1px solid $__honeydew_color;
	margin-bottom: 24px;
	padding-bottom: 14px;
	flex-wrap: wrap;
	h3 {
		color: $__black__shade;
		font-weight: $__semibold;
		margin-bottom: 10px;
	}
	ul {
		li {
			margin-bottom: 10px;
		}
	}
}
.md-text {
	font-size: $__font__size__14;
	font-weight: $__medium;
}
.text-green {
	color: $__emerald-600;
}
.text-blue {
	color: $__indigo-500;
}
.text-indigo {
	color: $__indigo-800;
}
.text-blue-600 {
	color: $__blue__14 !important;
}
.success-wrap {
	.icon-success {
		width: 64px;
		height: 64px;
		@include rounded(50%);
		display: flex;
		display: -webkit-flex;
		align-items:center;
		-webkit-align-items:center;
		justify-content: center;
		-webkit-justify-content: center;
		margin: 0  auto 24px;
		background: $__blue__18;
		font-size: $__font__size__20;
		color: $__white;
		@include respond-below(custom767) {
			width: 50px;
			height: 50px;
			font-size: $__font__size__16;
		}
	}
	h3 {
		font-weight: $__medium;
		margin-bottom: 5px;
		color: $__black__shade;
	}
	p {
		color: $__black__ash;
		margin-bottom: 24px;
	}
	.btn {
		font-weight: $__semibold;
		font-size: $__font__size__15;
		padding: 8px 10px;
		@include respond-below(custom575) {
			margin-top: 5px;
		}
	}
	.form-wrap {
		margin-bottom: 15px;
	}
	.form-check {
		font-size: $__font__size__16;
		color: $__midnight_black;
		font-weight: $__medium;
		@include respond-below(custom991) {
			font-size: $__font__size__15;
		}
	}
}
.success-info {
	margin-bottom: 24px;
}
.info-modal {
	.modal-body {
		padding: 40px;
		@include respond-below(custom767) {
			padding: 25px;
		}
	}
}
.loader-item {
	margin-top: 25px;
	@include respond-below(custom767) {
		margin-top: 5px;
	}
}
.btn-load {
	border: 1px solid $__honeydew_color;
	background: $__white;
	border-radius: 6px;
	font-weight: $__semibold;
	font-size: $__font__size__15;
	padding: 8px 11px;
	color: $__black__ash;
	box-shadow: 0px 1px 2px 0px #0000000D;
	&:hover {
		background: $__honeydew_color;
		color: $__black__ash;
	}
}
.dashboard-header {
	.header-list-btns {
		display: flex;
		align-items: center;
		li {
			.view-icons {
				a {
					border: 1px solid #E2E8F0;
					border-radius: 10px;
					background: $__white;
					width: 36px;
					height: 36px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 16px;
					&.active, &:hover {
						background: $__blue__14;
						color: $__white;
						border-color: $__blue__14;
					}
				}
			}
			.dash-search-input {
				position: relative;
				.form-control {
					height: 36px;
					min-height: 36px;
					border: 1px solid #E2E8F0;
					border-radius: 10px;
				}
				.search-icon {
					position: absolute;
					right: 15px;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
		
	}
}
.dash-search-input {
	position: relative;
	.form-control {
		height: 36px;
		min-height: 36px;
		border: 1px solid #E2E8F0;
		border-radius: 10px;
	}
	.search-icon {
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
	}
}
.appointment-info {
	.apponitment-types {
		align-items: center;
		li {
			font-size: $__font__size__14;
			font-weight: $__medium;
			color: $__black__05;
			line-height: 1;
			& +li {
				border-left: 1px solid $__honeydew_color;
				margin-left: 5px;
				padding-left: 5px;
			}
		}
	}
}
.mail-info-patient {
	li {
		color: $__black__05;
		i {
			margin-right: 5px;
			font-size: $__font__size__13;
		}
		& +li {
			margin-top: 5px;
		}
	}
}
.appointment-action {
	ul {
		display: flex;
		align-items: center;
		li {
			& +li {
				margin-left: 8px;
			}
			a {
				width: 32px;
				height: 32px;
				border-radius: 50%;
				font-size: $__font__size__14;
				background: $__honeydew_color;
				display: flex;
				align-items: center;
				justify-content: center;
				&:hover {
					background: $__blue__14;
					color: $__white;
				}
			}
		}
	}
}
.appointment-start {
	a {
		color: #18181B;
		font-weight: $__semibold;
		text-decoration: underline;
		&:hover {
			color: $__blue__14;
		}
	}
}
.patient-info {
	h6 {
		display: inline-flex;
		align-items: center;
		.badge {
			&.new-tag {
				background: $__teal;
				font-size: $__font__size__10;
				margin-left: 5px;
				font-weight: $__medium;
				padding: 4px 6px;
			}
		}
	}
}

.form-sorts  {
    &.dropdown {
        background: $__white;
        border: 1px solid #E2E8F0;
        height: 36px;
        border-radius: 6px;
        padding: 10px;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
        a {
            color: $__bodycolor;
            &.dropdown-toggle {
                &::after {
                    margin-left: 8px;
                }
            }
        }
    }
}
.filter-checks {
    .checkboxs {
        display: block;
        cursor: pointer;
        user-select: none;
		.check-title {
			padding-left: 25px;
			color: $__black__01;
		}
      }
      .checkboxs input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
      .checkboxs input ~ .checkmarks {
        border-radius: 5px;
      }
      .checkboxs input:checked ~ .checkmarks {
        background-color: $__broken_blue;
        border-color: $__broken_blue;
      }
      .checkboxs input:checked ~ .checkmarks:after {
        display: block;
      }
      .checkboxs .checkmarks {
        height: 18px;
        width: 18px;
        position: absolute;
		border: 1px solid #E5E7EB;
        top: 3px;
        left: 0;
      }
      .checkboxs .checkmarks:after {
        content: "";
        position: absolute;
        display: none;
      }
      .checkboxs .checkmarks:after {
        left: 5px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid $__white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
    }
}
.filter-set-content-head {
    a {
        i {
            transition: 0.5s all;
			font-size: $__font__size__16;
        }
        &:not(.collapsed) {
            i {
                transform: rotate(-90deg);
                transition: 0.5s all;
            }
        }
    }
}
.form-sorts {
    position: relative;
    .filter-dropdown-menu {
        padding: 20px;
        border-radius: 5px;
        background: $__white;
        border: 1px solid $__white;
        box-shadow: 0px 4px 24px 0px #BCBCBC40;
        position: absolute;
        z-index: 1;
        min-width: 350px;
        right: 0;
        top: 40px;
        opacity: 0;
        display: none;
        @include respond-below(custom575) {
            right: unset;
            left: 0;
            min-width: 280px;
        }
    }
    &.table-filter-show {
        .filter-dropdown-menu {
            display: block;
            opacity: 1;
        }
    }
    .filter-set-head {
        h4 {
            font-size: $__font__size__20;
            // border-bottom: 1px solid $secondary-200;
            margin-bottom: 15px;
            padding-bottom: 15px;
        }
    }
    .filter-set-content {
        margin-bottom: 15px;
        .filter-set-content-head {
            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
				background: $__sky__04;
				padding: 10px;
				font-size: $__font__size__16;
				color: $__black__shade;
				font-weight: $__medium;
            }
        }
    }
    .filter-reset-btns {
        display: flex;
		align-items: center;
        padding-top: 15px;
        a {
            padding: 10px 20px;
			flex: 1;
			display: inline-flex;
			justify-content: center;
			font-family: $__semibold;
            @include respond-below(custom575) {
                padding: 10px 30px;
            }
			&.btn-primary {
				background: $__blue__14;
				color: $__white;
			}
        }
        .btn-light {
            background: #F3F4F6;
			color: #1F2937;
            margin-right: 15px;
        }
    }
}
.filter-set-contents {
    ul {
        margin-top: 20px;
        li {
            position: relative;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
            .rating {
                margin-left: 28px;
                i {
                    // color: $light-900;
                    &.filled {
                        // color: $warning;
                    }
                }
                span {
                    margin-left: 5px;
                }
            }
            .collapse-inside-text {
                margin-left: 28px;
                h5 {
                    margin-bottom: 0;
                    font-size: $__font__size__14;
                    font-weight: $__medium;
                }
            }
        }
    }
}
.setting-title {
	h5 {
		font-size: $__font__size__18;
		color: $__black__shade;
		margin-bottom: 15px;
		@include respond-below(custom991) {
			font-size: $__font__size__16;
		}
	}
}
.appointment-tabs {
	ul {
		&.available-nav {
			margin-bottom: 4px;
		}
		li {
			margin-right: 10px;
			margin-bottom: 20px;
			&:last-child {
				margin-right: 0;
			}
			.nav-link {
				background: $__white__shade;
				border-radius: 10px;
				color: $__black__ash;
				padding: 6px 15px;
				font-weight: $__medium;
				font-size: $__font__size__16;
				display: inline-flex;
				display: -webkit-inline-flex;
				align-items:center;
				-webkit-align-items:center;
				@include respond-below(custom575) {
		            text-align: center;
		        }
				span {
					background: $__white;
					padding: 1px 11px;
					color: $__black__shade;
					font-weight: $__bold;
					border-radius: 30px;
					display: inline-flex;
					align-items: center;
					justify-content: center;
					font-size: $__font__size__12;
					margin-left: 10px;
				}
				&.active {
					background: $__blue__14;
					color: $__white;
					border-color: $__blue__14;
				}
				&:hover {
					background: $__blue__14;
					color: $__white;
					border-color: $__blue__14;
				}
			}
			@include respond-below(custom575) {
	            margin-right: 0;
	        }
		}
		@include respond-below(custom575) {
            display: block;
        }
	}
}
.grid-patient {
	.patinet-information .patient-info {
		p {
			margin-bottom: 0;
		} 
		h6 {
			margin-bottom: 5px;
		}
	}
	.appointment-wrap.appointment-grid-wrap > ul > li {
		margin-bottom: 15px;
	}
	.appointment-action {
		border-top: 1px solid $__honeydew_color;
		padding-top: 15px;
	}
}
.appointment-tab-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	.filter-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		.daterange-wraper {
			margin-bottom: 20px;
			width: 230px;
			input.form-control {
				border: 1px solid #E2E8F0;
				min-height: 36px;
				height: 36px;
				border-radius: 6px;
				padding-left: 34px;
				color: #6B7280;
				&::placeholder {
					color: #6B7280;
				}
			}
			i {
				position: absolute;
				left: 15px;
				top: 50%;
				transform: translateY(-50%);
				color: #6B7280;
			}
		}
	}
}
.accordion {
	.filter-set-content {
		& +.filter-set-content {
			border-top: 1px solid #E2E8F0;
			padding-top: 15px;
		}
	}
}
.appointment-tab-content {
	padding-top: 10px;
}
.appointment-detail-btn {
	a {
		border: 1px solid $__blue__14;
		color: $__blue__14;
		font-weight: $__semibold;
		padding: 5px 10px;
		border-radius: 6px;
		i {
			font-size: $__font__size__14;
		}
	}
}
.dashboard-pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
	ul {
		display: flex;
		align-items: center;
		justify-content: center;
		li {
			.page-link {
				background: $__honeydew_color;
				border-color: $__honeydew_color;
				width: 32px;
				height: 32px;
				color: $__black__shade;
				border-radius: 10px;
				margin-right: 10px;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				&.active {
					background: $__blue__14;
					border-color: $__blue__14;
					color: $__white;
				}
				&:hover {
					background: $__blue__14;
					border-color: $__blue__14;
					color: $__white;
				}
			}
		}
	}
}
.bg-red {
	background: #DE3F44 !important;
}
.btn-gray {
	background: $__athens_grey;
	border: 1px solid $__athens_grey;
	color: $__black__ash;
	box-shadow: inset 0 0 0 0 $__white;
	&:hover {
		color: $__black__ash;
		background-color: $__white;
		border-color: $__black__ash;
		box-shadow: inset 0 0 0 50px $__white;
		-webkit-transition: 0.7s;
		-ms-transition: 0.7s;
		transition: 0.7s;
	}
}
.modal-button {
	.btn + .btn {
		@include respond-below(custom575) {
			margin-top: 15px;
		}
	}
}
.col-form-label {
	font-size: $__font__size__14;
	font-weight: $__medium;
	color: $__black__ash;
	padding: 0 0 10px 0;
}